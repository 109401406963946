<div mat-dialog-title>
  <h2>
    {{'payment.onlinePayment' | translate}}
  </h2>
  <button type="button" class="close" mat-dialog-close aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<mat-dialog-content class="px-0 checkout-dialog">
  <div class="mat-dialog-body checkout-dialog-body">
    <div class="select-payment-method">
      <h5>{{'payment.payWith' | translate}}</h5>
      <mat-button-toggle-group [(ngModel)]="paymentMethod" (change)="changeMethod($event.value)"
        aria-label="Select an option">
        <ng-container *ngFor="let item of paymentMethods">
          <mat-button-toggle class="h-auto" [value]="item.value"
            [hidden]="!iOS() && item.value == onlinePaymentEnum.ApplePay">
            <img [src]="item.imgPath" />
            <span>{{item.name | translate}}</span>
          </mat-button-toggle>
        </ng-container>
      </mat-button-toggle-group>
    </div>

    <div *ngIf="dataLoading" class="dataLoading">
      <mat-spinner class="m-auto" diameter="50"></mat-spinner>
    </div>

    <!-- <form *ngIf="!dataLoading && paymentProvider == OnlinePaymentEnum.Visa_MasterCard" id="eFinanceForm"
      [action]="redirectLink" class="paymentWidgets" data-brands="VISA MASTER"></form>
    <form *ngIf="!dataLoading && paymentProvider == OnlinePaymentEnum.Mada" id="eFinanceForm" [action]="redirectLink"
      class="paymentWidgets" data-brands="MADA"></form> -->
    <iframe *ngIf="!dataLoading" class="payment-online-iframe w-100" [src]="referencePaymentUrl | safe"
      [class.loading]="paymentIframeLoader" (load)="paymentIframeLoader = false"></iframe>

  </div>
</mat-dialog-content>