import {
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { WebRTCAdaptor } from '@antmedia/webrtc_adaptor';
import { CoursesService } from 'src/app/services/Courses.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-live-stream',
  templateUrl: './live-stream.component.html',
})
export class LiveStreamComponent implements OnInit , OnDestroy {
  dataLoading = false;
  actionLoading: any = false;
  courseId: any;
  @ViewChild('localVideo') localVideo!: ElementRef;

  webrtcAdaptor: any;
  websocketURL =
    'wss://origin.inndeve.com:443/WebRTCAppEE/websocket?target=origin';
  sdpConstraintsPublish;
  mediaConstraintsPublish;

  isPublished = false; // for owner
  isPlaying = false; // for player
  streamMsg = ''; // for player
  isOwner = false;
  details: any;

  chatList: {
    text: string;
    mine: boolean;
    time: Date;
  }[] = [];

  message = '';
  showChat = false;
  shouldStopPublishing = false;

  constructor(private route: ActivatedRoute, private service: CoursesService) {
    this.route.queryParams.subscribe((params) => {
      if (params['courseId']) {
        this.courseId = params['courseId'];
        this.getToken();
      }
    });
    this.showChat = true;
  }

  ngOnInit(): void {}

  // ngAfterViewInit(): void {
  //   this.localVideo.nativeElement.play();
  // }

  getToken() {
    this.dataLoading = true;
    this.service.getCourseStreamToken(this.courseId).subscribe(
      (data: any) => {
        this.dataLoading = false;
        if (data) {
          this.details = data;
          if (data.tokenType == 'PUBLISH') {
            this.isOwner = true;
          } else {
            this.isOwner = false;
          }
          this.sdpConstraintsPublish = {
            OfferToReceiveAudio: !this.isOwner,
            OfferToReceiveVideo: !this.isOwner,
          };
          this.mediaConstraintsPublish = {
            video: this.isOwner,
            audio: this.isOwner,
          };

          this.initWebrtc();
        }
      },
      (error) => {}
    );
  }

  initWebrtc() {
    this.actionLoading = 'publish';
    this.webrtcAdaptor = new WebRTCAdaptor({
      websocket_url: this.websocketURL,
      mediaConstraints: this.mediaConstraintsPublish,
      sdp_constraints: this.sdpConstraintsPublish,
      localVideoId: 'localVideo',
      remoteVideoId: 'localVideo',
      peerconnection_config: {
        iceServers: [{ urls: 'stun:stun1.l.google.com:19302' }],
      },
      bandwidth: 900,
      isPlayMode: !this.isOwner,
      isShow: !this.isOwner,

      callback: (info: any, description: any) => {
        this.localVideo?.nativeElement?.play();

        if (info == 'initialized') {
          this.actionLoading = false;
        } else if (info == 'publish_started') {
          console.log('publish started');
        } else if (info == 'publish_finished') {
          console.log('publish finished');
        } else if (info == 'available_devices') {
          console.log('Calling available devices', description);
        } else if (info == 'closed') {
          if (typeof description != 'undefined') {
            this.webrtcAdaptor = null;
            this.initWebrtc();
          }
        } else if (info == 'data_received') {
          if (description.data[0] !== '{') {
            this.chatList.unshift({
              text: description.data,
              mine: false,
              time: new Date(),
            });
            this.showChat = true;
          }
        }
        if (info == 'play_started') {
          this.isPlaying = true;
        }
        if (info == 'play_finished') {
          this.isPlaying = false;
          this.streamMsg = 'The live stream is ended';
          this.showChat = false;
        }
      },
      callbackError: (error: any, message: any) => {
        //some of the possible errors, NotFoundError, SecurityError,PermissionDeniedError
        console.log('error callback: ' + JSON.stringify(error));
        var errorMessage = JSON.stringify(error);
        if (typeof message != 'undefined') {
          errorMessage = message;
        }
        if (error == 'no_stream_exist') {
          Swal.fire('No Stream Exist', '', 'warning');
        }
        if (error.indexOf('NotFoundError') != -1) {
          errorMessage =
            'Camera or Mic are not found or not allowed in your device';
        } else if (
          error.indexOf('NotReadableError') != -1 ||
          error.indexOf('TrackStartError') != -1
        ) {
          errorMessage =
            'Camera or Mic is being used by some other process that does not let read the devices';
        } else if (
          error.indexOf('OverconstrainedError') != -1 ||
          error.indexOf('ConstraintNotSatisfiedError') != -1
        ) {
          errorMessage =
            'There is no device found that fits your video and audio constraints. You may change video and audio constraints';
        } else if (
          error.indexOf('NotAllowedError') != -1 ||
          error.indexOf('PermissionDeniedError') != -1
        ) {
          errorMessage = 'You are not allowed to access camera and mic.';
          Swal.fire(errorMessage, '', 'warning');
        } else if (error.indexOf('TypeError') != -1) {
          errorMessage = 'Video/Audio is required';
        }
        if (error.indexOf('publishTimeoutError') != -1) {
          console.log(
            'publish timeout error caught, Correct Start stream label',
            error
          );
          this.webrtcAdaptor = null;
          this.initWebrtc();
        }

        //alert(errorMessage);
      },
    });
  }

  sendMsg(event = null) {
    if (event) {
      event.preventDefault();
      event.target.style.height = 'auto';
    }
    this.webrtcAdaptor.sendData(this.details.streamId, this.message);
    this.chatList.unshift({ text: this.message, mine: true, time: new Date() });
    this.message = '';
  }

  PublishStreaming() {
    this.actionLoading = 'publish';
    this.service.GoLive(this.courseId).subscribe(() => {
      this.actionLoading = false;
      this.isPublished = true;
      this.webrtcAdaptor.publish(
        String(this.details.streamId),
        String(this.details.streamToken)
      );
      this.localVideo.nativeElement.pause();
    });
  }

  StopPublishing() {
    this.actionLoading = 'stop';
    this.service.GoOffline(this.courseId).subscribe(() => {
      this.actionLoading = false;
      this.webrtcAdaptor.stop(this.details.streamId);
      this.webrtcAdaptor.closeStream();
      this.webrtcAdaptor = null;
      this.isPublished = false;
      this.showChat = false;
      this.localVideo.nativeElement.resetView();
    });
  }

  shareScreen() {
    this.webrtcAdaptor.switchDesktopCaptureWithCamera(this.details.streamId);
  }

  play() {
    this.webrtcAdaptor.play(this.details.streamId, this.details.streamToken);
    this.webrtcAdaptor.muteLocalMic();
  }

  textAreaAdjust(event) {
    event.target.style.height = event.target.scrollHeight + 'px';
  }
  ngOnDestroy(): void {
     // this.StopPublishing();
  }
}
