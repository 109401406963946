<div mat-dialog-title>
  <h2>
    Share to Concurel
  </h2>
  <button type="button" class="close" mat-dialog-close aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<mat-dialog-content class="assign-dialog-content">
  <div class="page">
    <app-concurel-login
      *ngIf="!isLocalStorageSaved() && !(data.conCuralUserName && data.conCuralPassword)"></app-concurel-login>
    <app-meeting-list *ngIf="isLocalStorageSaved()" (selected)="selectedMeeting = $event" (closeDailog)="closeDailog()"
      [successMsg]="successMsg"></app-meeting-list>
    <app-concurel-account-msg *ngIf="(successMsg || data.notAuthorized)" [notAuthorized]="data.notAuthorized"
      [successMsg]="successMsg" (closeDailog)="closeDailog()"></app-concurel-account-msg>
  </div>
</mat-dialog-content>
<mat-dialog-actions *ngIf="isLocalStorageSaved() && !successMsg" align="end"
  class="assign-dialog-actions justify-content-end">
  <div>
    <button mat-raised-button (click)="submit()" [class.loading]="loading" [disabled]="!selectedMeeting || loading"
      color="primary">
      Submit
    </button>
  </div>
</mat-dialog-actions>