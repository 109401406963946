import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';
import { LabForm } from '../models/labs/LabForm';

@Injectable({
  providedIn: 'root'
})
export class LabsService {
  refreshDetails = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient) {
  }

  getAll(params): any {
    return this.http.get(`${environment.apiUrl}/Lab/GetAllLabs`, { params });
  }
  getAllMain(id = null): any {
    const query = id ? '?LabId=' + id : '';
    return this.http.get(`${environment.apiUrl}/Lab/ListMainLabs${query}`);
  }
  saveRecord(data: LabForm, logo) {
    const formData = new FormData();
    if (logo) {
      formData.append('logo', logo, logo.name);
    }
    formData.append('data', JSON.stringify(data));
    if (data.labId) {
      return this.http.put(`${environment.apiUrl}/Lab/EditLab`, formData);
    } else {
      return this.http.post(`${environment.apiUrl}/Lab/AddLab`, formData);
    }
  }
  getDetails(id) {
    return this.http.get(`${environment.apiUrl}/Lab/GetLabDetails?labId=${id}`);
  }
  deleteRecord(id) {
    return this.http.delete(`${environment.apiUrl}/Lab/DeleteLab?labId=${id}`);
  }

  getAllLabsLookup() {
    return this.http.get(`${environment.apiUrl}/Lab/GetAllLabsLookup`);
  }

  getAuthorizedLabs() {
    return this.http.get(`${environment.apiUrl}/Lab/GetAuthorizedLabs`);
  }
  getLabProfileDetails() {
    return this.http.get(`${environment.apiUrl}/LabProfile/GetLabProfileDetails`);
  }

  getLabScanningCentersLookup(params) {
    return this.http.get(`${environment.apiUrl}/Lab/GetLabScanningCentersLookup`, { params });
  }

  getMainLabBranchesLookup(params) {
    return this.http.get(`${environment.apiUrl}/Lab/GetMainLabBranchesLookup`, { params });
  }

  updateLabProfile(data: LabForm, logo, type = null) {
    const formData = new FormData();
    if (logo) {
      formData.append('logo', logo, logo.name);
    }
    formData.append('data', JSON.stringify(data));
    if (type === 'profileVl') {
      return this.http.put(`${environment.apiUrl}/LabProfile/EditVLLabProfile`, formData);
    } else {
      return this.http.put(`${environment.apiUrl}/LabProfile/EditLabProfile`, formData);
    }
  }
}
