<div class="preloading" *ngIf="moduleLoading">
  <img src="./assets/images/logo-circle.svg" alt="">
</div>

<div *ngIf="!moduleLoading" class="loaded">
  <app-theme-header [isBottom]="isBottom"></app-theme-header>
  <a (click)="scrollToTop()" class="backtop" [class.show]='isBottom'>
    <svg width="60" height="60" viewBox="0 0 60 60">
      <g id="Go_to_top_button" data-name="Go to top button" transform="translate(-624.006 680.179) rotate(-90)">
        <circle id="Ellipse_302" data-name="Ellipse 302" cx="30" cy="30" r="30" transform="translate(620.179 624.006)"
          fill="#643676"></circle>
        <path id="Path_21212" data-name="Path 21212"
          d="M21.721,12.594l-7.4-6.625,1.95-1.747L27,13.829,16.273,23.436l-1.95-1.747,7.4-6.625H3v-2.47Z"
          transform="translate(635.179 639.783)" fill="#fff"></path>
      </g>
    </svg>
  </a>
  <router-outlet></router-outlet>
  <app-theme-footer></app-theme-footer>
</div>
