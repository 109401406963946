import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
export interface DialogData { }
@Component({
  selector: 'app-upload-id',
  templateUrl: './upload-id.component.html',
})
export class UploadIdComponent implements OnInit {
  isSubmitted = false;
  files: any[] = [];
  deletedAssignmentAttachments: any[] = [];
  constructor(public dialogRef: MatDialogRef<UploadIdComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,) {}

  ngOnInit(): void {
    if (this.data) {
      this.files.push(this.data)
    }
  }

  save () {
    this.isSubmitted = true;
    if (this.files && this.files.length) {
      if (this.files[0] instanceof File) {
        this.dialogRef.close(this.files[0])
      }
    }
  }
}
