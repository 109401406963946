<div mat-dialog-title>
  <h2>
    {{"quiz.respondentMessage"|translate}}
  </h2>
  <button type="button" class="close" mat-dialog-close aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<mat-dialog-content>
  <div class="mat-dialog-body new-course">
    <form [formGroup]="formGroup">

      <div class="fields-group">
        <mat-form-field>
          <mat-label>{{"quiz.question"|translate}}</mat-label>
          <input matInput formControlName="question" readonly>
        </mat-form-field>
      </div>
      <div class="fields-group">
        <mat-form-field>
          <mat-label>{{"quiz.option"|translate}}</mat-label>
          <input matInput formControlName="option" readonly>
        </mat-form-field>
      </div>

      <div class="mat-dialog-inner">
        <h3 class="subtitle-form">{{"quiz.respondentMessage"|translate}}
          <svg class="mx-2" [matTooltip]="'quiz.respondentMessageInfo'|translate" xmlns="http://www.w3.org/2000/svg" width="20"
            height="20" viewBox="0 0 20 20">
            <path id="info_FILL0_wght400_GRAD0_opsz24"
              d="M90-865a.968.968,0,0,0,.713-.287A.968.968,0,0,0,91-866v-4a.968.968,0,0,0-.287-.713A.968.968,0,0,0,90-871a.968.968,0,0,0-.713.287A.968.968,0,0,0,89-870v4a.968.968,0,0,0,.287.713A.968.968,0,0,0,90-865Zm0-8a.968.968,0,0,0,.713-.287A.968.968,0,0,0,91-874a.968.968,0,0,0-.287-.713A.968.968,0,0,0,90-875a.968.968,0,0,0-.713.287A.968.968,0,0,0,89-874a.968.968,0,0,0,.287.713A.968.968,0,0,0,90-873Zm0,13a9.738,9.738,0,0,1-3.9-.787,10.1,10.1,0,0,1-3.175-2.138,10.1,10.1,0,0,1-2.138-3.175A9.738,9.738,0,0,1,80-870a9.738,9.738,0,0,1,.787-3.9,10.1,10.1,0,0,1,2.138-3.175,10.1,10.1,0,0,1,3.175-2.138A9.738,9.738,0,0,1,90-880a9.738,9.738,0,0,1,3.9.787,10.1,10.1,0,0,1,3.175,2.138,10.1,10.1,0,0,1,2.138,3.175A9.738,9.738,0,0,1,100-870a9.738,9.738,0,0,1-.787,3.9,10.1,10.1,0,0,1-2.138,3.175,10.1,10.1,0,0,1-3.175,2.138A9.738,9.738,0,0,1,90-860Zm0-2a7.721,7.721,0,0,0,5.675-2.325A7.721,7.721,0,0,0,98-870a7.721,7.721,0,0,0-2.325-5.675A7.721,7.721,0,0,0,90-878a7.721,7.721,0,0,0-5.675,2.325A7.721,7.721,0,0,0,82-870a7.721,7.721,0,0,0,2.325,5.675A7.721,7.721,0,0,0,90-862ZM90-870Z"
              transform="translate(-80 880)" fill="#52606d" />
          </svg>
        </h3>
        <div class="fields-group">
          <mat-form-field>
            <mat-label>{{"quiz.messageGoesHere"|translate}}</mat-label>
            <textarea matInput formControlName="note" maxlength="250"></textarea>
          </mat-form-field>
        </div>
      </div>

      <button class="w-100" type="button" mat-raised-button color="primary" [disabled]="loading" [class.loading]='loading' *ngIf="!data.isAutomatically"
        (click)="saveRecord()">
        {{"forms.Submit"|translate}}
      </button>
    </form>
  </div>
</mat-dialog-content>
