import { Component, OnInit, NgZone, HostListener } from '@angular/core';
import { TranslationService } from './services/translation.service';
import { TenantService } from './services/tenant.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {

  constructor(
    private translateService: TranslationService,
    private tenantService: TenantService
  ) {

    this.getTenant();
  }

  ngOnInit() {
    this.setLnag();

  }

  setLnag() {
    const lang = this.translateService.getSelectedLanguage();
    this.translateService.setLanguage(lang);
  }

  getTenant() {
    this.tenantService.getTenant().subscribe(
      (data: any) => {
        this.tenantService.defaultTenantSubject.next(data);
      },
      (err) => {
        console.error(err);
      }
    );
  }
  removeTags(str) {
    if (str === null || str === '') {
      return false;
    } else {
      str = str.toString();
      return str.replace(/(<([^>]+)>)/gi, '');
    }
  }
}
