<div mat-dialog-title>
  <h2>
    {{"userManagment.AddNewUser"|translate}}
  </h2>
  <button type="button" class="close" mat-dialog-close aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<mat-dialog-content>
  <div class="mat-dialog-body">
    <div class="select-type-section-title">{{"userManagment.selectType"|translate}}</div>
    <div class="select-type-section">
      <div class="sel-box-container" (click)="select(1)">
        <img src="./assets/images/lab-internal-staff-illustration.svg" alt="">
        <h5>{{"userManagment.internalStaff"|translate}}</h5>
      </div>
      <div class="sel-box-container" (click)="select(2)">
        <img src="./assets/images/pathologist-illustration.svg" alt="">
        <h5>{{"userManagment.pathologist"|translate}}</h5>
      </div>
    </div>
  </div>
</mat-dialog-content>