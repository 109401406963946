<div class="common-page">
  <div class="page-container">
    <ng-lottie [options]="options"></ng-lottie>

    <div class="page-titles text-center">
      <h2>{{'general.pageNotFound' | translate}}</h2>
      <p>{{'general.cannotFindWhatYoureLookingFor' | translate}}</p>

      <!-- <button mat-raised-button color='primary' (click)="location.back()">Return Back</button> -->
    </div>
  </div>
</div>