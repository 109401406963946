import { EntityEnumSLA } from './../../../models/sla/enums/EntityEnumSLA';
import { ActivatedRoute, Router } from '@angular/router';
import { LabsService } from 'src/app/services/labs.service';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { LabFormComponent } from '../lab-form/lab-form.component';
import { LabDetails } from 'src/app/models/labs/LabDetails';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { AuthUser } from 'src/app/models/auth/AuthUser';
import { PaymentsService } from 'src/app/services/payments.service';
import { CurrencyService } from 'src/app/services/currency.service';
import { DefaultCurrency } from 'src/app/models/currency/DefaultCurrency';
import { ListHeaderData } from 'src/app/models/payments/list-header-data';
import { BranchesService } from 'src/app/services/branches.service';
import { SwalService } from 'src/app/services/swal.service';
import { VerificationService } from 'src/app/services/verification.service';

@Component({
  selector: 'app-lab-profile',
  templateUrl: './lab-profile.component.html'
})
export class LabProfileComponent implements OnInit {
  openSideNav: boolean;
  selectedRecord: any;
  dataLoading = false;

  labId: number;
  details: LabDetails = new LabDetails();
  isOurProfile = false;
  isOurProfileVl = false;
  currentUser = new AuthUser();
  walletDetails: ListHeaderData = new ListHeaderData();
  defaultCurrency: DefaultCurrency;
  entityEnumSLA = EntityEnumSLA;

  constructor(
    public dialog: MatDialog,
    private service: LabsService,
    public translate: TranslateService,
    private activatedRoute: ActivatedRoute,
    private auth: AuthenticationService,
    private router: Router,
    public servicePayment: PaymentsService,
    private currencyService: CurrencyService,
    private branchesService: BranchesService,
    private verifyService: VerificationService,
    private swalService: SwalService
  ) {
    this.currentUser = this.auth.currentUserValue;
    this.defaultCurrency = this.currencyService.defaultCurrencyValue;
  }

  ngOnInit() {
    this.activatedRoute.paramMap.subscribe(params => {
      if (params.get('id')) {
        this.labId = Number(params.get('id'));
        this.getDetails();
      } else {
        const currentRoute = this.router.url.split('/')[this.router.url.split('/').length - 1];

        if (currentRoute === 'vlProfile') {
          this.isOurProfileVl = true;
        } else {
          this.isOurProfile = true;
        }
        this.getLabProfileDetails();
      }
    });
    this.service.refreshDetails.subscribe(data => {
      if (data) {
        if (this.isOurProfile || this.isOurProfileVl) {
          this.getLabProfileDetails();
        } else {
          this.getDetails();
        }
      }
    });
  }

  getDetails() {
    this.dataLoading = true;
    this.service.getDetails(this.labId).subscribe(
      data => {
        if (data) {
          this.details = data as LabDetails;
          this.details.id = this.labId;
          this.getEntityWalletDetails();
        }
      },
      error => {
        this.dataLoading = false;
        if (this.hasPermission('SuperAdmin')) {
          this.router.navigate(['/admin/labs']);
        } else {
          this.router.navigate(['/admin/branches/lab']);
        }

      });
  }

  getLabProfileDetails() {
    this.dataLoading = true;
    this.service.getLabProfileDetails().subscribe(
      data => {
        if (data) {
          this.details = data as LabDetails;
          this.getEntityWalletDetails();
        }
      },
      error => {
        this.dataLoading = false;
        // this.router.navigate(['/admin/labs']);
      });
  }

  getEntityWalletDetails() {
    let query: any = {};
    if (!this.isOurProfile && !this.isOurProfileVl) {
      query = {
        entityId: this.labId,
        entityType: EntityEnumSLA.lab
      };
    }
    this.servicePayment.getEntityWalletDetails(query).subscribe(
      data => {
        if (data) {
          this.dataLoading = false;
          this.walletDetails = data as ListHeaderData;
        }
      },
      error => {
        this.dataLoading = false;
      });
  }

  openLabForm(addBranch) {
    const dialogRef = this.dialog.open(LabFormComponent, {
      width: '600px',
      data: {
        recordId: this.labId,
        record: !addBranch ? this.details : null,
        addBranch,
        isOurProfile: !addBranch ? this.isOurProfile : false,
        isOurProfileVl: !addBranch ? this.isOurProfileVl : false
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (this.isOurProfile || this.isOurProfileVl) {
          this.getLabProfileDetails();
        } else {
          this.getDetails();
        }
      }
    });
  }

  showBranches() {
    this.openSideNav = true;
  }

  closeSidenav(e) {
    this.openSideNav = false;
  }

  deleteRecord(labId) {
    this.swalService.swalConfirmation(this.translate.instant('swalMsg.Yesdeleteit'))
      .then((result) => {
        if (result.value) {
          this.dataLoading = true;
          this.service.deleteRecord(labId).subscribe(
            data => {
              if (data) {
                this.dataLoading = false;
                this.swalService.swalSuccess()
                  .then(() => {
                    if (labId === this.labId) {
                      this.router.navigate(['/admin/labs']);
                    } else {
                      this.getDetails();
                      this.branchesService.refreshProfileBraches.next(true);
                    }
                  });
              }
            },
            err => {
              this.dataLoading = false;
              // console.log('Try again!, ' + error);
              const errors = JSON.parse(err.detail);
              let message = '';
              if (err.detail) {
                errors.ErrorDTO.forEach((error) => {
                  if (error.ErrorCode === 'CantDelete') {
                    message = error.Error;
                  }
                });
              } else {
                message = err.title;
              }
              this.swalService.swalFailed(message);
            });
        }
      });
  }

  verfiyEmail(e) {
    this.swalService.swalConfirmation(this.translate.instant('swalMsg.yes'), this.translate.instant('swalMsg.verifyEmail')).then((result) => {
      if (result.value) {
        this.dataLoading = true;
        const record = {
          entityId: this.labId ? this.labId : this.currentUser.ObjectValue,
          entityType: this.isOurProfileVl ? EntityEnumSLA.vl : EntityEnumSLA.lab
        };
        this.verifyService.sendVerifyEntityEmail(record).subscribe(
          data => {
            if (data) {
              this.dataLoading = false;
              this.swalService.swalSuccess(this.translate.instant('swalMsg.sendSuccessfully'))
                .then(() => {
                  // this.getDetails();
                  // this.service.refreshProfileBraches.next(true);
                });
            }
          },
          err => {
            this.dataLoading = false;
            // console.log('Try again!, ' + error);
            this.swalService.swalFailed(err.title);
          });
      }
    });
  }

  verifyMobile(e) {
    this.service.refreshDetails.next(e);
  }

  hasPermission(permission: string) {
    return this.auth.hasPermission(permission);
  }

}
