import { MatPaginatorIntl } from '@angular/material/paginator';

export function CustomPaginator() {
  const customPaginatorIntl = new MatPaginatorIntl();
  const lang = localStorage.getItem("language")
  // customPaginatorIntl.itemsPerPageLabel = 'Entries per page: ';
  customPaginatorIntl.itemsPerPageLabel = lang == 'ar' ? 'عدد المدخلات المعروضة ' : 'Number of displayed entries';
  customPaginatorIntl.getRangeLabel = (page: number, pageSize: number, length: number) => {
    if (length == 0 || pageSize == 0) { return `0 ` + lang == 'ar' ? ' من'  : ' of ' + ` ${length}`; }

    length = Math.max(length, 0);

    const startIndex = page * pageSize;

    // If the start index exceeds the list length, do not try and fix the end index to the end.
    const endIndex = startIndex < length ?
      Math.min(startIndex + pageSize, length) :
      startIndex + pageSize;

    return `${startIndex + 1} - ${endIndex} ${lang == 'ar' ? 'من' : 'of'} ${length}`;

  }
  return customPaginatorIntl;
}
