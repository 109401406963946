<div class="floating-details {{custClass}}" [ngClass]="{'activeDetails': open}">
  <div class="d-flex h-100">
    <div class="overlay" (click)="close()">
      <div class="closeDetails">
        <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 18 18"><path id="close-btn" d="M27,10.575,25.425,9,18,16.425,10.575,9,9,10.575,16.425,18,9,25.425,10.575,27,18,19.575,25.425,27,27,25.425,19.575,18Z" transform="translate(-9 -9)" fill="#fff"/></svg>
      </div>
    </div>
    <div class="details-container">
      <ng-container *ngIf="open">
        <ng-content></ng-content>
      </ng-container>
    </div>
  </div>
</div>
