import { Component, OnInit } from '@angular/core';
import { AnimationOptions } from 'ngx-lottie';
import { Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-error',
  templateUrl: './error.component.html'
})

export class ErrorComponent implements OnInit {
  options: AnimationOptions = {
    path: '/assets/js/404.json',
    loop: false
  };

  constructor(
    public location: Location,
    public translate: TranslateService
  ) { }

  ngOnInit(): void {
  }

}
