<div mat-dialog-title>
  <h2>
    {{"profile.changeUsername"|translate}}
  </h2>
  <button type="button" class="close" mat-dialog-close aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<mat-dialog-content>
  <div class="mat-dialog-body">
    <form [formGroup]="formGroup" (ngSubmit)='submit()'>
      <h4 class="label-form">
        {{"profile.enterNewUsername"|translate}}
      </h4>
      <mat-form-field class="form-group" hideRequiredMarker>
        <mat-label>{{"profile.newUsername"|translate}}</mat-label>
        <input matInput formControlName="UserName" autocomplete="off" maxlength="20">
        <mat-error *ngIf="hasError('UserName', 'required')">{{ 'forms.Required' | translate }}</mat-error>
        <mat-error *ngIf="hasError('UserName', 'pattern')">{{"profile.invalidUsername"|translate}}</mat-error>
        <mat-error *ngIf="hasError('UserName', 'notUniqe')">
          {{"profile.usernameAlreadyExists"|translate}}
        </mat-error>
      </mat-form-field>
      <!-- <p class="label-helper">You'll be able to change your username back for another 14 days</p> -->
      <div class="btn-container">
        <button mat-raised-button color="primary" class="w-100" [disabled]='loading' [class.loading]='loading'>
          <span>{{"profile.changeMyUsername"|translate}}</span>
        </button>
        <button mat-raised-button mat-dialog-close color="grey" class="w-100 mt-4">
          <span>{{"swalMsg.cancel"|translate}}</span>
        </button>
      </div>
    </form>
  </div>
</mat-dialog-content>