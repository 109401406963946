import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { AttachmentsValueModel } from 'src/app/models/lookups/AttachmentsValueModel';

@Component({
  selector: 'app-attachment-details',
  templateUrl: './attachment-details.component.html'
})
export class AttachmentDetailsComponent implements OnInit {
  @ViewChild('pdfContainer', { static: true }) pdfContainer!: ElementRef<HTMLDivElement>;
  @ViewChild('videoplayer') videoplayer!: ElementRef<HTMLVideoElement>;
  @Output() closeDetails = new EventEmitter<boolean>();
  @Input() attachment: AttachmentsValueModel;
  @Input() isVideo = false;
  dataLoading = false;
  url: any;
  urlOfficeapps: any;
  filePreview: string;

  constructor(
    private sanitizer: DomSanitizer,
    private renderer: Renderer2
  ) { }

  ngOnInit() {
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.attachment.path + (!this.fileType(this.attachment.name) ? '?fs=0' : ''));
    this.urlOfficeapps = this.sanitizer.bypassSecurityTrustResourceUrl('https://view.officeapps.live.com/op/embed.aspx?src=' + this.attachment.path);

  }

  closeSidenavDetails() {
    this.closeDetails.emit();
  }
  fileType(fileName) {
    const extension = fileName.split('.').pop().toLowerCase();
    switch (extension) {
      case 'mp4':
        return 'mp4';
      case 'mkv':
        return 'mkv';
      case 'avi':
        return 'avi';
      case 'mov':
        return 'mov';
      case 'pdf':
        return 'pdf';
      case 'jpeg':
        return 'jpeg';
      case 'jpg':
        return 'jpg';
      case 'png':
        return 'png';
      case 'doc':
      case 'docx':
      case 'wpd':
      case 'tex':
        return 'doc';
      case 'xlsx':
      case 'xls':
        return 'xls';
      case 'zip':
      case 'rar':
        return 'zip';
      case 'ppt':
      case 'pptx':
        return 'pptx';
      case 'svs':
      case 'tif':
      case 'ndpi':
      case 'scn':
      case 'mrxs':
      case 'svslide':
      case 'bif':
        return 'wsi';
      default:
        return false;
    }
  }

  viewInIframe(attachment) {
    if (this.fileType(attachment.name) != 'mp4' && this.fileType(attachment.name) != 'mkv' && this.fileType(attachment.name) != 'avi' && this.fileType(attachment.name) != 'mov' && this.fileType(attachment.name) != 'pdf' && this.fileType(attachment.name) != 'jpeg' && this.fileType(attachment.name) != 'jpg' && this.fileType(attachment.name) != 'avi' && this.fileType(attachment.name) != 'mov' && this.fileType(attachment.name) != 'pdf' && this.fileType(attachment.name) != 'jpeg' && this.fileType(attachment.name) != 'jpg' && this.fileType(attachment.name) != 'png' && this.fileType(attachment.name) != 'pptx') {
      return true
    } else {
      return false
    }
  }

  viewFile(filePath: string) {
    this.filePreview = filePath;
    this.videoplayer.nativeElement.pause();
  }

  closePlayer(currentTime: number) {
    this.videoplayer.nativeElement.currentTime = currentTime;
    this.videoplayer.nativeElement.play();
    this.filePreview = '';
  }

}
