import { Component, OnInit } from '@angular/core';
import { Lookup } from 'src/app/models/lookups/Lookup';

@Component({
  selector: 'app-chatbot',
  templateUrl: './chatbot.component.html',
})
export class ChatbotComponent implements OnInit {
  openChat = false;
  loading = false;
  isDragging = false;

  data: any[] = [
    {
      id: 79,
      name: 'Manufacture of food products',
      childs: [
        {
          id: 80,
          name: 'Processing and preserving of meat',
          childs: [],
        },
        {
          id: 81,
          name: 'Processing and preserving of fish, crustaceans and molluscs',
          childs: [],
        },
        {
          id: 82,
          name: 'Processing and preserving of fruit and vegetables',
          childs: [],
        }
      ],
    },{
      id: 79,
      name: 'Processing and preserving of meat',
      text: 'OK',
      childs: [
        {
          id: 81,
          name: 'Processing and preserving of fish, crustaceans and molluscs',
          childs: [],
        },
        {
          id: 82,
          name: 'Processing and preserving of fruit and vegetables',
          childs: [],
        }
      ],
    },{
      id: 79,
      name: 'Processing and preserving of fish, crustaceans and molluscs',
      text: 'OK',
      childs: [
        {
          id: 82,
          name: 'Processing and preserving of fruit and vegetables',
          childs: [],
        }
      ],
    },

  ];
  selectedNode: any = null;
  selectedChild: any = null;
  messages:Lookup[] = []

  constructor() {}

  ngOnInit(): void {
    this.messages.push(this.data[0])
  }


  selectNode(message: string) {
    this.loading = true;
    const record = this.data.find(item => item.name == message);
    if (record) {
      record.mine=true;
      this.messages.push(record)
      this.scrollToChat();
      setTimeout(() => {
        this.loading = false;
        this.messages.push({...record,mine:false});
        this.scrollToChat();
      }, 2000);
    }
  }

  selectChild(child: any) {
    this.selectedChild = child;
  }

  backToNodes() {
    this.selectedNode = null;
    this.selectedChild = null;
  }

  backToChildren() {
    this.selectedChild = null;
  }

  scrollToChat() {
    setTimeout(() => {
      if (document.querySelectorAll('#chat' + ' ul').length) {
        document.getElementById('chat').scrollTop =
          document.querySelectorAll('#chat' + ' ul')[0].clientHeight;
      }
    }, 100);
  }

  clearChat (e) {
    e.stopPropagation();
    this.messages = []
    this.messages.push(this.data[0])
  }
  onDragStarted () {
    this.isDragging = true;
  }
  openChatFun () {
    if (!this.isDragging) {
      this.openChat = !this.openChat
    }
    this.isDragging = false;
  }
}
