<div class="sidenav-user-toolbar justify-content-start flex-nowrap">
  <button class="btn-back" mat-icon-button [matTooltip]="'back'" (click)="closeSidenavDetails()">
    <svg xmlns="http://www.w3.org/2000/svg" width="15.583" height="15.175" viewBox="0 0 15.583 15.175">
      <path id="Path_18837" data-name="Path 18837"
        d="M19,11H7.83l4.88-4.88a1.008,1.008,0,0,0,0-1.42,1,1,0,0,0-1.41,0L4.71,11.29a1,1,0,0,0,0,1.41l6.59,6.59a1,1,0,0,0,1.41-1.41L7.83,13H19a1,1,0,0,0,0-2Z"
        transform="translate(-4.417 -4.407)" fill="#555" />
    </svg>
  </button>
  <h4>
    {{attachment.name}}
  </h4>
</div>
<div class="sidenav-user-container">
  <div class="page w-100">
    <div class="sidenav-assignment-content p-4">
      <ng-container
        *ngIf="viewInIframe(attachment) else otherAttach">
        <iframe [src]="url" width="100%" allowfullscreen="true" webkitallowfullscreen="true" mozallowfullscreen="true"
          [ngClass]="{'video-iframe': isVideo}" height="auto" class="text-center attachment-iframe"
          frameborder="0"></iframe>
      </ng-container>
      <ng-template #otherAttach>
        <pdf-viewer *ngIf="fileType(attachment.name) == 'pdf';else viewVideo" [src]="attachment.path"
          [fit-to-page]="true" style="width: 100%; height: 600px;">
        </pdf-viewer>
      </ng-template>
      <ng-template #viewVideo>
        <ng-container *ngIf="this.fileType(attachment.name) != 'jpeg'  && this.fileType(attachment.name) != 'jpg' && this.fileType(attachment.name) != 'png' && this.fileType(attachment.name) != 'pptx';else viewpptx">
          <video #videoplayer class="w-100 video-iframe" controls controlsList="nodownload nofullscreen" height="auto">
            <source [src]="url">
          </video>
          <button mat-raised-button color="grey" class="w-100" (click)="viewFile(attachment.path)">
            {{'viewer.fullScreen'| translate}}
          </button>
        </ng-container>
      </ng-template>
      <ng-template #viewpptx>
        <ng-container *ngIf="this.fileType(attachment.name) == 'pptx' else viewImages">
          <div class="pttx-container">
            <iframe
            [src]="urlOfficeapps"
            width="100%"
            height="500px"
            frameborder="0">
          </iframe>
          </div>
        </ng-container>
      </ng-template>
      <ng-template #viewImages>
        <img [src]="attachment.path" class="img-fluid" />
      </ng-template>

    </div>

  </div>

</div>

<app-light-gallery-video [videoUrl]="filePreview" [currentTime]="videoplayer.nativeElement.currentTime"
  *ngIf="filePreview" (closeEvent)="closePlayer($event)"></app-light-gallery-video>
