import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { SwalService } from 'src/app/services/swal.service';

@Component({
  selector: 'app-respondent-message',
  templateUrl: './respondent-message.component.html'
})
export class RespondentMessageComponent implements OnInit {
  formGroup: FormGroup;
  loading = false;
  isSubmitted = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<RespondentMessageComponent>,
    public translate: TranslateService,
    private swalService: SwalService,
    private formBuilder: FormBuilder
  ) {}


  ngOnInit(): void {
    this.buildForm();
    if (this.data) {
      this.bindData();
    }
  }

  get f() {
    return this.formGroup.controls;
  }

  bindData() {
    this.f.question.setValue(this.data.question)
    this.f.option.setValue(this.data.option)
    this.f.note.setValue(this.data.note)
    // this.f.question.disable()
    // this.f.option.disable()
   }
  buildForm() {
    this.formGroup = this.formBuilder.group({
      question: [{value:null,disabled:this.data.isAutomatically}],
      option: [{value:null,disabled:this.data.isAutomatically}],
      note: [{value:null,disabled:this.data.isAutomatically}],
    });
  }
  saveRecord () {
    this.dialogRef.close(this.f.note.value)
  }

  hasError(controlName: string, errorName: string) {
    return this.formGroup.controls[controlName].hasError(errorName);
  }
}
