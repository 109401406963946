<div [formGroup]="_formGroup">
  <div [class.small_title]="isQuestionBank">
    <ng-content>
    </ng-content>
    <button *ngIf="isQuestionBank" type="button" class="btn btn-primary btn-sm" [disabled]="!questionsArray.valid"
      (click)="OnClickAddQuestion()" mat-raised-button color="primary">
      <svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 11 11">
        <path id="plus" d="M14.5,8.667H10.333V4.5H8.667V8.667H4.5v1.667H8.667V14.5h1.667V10.333H14.5Z"
          transform="translate(-4 -4)" fill="#fff" stroke="rgba(0,0,0,0)" stroke-width="1" />
      </svg>
      <span class="ml-2">{{"quiz.addQuestion"|translate}}</span>
    </button>

  </div>
  <div class="questions-list">
    <ng-container formArrayName="questionsArray">
      <div class="set-list fields-group" cdkDropList (cdkDropListDropped)="drop($event)"
        [cdkDropListDisabled]="questionsArray.controls.length == 1">
        <div class="box-drag" [class.dragging]="dragging"
          *ngFor="let question of questionsArray.controls; let questionIndex = index" cdkDrag
          (cdkDragStarted)="onDragStart()" (cdkDragEnded)="onDragEnd(questionIndex)">

          <div class="p-1 w-100 text-center" *ngIf="AllowLinkQuestions && questionIndex!=0">
            <a (click)="OnLinkOrder(questionIndex)">
              <ng-container *ngIf="isLinked(questionIndex); else elseTemplate_linked">
                <svg id="Link_Questions" data-name="Link Questions" xmlns="http://www.w3.org/2000/svg" width="24"
                  height="48" viewBox="0 0 24 48">
                  <rect id="line" width="1" height="10" rx="0.5" transform="translate(11.5 38)" fill="#d96ca4" />
                  <g id="Link_Questions-2" data-name="Link Questions" transform="translate(0 -1979)">
                    <rect id="Container" width="24" height="24" rx="4" transform="translate(0 1991)" fill="#c92d7d" />
                    <g id="link_questions-3" data-name="link_questions" transform="translate(8 1995)">
                      <path id="ic_linked_questions"
                        d="M3.822,8a3.6,3.6,0,0,1-2.7-1.17A3.947,3.947,0,0,1,0,4,3.948,3.948,0,0,1,1.118,1.17,3.6,3.6,0,0,1,3.822,0H6.364a.6.6,0,0,1,.45.19A.656.656,0,0,1,7,.661a.655.655,0,0,1-.182.471.6.6,0,0,1-.45.19H3.821a2.41,2.41,0,0,0-1.809.784A2.642,2.642,0,0,0,1.263,4a2.643,2.643,0,0,0,.749,1.894,2.41,2.41,0,0,0,1.809.784H6.364a.6.6,0,0,1,.45.19.7.7,0,0,1,0,.942.6.6,0,0,1-.45.19ZM5.474,4.661a.6.6,0,0,1-.45-.19.7.7,0,0,1,0-.942.6.6,0,0,1,.45-.19h5.053a.6.6,0,0,1,.45.19.7.7,0,0,1,0,.942.6.6,0,0,1-.45.19ZM9.636,8a.6.6,0,0,1-.45-.19.7.7,0,0,1,0-.942.6.6,0,0,1,.45-.19h2.544a2.41,2.41,0,0,0,1.809-.784A2.643,2.643,0,0,0,14.737,4a2.642,2.642,0,0,0-.749-1.894,2.41,2.41,0,0,0-1.809-.784H9.636a.6.6,0,0,1-.45-.19A.656.656,0,0,1,9,.661.654.654,0,0,1,9.186.19.6.6,0,0,1,9.636,0h2.543a3.6,3.6,0,0,1,2.7,1.17A3.946,3.946,0,0,1,16,4a3.948,3.948,0,0,1-1.118,2.83A3.6,3.6,0,0,1,12.178,8Z"
                        transform="translate(0 16) rotate(-90)" fill="#fff" />
                    </g>
                  </g>
                  <rect id="line-2" data-name="line" width="1" height="10" rx="0.5" transform="translate(11.5)"
                    fill="#d96ca4" />
                </svg>
              </ng-container>
              <ng-template #elseTemplate_linked>
                <svg id="Link_Questions" data-name="Link Questions" xmlns="http://www.w3.org/2000/svg" width="24"
                  height="48" viewBox="0 0 24 48">
                  <rect id="line" width="1" height="10" rx="0.5" transform="translate(11.5 38)" fill="#d6dbdf" />
                  <g id="Link_Questions-2" data-name="Link Questions" transform="translate(0 -1979)">
                    <rect id="Container" width="24" height="24" rx="4" transform="translate(0 1991)" fill="#f5f6f7" />
                    <g id="link_questions-3" data-name="link_questions" transform="translate(8 1995)">
                      <path id="ic_link_questions"
                        d="M3.822,8a3.6,3.6,0,0,1-2.7-1.17A3.947,3.947,0,0,1,0,4,3.948,3.948,0,0,1,1.118,1.17,3.6,3.6,0,0,1,3.822,0H6.364a.6.6,0,0,1,.45.19A.656.656,0,0,1,7,.661a.655.655,0,0,1-.182.471.6.6,0,0,1-.45.19H3.821a2.41,2.41,0,0,0-1.809.784A2.642,2.642,0,0,0,1.263,4a2.643,2.643,0,0,0,.749,1.894,2.41,2.41,0,0,0,1.809.784H6.364a.6.6,0,0,1,.45.19.7.7,0,0,1,0,.942.6.6,0,0,1-.45.19ZM9.636,8a.6.6,0,0,1-.45-.19.7.7,0,0,1,0-.942.6.6,0,0,1,.45-.19h2.544a2.41,2.41,0,0,0,1.809-.784A2.643,2.643,0,0,0,14.737,4a2.642,2.642,0,0,0-.749-1.894,2.41,2.41,0,0,0-1.809-.784H9.636a.6.6,0,0,1-.45-.19A.656.656,0,0,1,9,.661.654.654,0,0,1,9.186.19.6.6,0,0,1,9.636,0h2.543a3.6,3.6,0,0,1,2.7,1.17A3.946,3.946,0,0,1,16,4a3.948,3.948,0,0,1-1.118,2.83A3.6,3.6,0,0,1,12.178,8Z"
                        transform="translate(0 16) rotate(-90)" fill="#aeb6bf" />
                    </g>
                  </g>
                  <rect id="line-2" data-name="line" width="1" height="10" rx="0.5" transform="translate(11.5)"
                    fill="#d6dbdf" />
                </svg>
              </ng-template>

            </a>
          </div>

          <ng-container [formGroupName]="questionIndex">
            <div class="sets-conatiner" [ngClass]="{'mb-0': AllowLinkQuestions , 'mb-3':!AllowLinkQuestions}">
              <div
                [class]="{'not-valid': (!questionsArray.controls[questionIndex].valid && isSubmitted && !questionsArray.controls[questionIndex].disabled) || (!questionsArray.controls[questionIndex].valid && isTraditionalAssessment && questionsArray.controls[questionIndex].touched), 'collapsed': !isExpandQustion[questionIndex],'opened':questionIndex + 1 == questionsArray.controls.length} "
                class="set-item collapsed">
                <div class="set-head" (click)="expandQuestions(questionIndex)">
                  <div class="draggable-icon">
                    <svg cdkDragHandle xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                      <g id="draggable-dots-ic" transform="translate(24) rotate(90)">
                        <rect id="container" width="24" height="24" fill="none" />
                        <path id="draggable-dots-ic-2" data-name="draggable-dots-ic"
                          d="M2,7A2,2,0,1,0,4,9,2,2,0,0,0,2,7Zm0,7a2,2,0,1,0,2,2A2,2,0,0,0,2,14ZM9,4A2,2,0,1,0,7,2,2,2,0,0,0,9,4ZM2,0A2,2,0,1,0,4,2,2,2,0,0,0,2,0ZM9,14a2,2,0,1,0,2,2A2,2,0,0,0,9,14ZM9,7a2,2,0,1,0,2,2A2,2,0,0,0,9,7Z"
                          transform="translate(6.788 3)" fill="#7b8794" />
                      </g>
                    </svg>
                  </div>
                  <div class="title w-100">
                    <span *ngIf="isExpandQustion[questionIndex]">{{"quiz.question"|translate}} {{questionIndex +
                      1}}</span>
                    <span *ngIf="!isExpandQustion[questionIndex]"><strong>{{"quiz.question"|translate}}
                        {{questionIndex + 1}} : </strong>
                      {{getQuestion(questionIndex, 'questionText').value}}</span>
                  </div>
                  <div class="d-flex align-items-center item-action">
                    <div>
                      <mat-icon class="more">unfold_more</mat-icon>
                      <mat-icon class="less">unfold_less</mat-icon>
                    </div>
                  </div>
                </div>

                <div class="set-content">
                  <div class="row">
                    <!-- Question Category -->
                    <div class="col-md-12" *ngIf="isQuestionBank">
                      <mat-form-field class="matSuffix-control">
                        <mat-label>{{"questionsBankModule.questionCategory"|translate}}</mat-label>
                        <mat-select formControlName="QuestionBankCategoryId" placeholder="" [required]="isQuestionBank">
                          <mat-option *ngFor="let item of allCategories" [value]="item.id">
                            {{item.name}}
                          </mat-option>
                        </mat-select>
                        <mat-error
                          *ngIf="hasArrayError(questionsArray.controls[questionIndex], 'QuestionBankCategoryId','required')">
                          {{ "forms.Required" | translate }}
                        </mat-error>
                      </mat-form-field>
                    </div>

                    <!-- Question Difficulty -->
                    <div class="col-md-12" *ngIf="isQuestionBank">
                      <mat-form-field class="matSuffix-control">
                        <mat-label>{{"questionsBankModule.difficultyLevel"|translate}}</mat-label>
                        <mat-select formControlName="QuestionBankDifficultyId" placeholder=""
                          [required]="isQuestionBank">
                          <mat-option *ngFor="let item of allLevels" [value]="item.id">
                            {{item.name}}
                          </mat-option>
                        </mat-select>
                        <mat-error
                          *ngIf="hasArrayError(questionsArray.controls[questionIndex], 'QuestionBankDifficultyId','required')">
                          {{ "forms.Required" | translate }}
                        </mat-error>
                      </mat-form-field>
                    </div>

                    <!-- Tags -->
                    <div class="col-12" *ngIf="isQuestionBank">
                      <mat-form-field class="example-chip-list">
                        <mat-chip-list #chipList formControlName="QuestionTags" aria-label="tags selection">
                          <mat-chip *ngFor="let item of getTagsList(questionIndex)" [selectable]="false"
                            [removable]="true" (removed)="remove(item,questionIndex)">
                            {{item.name}}
                            <mat-icon matChipRemove>cancel</mat-icon>
                          </mat-chip>
                          <input [placeholder]="('slideDetails.newTag'|translate)+'...'" #tagsInput maxlength="50"
                            [matAutocomplete]="auto" [matChipInputFor]="chipList"
                            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                            (matChipInputTokenEnd)="add($event,questionIndex)" class="show">
                        </mat-chip-list>
                        <mat-autocomplete #auto="matAutocomplete"
                          (optionSelected)="selected($event,false,questionIndex)">
                          <mat-option *ngFor="let item of alltags" [value]="item">
                            {{item.name}}
                          </mat-option>
                        </mat-autocomplete>
                      </mat-form-field>
                    </div>

                    <!-- Question Type -->
                    <div class="col-md-12">
                      <mat-form-field class="matSuffix-control">
                        <mat-label>{{"quiz.questionType"|translate}}</mat-label>
                        <mat-select formControlName="questionType" placeholder="" [required]="isQuestionBank"
                          [disabled]="isAutomatically" (selectionChange)='questionTypeChange($event,questionIndex)'>
                          <mat-select-trigger>
                            <img [src]="questionsArray.controls[questionIndex].get('questionType').value?.icon"
                              class="option-img" />
                            {{ questionsArray.controls[questionIndex].get('questionType').value?.name }}
                          </mat-select-trigger>
                          <mat-option *ngFor="let item of listAllQuizQuestionType" [value]="item">
                            <img [src]="item.icon" class="option-img" /> {{item.name}}
                          </mat-option>
                        </mat-select>
                        <mat-error
                          *ngIf="hasArrayError(questionsArray.controls[questionIndex], 'questionType','required')">
                          {{ "forms.Required" | translate }}
                        </mat-error>
                      </mat-form-field>
                    </div>

                    <!-- Question Difficulty -->
                    <div class="col-md-12" *ngIf="isFinalAssessment">
                      <mat-form-field class="matSuffix-control">
                        <mat-label>{{"search.intendedLearningOutcomes"|translate}}</mat-label>
                        <mat-select formControlName="intendedLearningOutcomes" placeholder="" multiple>
                          <mat-option *ngFor="let item of allCourseOutComes" [value]="item.id">
                            {{item.name}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>



                    <!-- Question Attach -->
                    <div class="col-md-7 attach_file" *ngIf="!isSlideQuiz && !isAutomatically">
                      <h4>{{"forms.Attachments"|translate}} <span>({{"forms.Optional"|translate}})</span></h4>
                      <app-file-manager [isRequired]="false" [managerIndex]="'attachements'+questionIndex"
                        [isSubmitted]="isSubmitted" [allowedExt]="allowedExt" [maxSize]="5"
                        [label]="'slideDetails.dragAndDropFilesHere'|translate"
                        [files]="questionsArray.controls[questionIndex].get('files').value || []"
                        (selectedFiles)="OnSelectFiles( $event,questionIndex)"
                        (deletedFilesList)="DeletedFiles($event,questionIndex)"></app-file-manager>
                    </div>
                    <div class="col-md-7 attach_file"
                      *ngIf="isAutomatically && questionsArray.controls[questionIndex].get('files').value && questionsArray.controls[questionIndex].get('files').value.length">
                      <h4>{{"forms.Attachments"|translate}} <span>({{"forms.Optional"|translate}})</span></h4>
                      <div class="attchment details-items"
                        *ngIf="questionsArray.controls[questionIndex].get('files').value && questionsArray.controls[questionIndex].get('files').value.length && isAutomatically">
                        <app-files-viewer
                          [files]="questionsArray.controls[questionIndex].get('files').value"></app-files-viewer>
                      </div>
                    </div>
                    <ng-container *ngIf="isAssessment || isQuestionBank">

                      <div class="col-md-7 attach_file">
                        <h4>{{"forms.AttachVideos"|translate}} <span>({{"forms.Optional"|translate}})</span></h4>
                        <div class="form-group">
                          <div class="radio-selector quiz-radio-selector">
                            <mat-radio-group class="row" formControlName="videoMethodType">
                              <mat-radio-button [disabled]="isAutomatically" [value]="item.value" class="col-6" *ngFor="let item of videoMethodsList">
                                {{item.name}}
                              </mat-radio-button>
                            </mat-radio-group>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-7" *ngIf="questionsArray.controls[questionIndex].get('videoMethodType').value == VideoMethodsType.VIDEOURL">
                        <mat-form-field class="matSuffix-control">
                          <mat-label>{{"forms.EnterVideoUrl"|translate}}</mat-label>
                          <input matInput formControlName="videoUrl" maxlength="500" [readonly]="isAutomatically">
                          <mat-error *ngIf="hasArrayError(questionsArray.controls[questionIndex], 'videoUrl','videoValid')">
                            {{ "forms.PleaseenteravalidYouTubeorVimeoURL" | translate }}
                          </mat-error>
                          <mat-hint> {{ "forms.OnlyYouTubeOrVimeoLinksAreAccepted" | translate }}</mat-hint>
                        </mat-form-field>
                        <!-- <iframe *ngIf="videoUrl" [src]="videoUrl" (error)="onIframeError()" class="w-50 mt-3 border-0"></iframe> -->
                      </div>

                      <div class="col-md-7 attach_file" *ngIf="questionsArray.controls[questionIndex].get('videoMethodType').value == VideoMethodsType.UPLOADVIDEO && !isAutomatically">
                        <app-file-manager [isRequired]="false" [managerIndex]="'videoAttachment'+questionIndex"
                          [isSubmitted]="isSubmitted" [allowedExt]="videoAllowedExt" [maxFiles]="1" [maxSize]="100"
                          [label]="'slideDetails.dragAndDropFilesHere'|translate"
                          [files]="questionsArray.controls[questionIndex].get('videoAttachment').value || []"
                          (selectedFiles)="OnSelectVideoFiles( $event,questionIndex)"
                          (deletedFilesList)="DeletedVideoFiles($event,questionIndex)"></app-file-manager>
                      </div>
                      <div class="col-md-7 attach_file"
                        *ngIf="isAutomatically && questionsArray.controls[questionIndex].get('videoAttachment').value && questionsArray.controls[questionIndex].get('videoAttachment').value.length">
                        <div class="attchment details-items"
                          *ngIf="questionsArray.controls[questionIndex].get('videoAttachment').value && questionsArray.controls[questionIndex].get('videoAttachment').value.length && isAutomatically">
                          <app-files-viewer
                            [files]="questionsArray.controls[questionIndex].get('videoAttachment').value"></app-files-viewer>
                        </div>
                      </div>

                    </ng-container>

                    <div class="col-md-12 attach_file">
                      <h4>{{"quiz.question"|translate}}</h4>
                      <mat-form-field class="matSuffix-control">
                        <mat-label>{{"quiz.question"|translate}}</mat-label>
                        <input matInput formControlName="questionText" maxlength="500" [readonly]="isAutomatically">
                        <mat-error
                          *ngIf="hasArrayError(questionsArray.controls[questionIndex], 'questionText','required')">
                          {{ "forms.Required" | translate }}
                        </mat-error>
                      </mat-form-field>
                    </div>

                    <div class="col-md-12 choices">
                      <div class="row" [hidden]="showOptions(questionIndex)">
                        <div class="col-md-8 col-sm-12">
                          <h6>{{"poll.choices"|translate}}</h6>
                        </div>
                        <div class="col-md-4 p-0 col-sm-12 actions">
                          <div class="d-flex justify-content-start options-title"*ngIf="questionvalues(questionIndex).controls.length">
                            <p class="w-25" [hidden]="isQuestionBank"> {{"quiz.correctAnswer"|translate}}</p>
                            <p class="w-25" [hidden]="getQuestion(questionIndex, 'questionType').value?.id != QuizQuestionType.CKECKBOX || isQuestionBank">{{"quiz.scoringPoints"|translate}}</p>
                            <p class="w-25" [class.mx-5]="getQuestion(questionIndex, 'questionType').value?.id != QuizQuestionType.CKECKBOX || isQuestionBank">{{"quiz.respondentMessage"|translate}}</p>
                          </div>
                        </div>
                      </div>
                      <div formArrayName="options">
                        <div cdkDropList (cdkDropListDropped)="dropOptions($event , questionIndex)">
                          <div *ngFor="let item of questionvalues(questionIndex).controls; let optionIndex = index"
                            cdkDrag>
                            <div class="questions-mb" [formGroupName]="optionIndex">
                              <div class="row w-100">
                                <div class="col-xl-1 col-lg-1 col-md-1 col-3 icon">
                                  <svg cdkDragHandle xmlns="http://www.w3.org/2000/svg" width="11" height="18"
                                    viewBox="0 0 11 18">
                                    <g id="draggable-dots-ic" transform="translate(11 18) rotate(180)">
                                      <rect id="container" width="10.788" height="18" transform="translate(0.212)"
                                        fill="none" />
                                      <path id="draggable-dots-ic-2" data-name="draggable-dots-ic"
                                        d="M2,7A2,2,0,1,0,4,9,2,2,0,0,0,2,7Zm0,7a2,2,0,1,0,2,2A2,2,0,0,0,2,14ZM9,4A2,2,0,1,0,7,2,2,2,0,0,0,9,4ZM2,0A2,2,0,1,0,4,2,2,2,0,0,0,2,0ZM9,14a2,2,0,1,0,2,2A2,2,0,0,0,9,14ZM9,7a2,2,0,1,0,2,2A2,2,0,0,0,9,7Z"
                                        fill="#7b8794" />
                                    </g>
                                  </svg>
                                  <div
                                    [class.radio]="questionsArray.controls[questionIndex].get('questionType').value?.id == QuizQuestionType.MULTIPLECHOICE"
                                    class="checkbox"></div>
                                </div>
                                <div class="col-xl-7 col-lg-7 col-md-7 col-9">
                                  <div class="d-flex">
                                    <div class="icon">
                                      <span class="text-uppercase text-bold mx-2">{{getNextLetter(optionIndex)}}</span>
                                    </div>
                                    <mat-form-field class="form-group mb-0">
                                      <mat-label> {{"quiz.option"|translate}}</mat-label>
                                      <input matInput formControlName="text"
                                        [required]="questionsArray.controls[questionIndex].get('questionType').value?.id == QuizQuestionType.MULTIPLECHOICE || questionsArray.controls[questionIndex].get('questionType').value?.id == QuizQuestionType.CKECKBOX"
                                        maxlength="250" [readonly]="isAutomatically">
                                      <mat-error
                                        *ngIf=" hasOptionError(questionvalues(questionIndex).controls[optionIndex], 'text' ,'required')">
                                        {{ "forms.Required" | translate}}
                                      </mat-error>
                                      <mat-error
                                        *ngIf="hasOptionError(questionvalues(questionIndex).controls[optionIndex], 'text' ,'maxlength')">
                                        {{ "project.Max length is" | translate }} 250
                                      </mat-error>
                                      <mat-error
                                        *ngIf="hasOptionError(questionvalues(questionIndex).controls[optionIndex], 'text' ,'pattern')">
                                        {{"quiz.enterValidOption"|translate}}
                                      </mat-error>
                                    </mat-form-field>
                                  </div>
                                </div>
                                <div class="col-md-4 col-sm-12 actions">
                                  <ng-container *ngIf="questionvalues(questionIndex).controls.length">
                                    <div class="d-flex justify-content-between">
                                      <div class="pt-3">
                                        <mat-slide-toggle formControlName="isCorrectAnswer" [disabled]="isAutomatically"
                                          (change)="checkDisabled(questionvalues(questionIndex) , questionvalues(questionIndex).controls[optionIndex] , questionIndex, optionIndex); calcPoints(questionIndex , optionIndex);"
                                          [matTooltip]="'quiz.correctAnswer'|translate">
                                        </mat-slide-toggle>
                                      </div>
                                      <div [hidden]="this.getQuestion(questionIndex, 'questionType').value?.id !=
                                        QuizQuestionType.CKECKBOX || isQuestionBank">
                                        <mat-form-field class="matSuffix-control scoringPoints"
                                          [matTooltip]="'quiz.scoringPoints'|translate">
                                          <input appOnlynumber matInput formControlName="scoringPoints" [required]="this.getQuestion(questionIndex, 'questionType').value?.id ==
                                            QuizQuestionType.CKECKBOX && !isQuestionBank"
                                            (keyup)="calcPoints(questionIndex , optionIndex);checkCorrectAnswer(questionIndex, optionIndex)">
                                          <!-- <span *ngIf="!getOption(questionIndex, optionIndex, 'isCorrectAnswer').value" matPrefix class="prefix">
                                                <mat-icon>remove</mat-icon>
                                              </span> -->
                                          <mat-error
                                            *ngIf="hasOptionError(questionvalues(questionIndex).controls[optionIndex], 'scoringPoints' ,'required')">
                                            {{ "forms.Required" | translate}}
                                          </mat-error>
                                        </mat-form-field>
                                      </div>
                                      <div class="pt-2">
                                        <button *ngIf="getOption(questionIndex, optionIndex, 'note').value"
                                          mat-icon-button type="button"
                                          [matTooltip]="'quiz.respondentMessage'|translate"
                                          (click)="addNote(questionIndex,optionIndex)">
                                          <img src="./assets/images/icons/r-message.svg" />
                                        </button>
                                        <button *ngIf="!getOption(questionIndex, optionIndex, 'note').value"
                                          mat-icon-button type="button"
                                          [matTooltip]="'quiz.respondentMessage'|translate"
                                          (click)="addNote(questionIndex,optionIndex)">
                                          <img src="./assets/images/icons/n-message.svg" />
                                        </button>
                                      </div>
                                      <div class="pt-2">
                                        <button type="button" mat-icon-button class="removeOption"
                                          (click)="removeQuestionValue(questionIndex,optionIndex);calcPoints(questionIndex , optionIndex)"
                                          *ngIf="questionvalues(questionIndex).controls.length > 2 && !isAutomatically">
                                          <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10"
                                            viewBox="0 0 10 10">
                                            <path id="close"
                                              d="M15,6.007,13.993,5,10,8.993,6.007,5,5,6.007,8.993,10,5,13.993,6.007,15,10,11.007,13.993,15,15,13.993,11.007,10Z"
                                              transform="translate(-5 -5)" fill="#555" />
                                          </svg>
                                        </button>
                                      </div>
                                    </div>
                                  </ng-container>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <ng-container *ngIf="this.getQuestion(questionIndex, 'questionType').value?.id ==
                          QuizQuestionType.CKECKBOX && !isQuestionBank">
                          <ng-container *ngIf="getQuestion(questionIndex, 'totalPoints').value">
                            <div class="d-flex align-items-center col-md-6 offset-md-6 mt-2">
                              <label class="m-0">{{"quiz.totalScoringPoints"|translate}}</label>
                              <div class="totalPoints mx-2"
                                [class.error]="getQuestion(questionIndex, 'totalPoints').errors">
                                {{ getQuestion(questionIndex, 'totalPoints').value }}
                              </div>
                            </div>
                            <div class="d-flex align-items-center col-md-6 offset-md-6 mt-4"
                              *ngIf="getQuestion(questionIndex, 'totalPoints').errors">
                              <label
                                class="m-0 mat-error"><small>{{"quiz.totalPointsDeduction"|translate}}</small></label>
                            </div>
                          </ng-container>
                          <div class="col-md-12 mt-4">
                            <div class="mat-dialog-inner">
                              <div class="fields-group toggle penalty">
                                <mat-slide-toggle formControlName="penalty" [labelPosition]="'before'">
                                  <p>{{"quiz.penaltyForIncorrectOption"|translate}}</p>
                                </mat-slide-toggle>
                              </div>
                            </div>
                          </div>
                        </ng-container>
                        <div class="col-12" [hidden]="showOptions(questionIndex) || isAutomatically">
                          <div class="form-group questions-mb">
                            <button type="button" class="btn-trans-round" (click)="addQuestionValue(questionIndex)">
                              <svg class="m-0" xmlns="http://www.w3.org/2000/svg" width="12" height="12"
                                viewBox="0 0 11 11">
                                <path id="plus"
                                  d="M14.5,8.667H10.333V4.5H8.667V8.667H4.5v1.667H8.667V14.5h1.667V10.333H14.5Z"
                                  transform="translate(-4 -4)" fill="#7D4393" stroke="rgba(0,0,0,0)" stroke-width="1" />
                              </svg>
                            </button>
                          </div>
                        </div>

                        <div class="col-md-6">
                          <div
                            [class.d-none]="(getQuestion(questionIndex, 'questionType').value?.id == QuizQuestionType.CKECKBOX || isQuestionBank) ? true : false">
                            <div class="d-flex align-items-center mt-4">
                              <label class="mb-4">{{"quiz.totalScoringPoints"|translate}}</label>
                              <div class="mx-2 totalPoints-control w-50">
                                <mat-form-field>
                                  <input matInput formControlName="totalPoints" appOnlynumber maxlength="3"
                                    [required]="totalPointsValdation(questionIndex) && !isQuestionBank">
                                  <mat-error
                                    *ngIf="hasArrayError(questionsArray.controls[questionIndex], 'totalPoints','required')">
                                    {{ "forms.Required" | translate }}
                                  </mat-error>
                                  <mat-error
                                    *ngIf="hasArrayError(questionsArray.controls[questionIndex], 'totalPoints','min')">
                                    {{ "forms.min numbers" | translate }} 1
                                  </mat-error>
                                </mat-form-field>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <div class="d-flex align-items-center justify-content-end">
                        <p class="has-error h3 mb-3 mr-2"
                          *ngIf="hasArrayError(questionsArray.controls[questionIndex], 'options','required')">
                          {{"quiz.mustSelectCorrectAnswer"|translate}}
                        </p>
                        <button mat-button-icon type="button" class="btn"
                          *ngIf="!isAutomatically && questionsArray.controls.length > 1"
                          (click)="removeQuestion(questionIndex)">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="20" viewBox="0 0 16 20">
                            <path id="delete-icon"
                              d="M3.33,20a1.853,1.853,0,0,1-1.844-1.785L.976,7.393a.2.2,0,0,1,.192-.207H14.832a.194.194,0,0,1,.192.207l-.505,10.822A1.859,1.859,0,0,1,12.674,20ZM10.463,8.748v8.129a.616.616,0,1,0,1.231,0V8.748a.616.616,0,1,0-1.231,0Zm-3.079,0v8.129a.615.615,0,1,0,1.23,0V8.748a.615.615,0,1,0-1.23,0Zm-3.074,0v8.129a.615.615,0,1,0,1.23,0V8.748a.615.615,0,1,0-1.23,0ZM.618,5.938A.621.621,0,0,1,0,5.311V4.063A1.551,1.551,0,0,1,1.54,2.5H4.923V1.873A1.862,1.862,0,0,1,6.771,0H9.232a1.858,1.858,0,0,1,1.844,1.873V2.5H14.46A1.551,1.551,0,0,1,16,4.063V5.311a.617.617,0,0,1-.613.627ZM6.153,1.873V2.5H9.846V1.873a.616.616,0,0,0-.613-.621H6.771A.619.619,0,0,0,6.153,1.873Z"
                              fill="#616e7c" />
                          </svg>
                        </button>
                        <!-- [disabled]="!questionsArray.controls[questionIndex].valid" -->
                        <button mat-raised-button type="button" color="primary" (click)="saveQuestion(questionIndex)">
                          <svg xmlns="http://www.w3.org/2000/svg" width="15" height="11" viewBox="0 0 15 11">
                            <path id="check-mark"
                              d="M151.607,169.284a1.265,1.265,0,0,1,0,1.786l-8.455,8.474a1.259,1.259,0,0,1-1.782,0l-4.025-4.034a1.262,1.262,0,0,1,1.782-1.786l3.134,3.141,7.563-7.581A1.259,1.259,0,0,1,151.607,169.284Z"
                              transform="translate(-136.976 -168.914)" fill="#fff" />
                          </svg>

                          <span class="ml-2">{{"reports.save"|translate}}</span>
                        </button>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </ng-container>


        </div>
      </div>
    </ng-container>
    <button type="button" class="btn btn-primary btn-sm" *ngIf="!isAssessment" [disabled]="!questionsArray.valid"
      (click)="OnClickAddQuestion()" mat-raised-button color="primary">
      <svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 11 11">
        <path id="plus" d="M14.5,8.667H10.333V4.5H8.667V8.667H4.5v1.667H8.667V14.5h1.667V10.333H14.5Z"
          transform="translate(-4 -4)" fill="#fff" stroke="rgba(0,0,0,0)" stroke-width="1" />
      </svg>
      <span class="ml-2">{{"quiz.addQuestion"|translate}}</span>
    </button>

    <div class="btns-list" *ngIf="isAssessment && !isAutomatically">
      <button mat-raised-button color="primary" (click)="OnClickAddQuestion()" [class.disabled]="!questionsArray.valid">
        <svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 11 11">
          <path id="plus" d="M14.5,8.667H10.333V4.5H8.667V8.667H4.5v1.667H8.667V14.5h1.667V10.333H14.5Z"
            transform="translate(-4 -4)" fill="#fff" stroke="rgba(0,0,0,0)" stroke-width="1" />
        </svg>
        {{"quiz.addQuestion"|translate}}</button>
      <ng-content select="#addFromQuestionBanck"></ng-content>
    </div>

  </div>
</div>
