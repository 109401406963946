<div mat-dialog-title>
  <h4>{{"imageGuidelines.uploadID" | translate}}</h4>
  <button type="button" class="close" mat-dialog-close aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<mat-dialog-content>
  <div class="mat-dialog-body">
    <app-file-manager class="w-100" [isRequired]="true"
      [isSubmitted]="isSubmitted" [allowedExt]="['jpeg', 'jpg', 'png']" [maxFiles]="1" [maxSize]="5"
      [label]="'slideDetails.dragAndDropFilesHere'|translate" [files]="files" (selectedFiles)="files = $event"
      (deletedFilesList)="deletedAssignmentAttachments = $event"></app-file-manager>
      <button mat-button (click)="save()" class="w-100 mt-4" color="primary">{{"imageGuidelines.ValidateandSubmit" | translate}}</button>
  </div>

</mat-dialog-content>
