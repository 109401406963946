import { Observable, Subject, Subscription, timer } from 'rxjs';
import { Directive, Input, Output, EventEmitter, OnChanges, OnDestroy } from '@angular/core';
import { map, switchMap, take, tap } from 'rxjs/operators';

@Directive({
  selector: '[appTimeRemaining]'
})
export class TimeRemainingDirective implements OnChanges, OnDestroy {
  @Input() futureDate:any;
  @Input() type:string;
  @Input() enableExpired: boolean = true;
  @Input() interval = 1000;
  @Input() isLiveSession = false;
  @Output() value = new EventEmitter<string>();
  @Output() done = new EventEmitter<boolean>();
  private counterSource = new Subject<any>();
  private subscription = Subscription.EMPTY;

  constructor(){
    this.subscription = this.counterSource.pipe(
      switchMap(({ interval, count }) =>
        timer(0, interval).pipe(
          take(count),
          tap(() => {
            this.value.emit(this.msToTime(this.enableExpired, this.type === 'seconds' ? this.futureDate = --count : this.futureDate, this.type));
            if (count <= 0) {
              this.done.emit(true);
            }
          })
        )
      )
    ).subscribe();
  }

  ngOnChanges() {
    this.counterSource.next({ count: this.futureDate, interval: this.interval });
  }

  public startTimer() {
    this.counterSource.next({ count: this.futureDate, interval: this.interval });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  private msToTime(enableExpired, futureDate, type): string | null {
    let expired = false;
    let msRemaining = 0;
    if (type && type === 'seconds') {
      msRemaining = Math.abs(futureDate) * 1000;
      if (futureDate < 0) {
        expired = true;
        this.done.emit(true);
      }
    } else {
      const now = new Date().getTime();
      const endDate = new Date(futureDate).getTime();
      msRemaining = endDate - now;
      if (msRemaining < 0) {
        msRemaining = now - endDate;
        expired = true;
        this.done.emit(true);
      }
    }
    const days = Math.floor(msRemaining / (1000 * 60 * 60 * 24));
    const hours = Math.floor((msRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((msRemaining % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((msRemaining % (1000 * 60)) / (1000));
    if (!enableExpired && expired) {
      return ``;
    }
    if (!this.isLiveSession) {
      return ` <p class="${expired ? 'text-danger' : ''}">
      ${expired ? '-' : ''}
      ${days !== 0 ? days + ' d, ' : ''}
      ${hours !== 0 ? hours + ' h, ' : ''}
      ${minutes !== 0 ? minutes + ' m, ' : ''}
      ${seconds !== 0 ? seconds + ' sec' : ''}
      </p>`;
    } else {
      return ` <ul class="liveSession-remainingTime">
        <li>${(days !== 0 ? days : '0') + `<span>Days</span>`}</li>
        <li>${(hours !== 0 ? hours : '0') + `<span>Hours</span>`}</li>
        <li>${(minutes !== 0 ? minutes : '0') + `<span>Minutes</span>`}</li>
        <li>${(seconds !== 0 ? seconds : '0') + `<span>Seconds</span>`}</li>
      </ul>`;
    }
  }

}
