import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TraineeCertificateIssuanceService {
  refreshDetails = new BehaviorSubject<any>(false);

  constructor(private http: HttpClient) { }

  getAll(params): any {
    return this.http.get(`${environment.apiUrl}/TraineeCertificateIssuance/ListCertificatesByAdmin`, { params });
  }

  getListTrainees(params: any): any {
    return this.http.get(`${environment.apiUrl}/TraineeCertificateIssuance/ListTrainees`, { params });
  }

  getListCertificateTemplates(params: any): any {
    return this.http.get(`${environment.apiUrl}/TraineeCertificateIssuance/ListCertificateTemplates`, { params });
  }

  getListCertificateTypes(): any {
    return this.http.get(`${environment.apiUrl}/TraineeCertificateIssuance/ListCertificateTypes`);
  }

  getListCourses(params: any): any {
    return this.http.get(`${environment.apiUrl}/TraineeCertificateIssuance/ListCourses`, { params });
  }

  previewCertificate(data: any): any {
    return this.http.post(`${environment.apiUrl}/TraineeCertificateIssuance/PreviewCertificate`, data, { responseType: 'blob' });
  }

  issueCertificate(data: any): any {
    return this.http.post(`${environment.apiUrl}/TraineeCertificateIssuance/IssueCertificate`, data);
  }

  resendCertificate(data: any): any {
    return this.http.post(`${environment.apiUrl}/TraineeCertificateIssuance/ResendCertificate`, data);
  }

  getListRevocationReasons(): any {
    return this.http.get(`${environment.apiUrl}/TraineeCertificateIssuance/ListRevocationReasons`);
  }

  revokeCertificate(data: any): any {
    return this.http.post(`${environment.apiUrl}/TraineeCertificateIssuance/RevokeCertificate`, data);
  }

  reissueCertificate(data: any): any {
    return this.http.post(`${environment.apiUrl}/TraineeCertificateIssuance/ReissueCertificate`, data);
  }

  getCertificateById(id: string): any {
    return this.http.get(`${environment.apiUrl}/TraineeCertificateIssuance/GetCertificateById?id=${id}`);
  }

  getListCertificatesByTrainee(params: any): any {
    return this.http.get(`${environment.apiUrl}/TraineeCertificateIssuance/ListCertificatesByTrainee`, { params });
  }
}
