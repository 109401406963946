<mat-dialog-content class="qrcode-content">
  <div class="mat-dialog-body">
    <div class="mat-dialog-inner" id="qrCodeEleme">
      <qrcode [elementType]='"img"' [margin]="2" [qrdata]="QrCode" [width]="230" [errorCorrectionLevel]="'M'"
        class="bdcase-qrocde text-center"></qrcode>
      <p class="text-center label-form">
        {{"caseActions.referenceNumber"|translate}}: <strong>{{data.record.generatedSlideReference}}</strong>
      </p>
    </div>
    <button class="w-100" mat-raised-button color="primary" [disabled]="loading" [class.loading]='loading'
      (click)="print('qrCodeEleme')">
      {{"caseActions.printQrCode"|translate}}
    </button>
  </div>
</mat-dialog-content>
