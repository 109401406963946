<form [formGroup]="formGroup">

  <mat-form-field>
    <mat-label>{{"general.country"|translate}}</mat-label>
    <mat-select disableOptionCentering formControlName='country' (selectionChange)='getCoverageAreas()'>
      <mat-option [value]="item" *ngFor="let item of countryList">
        <span>{{item.name}}</span>
      </mat-option>
    </mat-select>
    <mat-error *ngIf="hasError('country', 'required')">{{ 'forms.Required' | translate }}</mat-error>
    <p class="invalid-feedback mt-2 d-block" *ngIf="checkUniqCountry()">
      {{"userForm.thisCountryAlreadyUsed"|translate}} {{checkUniqCountry()}}
    </p>
  </mat-form-field>

  <app-tree-field [required]='true' [isSubmitted]='isSubmitted' [recordsTree]='citiesTree'
    [recordsLoading]="coAreaLoading" [isDisabled]="!this.formGroup.controls.country.value?.id || coAreaLoading"
    [detailsSelectedRecords]='detailsSelectedRecords' (selected)="selectedCities = $event"></app-tree-field>

  <div class="set-actions">
    <button mat-icon-button (click)='deleteRecord()'>
      <mat-icon>delete_outline</mat-icon>
    </button>
    <button mat-raised-button color="primary" (click)='submitRecord()'>
      <mat-icon>check</mat-icon>
    </button>
  </div>

</form>
