<form [formGroup]="formGroup">
  <div class="details-page">
    <div class="row justify-content-center">
      <div class="col-xl-8 col-lg-8 col-md-12">

        <div class="card_field_group mt-4">
          <div class="card_head">
            <h4>
              {{ 'general.notificationPreferences' | translate }}
            </h4>
          </div>
          <div class="card_content">
            <div class="fields-group">
              <mat-slide-toggle class="customToggleButton" formControlName="TaskReminder" [labelPosition]="'before'">
                <h3 class="subtitle-form mb-2"> {{"general.taskReminders"|translate}}</h3>
                <p class="title_hint">
                  {{"general.alertsForUpcomingTasksAssignmentsQuizzesAndProjectDeadlines"|translate}}</p>
              </mat-slide-toggle>
            </div>
            <div class="fields-group">
              <mat-slide-toggle class="customToggleButton" formControlName="EventNotifications"
                [labelPosition]="'before'">
                <h3 class="subtitle-form mb-2"> {{"general.eventNotifications"|translate}}</h3>
                <p class="title_hint">
                  {{"general.alertsForScheduledEventsSuchAsLiveSessionsWebinarsAndExams"|translate}}</p>
              </mat-slide-toggle>
            </div>
            <div class="fields-group">
              <mat-slide-toggle class="customToggleButton" formControlName="CourseUpdates" [labelPosition]="'before'">
                <h3 class="subtitle-form mb-2"> {{"general.courseUpdates"|translate}}</h3>
                <p class="title_hint">
                  {{"general.notificationsAboutNewCourseMaterialsUpdatesFromInstructorsAndChangesInTheSchedule"|translate}}
                </p>
              </mat-slide-toggle>
            </div>
            <div class="fields-group">
              <mat-slide-toggle class="customToggleButton" formControlName="FeedbackAndGrades"
                [labelPosition]="'before'">
                <h3 class="subtitle-form mb-2"> {{"general.feedbackAndGrades"|translate}}</h3>
                <p class="title_hint">
                  {{"general.alertsWhenFeedbackOrGradesForSubmittedAssignmentsAreAvailable"|translate}}</p>
              </mat-slide-toggle>
            </div>
            <div class="fields-group">
              <mat-slide-toggle class="customToggleButton" formControlName="ImportantAnnouncements"
                [labelPosition]="'before'">
                <h3 class="subtitle-form mb-2"> {{"general.importantAnnouncements"|translate}}</h3>
                <p class="title_hint">
                  {{"general.notificationsForImportantAnnouncementsFromTheTrainingAgencyOrInstructors"|translate}}</p>
              </mat-slide-toggle>
            </div>
          </div>
          <div class="card_content">
            <div class="head_group">
              {{"general.alertDeliveryMethods"|translate}}
            </div>
            <div class="fields-group">
              <mat-slide-toggle class="customToggleButton" formControlName="AllowNotificationsDelivery"
                [labelPosition]="'before'">
                <h3 class="subtitle-form mb-2"> {{"general.inSystemNotifications"|translate}}</h3>
                <p class="title_hint">
                  {{"general.alertsDisplayedWithinThePlatformAccessibleFromANotificationBellIconOrADedicatedNotificationsSection"|translate}}
                </p>
              </mat-slide-toggle>
            </div>
            <div class="fields-group">
              <mat-slide-toggle class="customToggleButton" formControlName="AllowEmailDelivery"
                [labelPosition]="'before'">
                <h3 class="subtitle-form mb-2"> {{"general.emailNotifications"|translate}}</h3>
                <p class="title_hint">
                  {{"general.optionToReceiveAlertsViaEmailWithASummaryOfTheTasksAndDeadlines"|translate}}</p>
              </mat-slide-toggle>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
  <div class="sticky-footer-action">
    <div class="btn-container">
      <button mat-raised-button color="primary" [disabled]="loading" [class.loading]='loading' (click)="saveRecord()">
        {{"forms.Submit"|translate}}
      </button>
    </div>
  </div>
</form>