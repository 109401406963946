import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SupportTicketService {

  constructor(private http: HttpClient) {
  }

  submitTicket(data: any, isAuth): any {
    if (isAuth) {
      return this.http.post(`${environment.apiUrl}/SupportTicket/SubmitTicket`, data);
    } else {
      return this.http.post(`${environment.apiUrl}/SupportTicket/SubmitTicketAsAnonymous`, data);
    }
  }

  getRoles(): any {
    return this.http.get(`${environment.apiUrl}/SupportTicket/GetRoles`);
  }

  getSupportTicketCategory(): any {
    return this.http.get(`${environment.apiUrl}/SupportTicket/GetSupportTicketCategory`);
  }

  getSupportTicketPriorities(): any {
    return this.http.get(`${environment.apiUrl}/SupportTicket/GetSupportTicketPriorities`);
  }

  getListSupportTickets(params: any): any {
    return this.http.get(`${environment.apiUrl}/SupportTicket/ListSupportTickets`, { params });
  }

  setTicketPriority(data: any) {
    return this.http.post(`${environment.apiUrl}/SupportTicket/SetTicketPriority`, data);
  }

  changeTicketStatus(data: any) {
    return this.http.post(`${environment.apiUrl}/SupportTicket/ChangeTicketStatus`, data);
  }

  getTicketDetails(id) {
    return this.http.get(`${environment.apiUrl}/SupportTicket/GetTicketDetails?Id=${id}`);
  }

  addNote(data: any) {
    return this.http.post(`${environment.apiUrl}/SupportTicket/AddNote`, data);
  }

  getPersonalData(): any {
    return this.http.get(`${environment.apiUrl}/SupportTicket/GetPersonalData`);
  }
}
