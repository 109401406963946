<header>
  <nav class="navbar navbar-expand-lg fixed-top page-nave colored-pages" [ngClass]="isBottom ? 'page-nav-scroll' : ''">
    <a class="navbar-brand" [routerLink]="['/public-courses']">
      <!-- <img src="./images/logo.svg" class="logo" alt="Histo" /> -->
      <img src="./assets/images/logo-for-pages.svg" class="logo-for-scroll" alt="Histo" />
    </a>

    <div class="collapse navbar-collapse" id="navbarNav" [class.show]="showNavbar">
      <ul class="navbar-nav">
        <li class="nav-item">
          <a class="nav-link" aria-current="page" [routerLink]="['/']">{{"home.products"|translate}}</a>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" id="whoWeServeDropdown">{{"home.ourServices"|translate}}</a>
          <ul class="dropdown-menu border-0 shadow bsb-zoomIn" aria-labelledby="whoWeServeDropdown">
            <li><a class="dropdown-item" [routerLink]="['/']">{{"home.hospitalsAndClinics"|translate}}</a>
            </li>
            <li><a class="dropdown-item" [routerLink]="['/']">{{"home.laboratories"|translate}}</a></li>
            <li><a class="dropdown-item" [routerLink]="['/']">{{"home.scanningCenters"|translate}}</a></li>
            <li><a class="dropdown-item" [routerLink]="['/']">{{"home.pathologists"|translate}}</a></li>
            <li><a class="dropdown-item" [routerLink]="['/']">{{"home.nationalHealthcare"|translate}}</a>
            </li>
            <li><a class="dropdown-item" [routerLink]="['/']">{{"home.patients"|translate}}</a></li>
          </ul>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" id="whoWeServeDropdown">{{"home.whoWeServe"|translate}}</a>
          <ul class="dropdown-menu border-0 shadow bsb-zoomIn" aria-labelledby="whoWeServeDropdown">
            <li><a class="dropdown-item" [routerLink]="['/']">{{"home.hospitalsAndClinics"|translate}}</a>
            </li>
            <li><a class="dropdown-item" [routerLink]="['/']">{{"home.laboratories"|translate}}</a></li>
            <li><a class="dropdown-item" [routerLink]="['/']">{{"home.scanningCenters"|translate}}</a></li>
            <li><a class="dropdown-item" [routerLink]="['/']">{{"home.pathologists"|translate}}</a></li>
            <li><a class="dropdown-item" [routerLink]="['/']">{{"home.nationalHealthcare"|translate}}</a>
            </li>
            <li><a class="dropdown-item" [routerLink]="['/']">{{"home.patients"|translate}}</a></li>
          </ul>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" id="CompanyDropdown">{{"home.company"|translate}}</a>
          <ul class="dropdown-menu border-0 shadow bsb-zoomIn" aria-labelledby="CompanyDropdown">
            <li><a class="dropdown-item" [routerLink]="['/']">{{"home.about"|translate}}</a>
            </li>
            <li><a class="dropdown-item" [routerLink]="['/']">{{"home.career"|translate}}</a></li>
            <li><a class="dropdown-item" [routerLink]="['/']">{{"home.medicalAdvisoryBoard"|translate}}</a></li>
            <li><a class="dropdown-item" [routerLink]="['/']">{{"home.blogAndNews"|translate}}</a></li>
          </ul>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/']">{{"home.training"|translate}}</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/contact-us']"
            routerLinkActive="active">{{"home.contactUs"|translate}}</a>
        </li>
        <li class="nav-item">
          <a [routerLink]="['/']" class="nav-link demo">{{"home.bookADemo"|translate}}</a>
        </li>
      </ul>
    </div>

    <div class="buttons d-flex align-items-center">
      <a class="btn mx-2 change-language" (click)="changeLanguage()">
        <!-- <img src="./assets/images/icons/language-icon.svg" alt=""> -->
        {{ 'general.language' | translate }}
      </a>
      <a class="btn text-uppercase me-1" [routerLink]="['/login']" *ngIf="!currentUser">
        {{"home.logIn"|translate}}
      </a>
    </div>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
      aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation" (click)="showNavbar = !showNavbar">
      <i></i><i></i><i></i>
    </button>
  </nav>
</header>