<div mat-dialog-title>
  <h2>
    {{"profile.changePassword"|translate}}
  </h2>
  <button type="button" class="close" mat-dialog-close aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<mat-dialog-content>
  <div class="mat-dialog-body">
    <form [formGroup]="formGroup" (ngSubmit)='submit()'>
      <mat-form-field>
        <mat-label>{{ 'auth.Old Password' | translate }}</mat-label>
        <input matInput formControlName="OldPassword" autocomplete="off" [type]="hideOldPassword ? 'password' : 'text'"
          maxlength="50" required>
        <button type="button" mat-icon-button matSuffix (click)="hideOldPassword = !hideOldPassword"
          [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hideOldPassword">
          <mat-icon>{{hideOldPassword ? 'visibility_off' : 'visibility'}}</mat-icon>
        </button>
        <mat-error *ngIf="hasError('OldPassword', 'required')">{{ 'forms.Required' | translate }}</mat-error>
        <mat-error *ngIf="hasError('OldPassword', 'validOldPass')">
          {{"profile.oldPasswordInvalid"|translate}}
        </mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label>{{ 'forms.Password' | translate }}</mat-label>
        <input matInput formControlName="password" autocomplete="off" [type]="hidePassword ? 'password' : 'text'"
          maxlength="50" required>
        <button type="button" mat-icon-button matSuffix (click)="hidePassword = !hidePassword"
          [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hidePassword">
          <mat-icon>{{hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
        </button>
        <mat-error *ngIf="hasError('password', 'required')">{{ 'forms.Required' | translate }}</mat-error>
        <mat-error *ngIf="hasError('password', 'pattern')">{{ 'forms.password-validation' | translate }}</mat-error>
      </mat-form-field>

      <mat-form-field class="form-group">
        <mat-label>{{ 'forms.cPassword' | translate }}</mat-label>
        <input matInput formControlName="cPassword" autocomplete="off" [type]="hideConfPassword ? 'password' : 'text'"
          maxlength="50" required>
        <button type="button" mat-icon-button matSuffix (click)="hideConfPassword = !hideConfPassword"
          [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hideConfPassword">
          <mat-icon>{{hideConfPassword ? 'visibility_off' : 'visibility'}}</mat-icon>
        </button>
        <mat-error *ngIf="hasError('cPassword', 'required')">{{ 'forms.Required' | translate }}</mat-error>
        <mat-error *ngIf="hasError('cPassword', 'notMatch')">{{ 'auth.Password-match' | translate }}</mat-error>
      </mat-form-field>

      <div class="btn-container">
        <button mat-raised-button color="primary" class="w-100" [disabled]='loading' [class.loading]='loading'>
          <span>{{"profile.changePassword"|translate}}</span>
        </button>
        <button mat-raised-button mat-dialog-close color="grey" class="w-100 mt-4">
          <span>{{"swalMsg.cancel"|translate}}</span>
        </button>
      </div>
    </form>
  </div>
</mat-dialog-content>
