<div mat-dialog-title class="no-bg justify-content-end">
  <button type="button" class="close" mat-dialog-close aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<mat-dialog-content>
  <div class="mat-dialog-body image-guidelines">

    <div class="row">
      <div class="col-12 text-center">
        <svg xmlns="http://www.w3.org/2000/svg" width="178.001" height="125" viewBox="0 0 178.001 125">
          <g id="illustration_add_id" transform="translate(-1024.001 -1100.001)">
            <g id="illustration_id" transform="translate(1024 1100)">
              <path id="Subtraction_57" data-name="Subtraction 57"
                d="M13776.241-13703.337h-88.812a18.051,18.051,0,0,1-13.03-5.551,19.1,19.1,0,0,1-5.4-13.4v-63.762a19.1,19.1,0,0,1,5.4-13.4,18.051,18.051,0,0,1,13.03-5.551h108.758a18.071,18.071,0,0,1,13.038,5.551,19.111,19.111,0,0,1,5.4,13.4v34.692c-.891-.062-1.792-.095-2.68-.095a37.338,37.338,0,0,0-37.294,37.3,37.324,37.324,0,0,0,1.593,10.815Z"
                transform="translate(-13664.311 13809.044)" fill="#fddaec" />
              <path id="Subtraction_58" data-name="Subtraction 58"
                d="M13782.524-13695H13692.1a22.657,22.657,0,0,1-9-1.847,22.968,22.968,0,0,1-7.34-5.03,23.4,23.4,0,0,1-4.95-7.458,23.721,23.721,0,0,1-1.815-9.141v-63.047a23.714,23.714,0,0,1,1.815-9.141,23.448,23.448,0,0,1,4.95-7.461,23.005,23.005,0,0,1,7.34-5.028,22.638,22.638,0,0,1,9-1.846h108.8a22.631,22.631,0,0,1,9,1.846,23.044,23.044,0,0,1,7.34,5.028,23.374,23.374,0,0,1,4.947,7.461,23.644,23.644,0,0,1,1.818,9.141v34.847a37.5,37.5,0,0,0-4.662-.631v-34.216a18.61,18.61,0,0,0-18.438-18.736h-108.8a18.61,18.61,0,0,0-18.438,18.736v63.047a18.61,18.61,0,0,0,18.438,18.736h88.7a37.073,37.073,0,0,0,1.727,4.738Z"
                transform="translate(-13669 13805)" fill="#c92d7d" />
              <path id="Subtraction_59" data-name="Subtraction 59"
                d="M13689.281-13740.27a17.933,17.933,0,0,1-14.476-6.721,28.044,28.044,0,0,1-4.8-9.153l-.014-.038a4.725,4.725,0,0,0-.258-.7,11.326,11.326,0,0,1-.632-5.563,11.461,11.461,0,0,1,1.927-4.941,10.692,10.692,0,0,1,8.878-4.7h18.74a10.7,10.7,0,0,1,8.878,4.7,11.464,11.464,0,0,1,1.926,4.944,11.3,11.3,0,0,1-.631,5.562,3.678,3.678,0,0,0-.166.454l-.017.048a29.081,29.081,0,0,1-4.781,9.1A17.98,17.98,0,0,1,13689.281-13740.27Zm-9.372-27a6.1,6.1,0,0,0-5.065,2.678,6.5,6.5,0,0,0-.73,5.989,5.527,5.527,0,0,1,.32.859l0,.009c1.034,3.131,4.182,12.658,14.845,12.658,10.548,0,13.732-9.424,14.779-12.521l0-.007a6.331,6.331,0,0,1,.383-1,6.5,6.5,0,0,0-.73-5.988,6.092,6.092,0,0,0-5.065-2.679Zm82.5,13.311h-37.464a2.379,2.379,0,0,1-2.346-2.405,2.379,2.379,0,0,1,2.346-2.405h41.417a37.581,37.581,0,0,0-3.953,4.811Zm12.2-16.265h-49.663a2.379,2.379,0,0,1-2.346-2.405,2.379,2.379,0,0,1,2.346-2.405h49.663a2.377,2.377,0,0,1,2.343,2.405A2.377,2.377,0,0,1,13774.6-13770.228Zm-85.334-5.433a14.494,14.494,0,0,1-14.286-14.661,14.5,14.5,0,0,1,14.286-14.679,14.512,14.512,0,0,1,14.3,14.679A14.5,14.5,0,0,1,13689.271-13775.66Zm0-24.529a9.746,9.746,0,0,0-9.6,9.868,9.737,9.737,0,0,0,9.6,9.851,9.745,9.745,0,0,0,9.614-9.851A9.754,9.754,0,0,0,13689.271-13800.189Zm47.254,13.7h-11.583a2.381,2.381,0,0,1-2.346-2.409,2.379,2.379,0,0,1,2.346-2.405h11.583a2.377,2.377,0,0,1,2.343,2.405A2.379,2.379,0,0,1,13736.524-13786.492Z"
                transform="translate(-13645.475 13827.674)" fill="#c92d7d" />
            </g>
            <g id="Group_38867" data-name="Group 38867" transform="translate(13452.099 -13609.9)">
              <circle id="Ellipse_371" data-name="Ellipse 371" cx="29.295" cy="29.295" r="29.295"
                transform="matrix(0, -1, 1, 0, -12310.425, 14833.185)" fill="#fefdfe" />
              <path id="Path_93955" data-name="Path 93955"
                d="M369.449,338.337a4.126,4.126,0,0,1-1.215,2.917,4.073,4.073,0,0,1-2.917,1.215H356.97v8.346a4.138,4.138,0,0,1-4.133,4.133,4.073,4.073,0,0,1-2.917-1.215,4.125,4.125,0,0,1-1.215-2.917V342.47h-8.346a4.133,4.133,0,0,1,0-8.265H348.7v-8.346a4.138,4.138,0,0,1,4.133-4.133,4.073,4.073,0,0,1,2.917,1.215,4.125,4.125,0,0,1,1.215,2.917v8.346h8.346A4.132,4.132,0,0,1,369.449,338.337Z"
                transform="translate(-12633.936 14465.563)" fill="#fddaec" />
              <path id="Path_93956" data-name="Path 93956"
                d="M328.9,283.4a31,31,0,1,0,31,31A31.036,31.036,0,0,0,328.9,283.4Zm0,58.59A27.589,27.589,0,1,1,356.491,314.4,27.62,27.62,0,0,1,328.9,341.991Z"
                transform="translate(-12610 14489.499)" fill="#c92d7d" />
              <path id="Path_93957" data-name="Path 93957"
                d="M361.167,328.611h-6.9v-6.9c0-7.385-11.159-7.375-11.159,0v6.9h-6.9c-7.384,0-7.379,11.159,0,11.159h6.9v6.9c0,7.388,11.159,7.376,11.159,0v-6.9h6.9C368.544,339.771,368.546,328.611,361.167,328.611Zm0,8.265h-8.346a1.447,1.447,0,0,0-1.447,1.447v8.346c0,3.554-5.371,3.55-5.371,0v-8.346a1.447,1.447,0,0,0-1.447-1.447H336.21c-3.553,0-3.551-5.371,0-5.371h8.346A1.447,1.447,0,0,0,346,330.058v-8.346c0-3.553,5.371-3.55,5.371,0v8.346a1.447,1.447,0,0,0,1.447,1.447h8.346C364.726,331.505,364.706,336.877,361.167,336.877Z"
                transform="translate(-12629.786 14469.71)" fill="#c92d7d" />
            </g>
          </g>
        </svg>
        <h2>{{"imageGuidelines.uploadImageGuidelines" | translate}}</h2>
        <p>{{"imageGuidelines.uploadImageGuidelinesDescription" | translate}}</p>
      </div>
      <div class="col-12">
        <div class="guidelines-card">
          <ul>
            <li>
              <svg id="bullet" xmlns="http://www.w3.org/2000/svg" width="14.996" height="14.996"
                viewBox="0 0 14.996 14.996">
                <path id="circle" d="M955.654,543.6a7.5,7.5,0,1,1,7.5-7.5A7.507,7.507,0,0,1,955.654,543.6Z"
                  transform="translate(-948.155 -528.599)" fill="#71808e" />
                <path id="correct"
                  d="M2.987,5.453A.565.565,0,0,1,2.6,5.3L0,2.835l.78-.741L2.987,4.189,7.4,0l.78.741L3.377,5.3A.565.565,0,0,1,2.987,5.453Z"
                  transform="translate(3.408 4.772)" fill="#f7f7f7" />
              </svg>
              <div>
                <h6>{{"imageGuidelines.ContainsHumanFace" | translate}}</h6>
                <p [innerHTML]="'imageGuidelines.ContainsHumanFaceDescription' | translate"></p>
              </div>
            </li>
            <li>
              <svg id="bullet" xmlns="http://www.w3.org/2000/svg" width="14.996" height="14.996"
                viewBox="0 0 14.996 14.996">
                <path id="circle" d="M955.654,543.6a7.5,7.5,0,1,1,7.5-7.5A7.507,7.507,0,0,1,955.654,543.6Z"
                  transform="translate(-948.155 -528.599)" fill="#71808e" />
                <path id="correct"
                  d="M2.987,5.453A.565.565,0,0,1,2.6,5.3L0,2.835l.78-.741L2.987,4.189,7.4,0l.78.741L3.377,5.3A.565.565,0,0,1,2.987,5.453Z"
                  transform="translate(3.408 4.772)" fill="#f7f7f7" />
              </svg>
              <div>
                <h6>{{"imageGuidelines.clearImage" | translate}}</h6>
                <p [innerHTML]="'imageGuidelines.clearImageDescription' | translate"></p>
              </div>
            </li>
            <li>
              <svg id="bullet" xmlns="http://www.w3.org/2000/svg" width="14.996" height="14.996"
                viewBox="0 0 14.996 14.996">
                <path id="circle" d="M955.654,543.6a7.5,7.5,0,1,1,7.5-7.5A7.507,7.507,0,0,1,955.654,543.6Z"
                  transform="translate(-948.155 -528.599)" fill="#71808e" />
                <path id="correct"
                  d="M2.987,5.453A.565.565,0,0,1,2.6,5.3L0,2.835l.78-.741L2.987,4.189,7.4,0l.78.741L3.377,5.3A.565.565,0,0,1,2.987,5.453Z"
                  transform="translate(3.408 4.772)" fill="#f7f7f7" />
              </svg>
              <div>
                <h6>{{"imageGuidelines.properOrientation" | translate}}</h6>
                <p [innerHTML]="'imageGuidelines.properOrientationDescription' | translate"></p>
              </div>
            </li>
            <li>
              <svg id="bullet" xmlns="http://www.w3.org/2000/svg" width="14.996" height="14.996"
                viewBox="0 0 14.996 14.996">
                <path id="circle" d="M955.654,543.6a7.5,7.5,0,1,1,7.5-7.5A7.507,7.507,0,0,1,955.654,543.6Z"
                  transform="translate(-948.155 -528.599)" fill="#71808e" />
                <path id="correct"
                  d="M2.987,5.453A.565.565,0,0,1,2.6,5.3L0,2.835l.78-.741L2.987,4.189,7.4,0l.78.741L3.377,5.3A.565.565,0,0,1,2.987,5.453Z"
                  transform="translate(3.408 4.772)" fill="#f7f7f7" />
              </svg>
              <div>
                <h6>{{"imageGuidelines.clearAndReadablePhoto" | translate}}</h6>
                <p [innerHTML]="'imageGuidelines.clearAndReadablePhotoDescription' | translate"></p>
              </div>
            </li>
            <li>
              <svg id="bullet" xmlns="http://www.w3.org/2000/svg" width="14.996" height="14.996"
                viewBox="0 0 14.996 14.996">
                <path id="circle" d="M955.654,543.6a7.5,7.5,0,1,1,7.5-7.5A7.507,7.507,0,0,1,955.654,543.6Z"
                  transform="translate(-948.155 -528.599)" fill="#71808e" />
                <path id="correct"
                  d="M2.987,5.453A.565.565,0,0,1,2.6,5.3L0,2.835l.78-.741L2.987,4.189,7.4,0l.78.741L3.377,5.3A.565.565,0,0,1,2.987,5.453Z"
                  transform="translate(3.408 4.772)" fill="#f7f7f7" />
              </svg>
              <div>
                <h6>{{"imageGuidelines.ValidExpirationDate" | translate}}</h6>
              </div>
            </li>
            <li>
              <svg id="bullet" xmlns="http://www.w3.org/2000/svg" width="14.996" height="14.996"
                viewBox="0 0 14.996 14.996">
                <path id="circle" d="M955.654,543.6a7.5,7.5,0,1,1,7.5-7.5A7.507,7.507,0,0,1,955.654,543.6Z"
                  transform="translate(-948.155 -528.599)" fill="#71808e" />
                <path id="correct"
                  d="M2.987,5.453A.565.565,0,0,1,2.6,5.3L0,2.835l.78-.741L2.987,4.189,7.4,0l.78.741L3.377,5.3A.565.565,0,0,1,2.987,5.453Z"
                  transform="translate(3.408 4.772)" fill="#f7f7f7" />
              </svg>
              <div>
                <p [innerHTML]="'imageGuidelines.matchingId' | translate"></p>
              </div>
            </li>
            <li>
              <svg id="bullet" xmlns="http://www.w3.org/2000/svg" width="14.996" height="14.996"
                viewBox="0 0 14.996 14.996">
                <path id="circle" d="M955.654,543.6a7.5,7.5,0,1,1,7.5-7.5A7.507,7.507,0,0,1,955.654,543.6Z"
                  transform="translate(-948.155 -528.599)" fill="#71808e" />
                <path id="correct"
                  d="M2.987,5.453A.565.565,0,0,1,2.6,5.3L0,2.835l.78-.741L2.987,4.189,7.4,0l.78.741L3.377,5.3A.565.565,0,0,1,2.987,5.453Z"
                  transform="translate(3.408 4.772)" fill="#f7f7f7" />
              </svg>
              <div>
                <p [innerHTML]="'imageGuidelines.matchingName' | translate"></p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="d-flex align-items-center justify-content-center">
      <button mat-raised-button mat-dialog-close color="grey" class="mr-2 w-50" type="button">
        {{"profile.cancel" | translate}}
      </button>
      <button class="w-50" mat-raised-button color="primary" (click)="dialogRef.close(true)">
        {{"imageGuidelines.ContinueToUpload" | translate}}
      </button>
    </div>
  </div>
</mat-dialog-content>
