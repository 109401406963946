import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { OnlinePaymentEnum } from 'src/app/models/payments/enums/OnlinePaymentType.enum';
import { HPayementService } from 'src/app/services/h-payement.service';
import { environment } from 'src/environments/environment';
export interface DialogData {
  courseId: number;
  paymentProvider: OnlinePaymentEnum;
}

@Component({
  selector: 'app-efinance-form',
  templateUrl: './efinance-form.component.html'
})
export class EfinanceFormComponent implements OnInit {
  dataLoading = false;
  paymentIframeLoader = false;
  paymentMethod = null;
  paymentProvider = null;
  referencePaymentUrl = null;
  onlinePaymentEnum = OnlinePaymentEnum;
  paymentMethods = [
    { name: 'payment.visaMastercard', value: OnlinePaymentEnum.Visa_MasterCard, imgPath: 'assets/images/icons/payment-icon-card.svg' },
    { name: 'payment.mada', value: OnlinePaymentEnum.Mada, imgPath: 'assets/images/mada_logo.png' },
    { name: 'payment.applePay', value: OnlinePaymentEnum.ApplePay, imgPath: 'assets/images/icons/apple-pay-logo-svgrepo-com.svg' },
    { name: 'payment.stcPay', value: OnlinePaymentEnum.STCPay, imgPath: 'assets/images/icons/stcPay.svg' },
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<EfinanceFormComponent>,
    private service: HPayementService,
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    public translate: TranslateService,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.paymentProvider = this.data.paymentProvider;
    this.paymentMethod = this.data.paymentProvider;
    if (this.paymentProvider) {
      this.getCopyAndPayId();
    }
  }


  getCopyAndPayId() {
    this.dataLoading = true;
    const data = {
      checkOutRedirectUrl: (window.location ? window.location.origin : environment.appUrl) + "/public-courses/" + this.data.courseId,
      courseId: this.data.courseId,
      paymentType: this.paymentProvider
    }
    this.service.getCopyAndPayId(data).subscribe(
      (res) => {
        this.referencePaymentUrl = `./assets/paymentWidget.html?brand=${this.paymentProvider}&scriptUrl=${res.webUrl}`;
        // this.loadJsScript('https://eu-test.oppwa.com/v1/paymentWidgets.js?checkoutId=8CA1029D46EB9F6070C3D50B0F3EA839.uat01-vm-tx03');
        this.dataLoading = false;
      },
      (err) => {
        this.dataLoading = false;
        this.openSnackBar(err.message);
      }
    );

  }

  public loadJsScript(src: string): HTMLScriptElement {
    const oldScript = document.getElementById('e-payment-script');

    const script = this.renderer.createElement('script');
    script.id = 'e-payment-script';
    script.src = src;
    if (oldScript) {
      this.renderer.removeChild(this.document.body, oldScript);
    }
    // this.renderer.destroyNode(oldForm);
    this.renderer.appendChild(this.document.body, script);
    return script;
  }

  changeMethod(id) {
    console.log(id, this.paymentProvider)
    if (id != this.paymentProvider) {
      this.paymentProvider = id;
      const oldScript = document.getElementById('e-payment-script');
      if (oldScript) {
        this.renderer.removeChild(this.document.body, oldScript);
      }
      const oldForm = document.getElementById('eFinanceForm');

      if (oldForm) {
        oldForm.remove()
      }
      this.getCopyAndPayId();
      // this.dialogRef.close(id);
    }
  }

  openSnackBar(message) {
    this.snackBar.open(message, '✖', {
      duration: 1000,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
    });
  }

  iOS() {
    return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform)
      // iPad on iOS 13 detection
      || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  }

  afterLoadIframe() {
    this.paymentIframeLoader = false;
    const iframe = document.querySelector("iframe");
    console.log('dssads')
    iframe.style.height = iframe.contentWindow.document.body.scrollHeight + "px";

  }

  closePayment() {
    // this.dialogRef.close(false);
    window.location.reload();
  }
}
