import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  constructor(private http: HttpClient) { }

  GetNotifications(params) {
    return this.http.get(`${environment.apiUrl}/Notification/list`, { params });
  }
  SeeNotifications(params) {
    return this.http.get(`${environment.apiUrl}/notification/SeeNotifications`, { params });
  }

  getNotificationSettings() {
    return this.http.get(`${environment.apiUrl}/Notification/GetNotificationSettings`);
  }

  updateNotificationSettings(data: any) {
    return this.http.post(`${environment.apiUrl}/Notification/UpdateNotificationSettings`, data);
  }
}
