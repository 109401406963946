<div [formGroup]='formGroup'>
  <!-- <wsa-web-speech [formGroup]='formGroup' [fieldControlName]="'searchText'" [randomNumber]="1"></wsa-web-speech>
  <wsa-web-speech [formGroup]='formGroup' [fieldControlName]="'searchText2'" [randomNumber]="2"></wsa-web-speech> -->
  <!-- <wsa-web-speech></wsa-web-speech> -->


  <ng-container *ngTemplateOutlet="recursiveList,context:{field:field}">
  </ng-container>

  <ng-template #recursiveList let-field="field">

    <div class="field-header">
      <div class="field-title m-0" *ngIf="field.isConditionalLogic && (field.type === 'QUESTION' || field.inputTitle)">
        <read-more [text]="field.inputTitle" [maxLength]="39"> </read-more>
      </div>
      <a *ngIf="field.inputTypeId === 7 && !field.isMandatory" (click)="resetDate(field.id)" class="btn_header">
        Reset Answer
      </a>
    </div>
    <ng-container *ngIf="field.isConditionalLogic && (field.type === 'QUESTION' || field.inputTitle)">
      <div class="field-desc">
        <read-more [text]="field.description" [maxLength]="39"> </read-more>
      </div>
    </ng-container>
    <div class="form-group preview-textboxes" *ngIf="field.inputTypeId === 3">
      <div class="row align-items-stretch">
        <div class="col-sm-10">
          <mat-form-field>
            <!-- <mat-label>{{field.inputTitle}}</mat-label> -->
            <input matInput [formControlName]="field.id" appOnlynumber [canApplyDecimal]="true"
              [maxlength]="field.fieldLength">
            <mat-error *ngIf="hasError('required',field.id)">{{ 'forms.Required' | translate }}</mat-error>
            <mat-error *ngIf="hasError('pattern',field.id)">{{ 'forms.Invalid number' | translate }}</mat-error>
            <mat-error *ngIf="hasError('min',field.id)">
              Minimum is {{field.jsonProps.minimumRange}} Characters
            </mat-error>
            <mat-error *ngIf="hasError('max',field.id)">
              Maximum is {{field.jsonProps.maxRange}} Characters
            </mat-error>
            <mat-error *ngIf="hasError('maxLength',field.id)">
              Max Length is {{field.fieldLength}} Characters
            </mat-error>
            <mat-hint *ngIf="field.validationMessage">{{field.validationMessage}}</mat-hint>
          </mat-form-field>
        </div>
        <div class="col-sm-2 suffix_item" *ngIf="field.jsonProps.hasAUnit">
          <span class="unit-name">
            {{field.jsonProps.unitName}}
          </span>
        </div>
      </div>
    </div>


    <!-- <mat-form-field class="form-group" *ngIf="field.inputTypeId == 5">
      <input matInput [formControlName]="field.id" [maxlength]="field.fieldLength">
      <mat-error *ngIf="hasError('required',field.id)">{{ 'forms.Required' | translate }}</mat-error>
      <mat-error *ngIf="hasError('pattern',field.id)">{{ 'forms.Invalid number' | translate }}</mat-error>
      <mat-error *ngIf="hasError('maxLength',field.id)">
        Max Length is {{field.fieldLength}} Characters
      </mat-error>
      <mat-hint *ngIf="field.validationMessage">{{field.validationMessage}}</mat-hint>
    </mat-form-field> -->

    <app-deepgram-field [formGroup]='formGroup' [fieldControlName]="field.id" [inputType]="field.inputTypeId"
      [fieldLength]="field.fieldLength" [validationMessage]="field.validationMessage"
      *ngIf="field.inputTypeId == 6 || field.inputTypeId == 5"
      [allowVoiceRecognition]="allowVoiceRecognition"></app-deepgram-field>
    <!-- <mat-form-field class="form-group" *ngIf="field.inputTypeId == 6">
      <textarea matInput [formControlName]="field.id" [maxlength]="field.fieldLength"></textarea>
      <mat-error *ngIf="hasError('required',field.id)">{{ 'forms.Required' | translate }}</mat-error>
      <mat-error *ngIf="hasError('maxLength',field.id)">
        Max Length is {{field.fieldLength}} Characters
      </mat-error>
      <mat-hint *ngIf="field.validationMessage">{{field.validationMessage}}</mat-hint>
    </mat-form-field> -->

    <ng-container *ngIf="field.inputTypeId === 2">
      <mat-form-field class="form-group" [ngClass]="{'mb-2': formGroup.controls[field.id].value === 'other'}">
        <mat-label>Select Value {{formGroup.controls[field.id].value}}</mat-label>
        <mat-select [formControlName]="field.id" (selectionChange)="dropdownChanged(field.id)">
          <mat-option [value]="">--None--</mat-option>
          <mat-option [matTooltip]="item.title" [value]="item.id" *ngFor="let item of field.values">
            <span>{{item.title}}</span>
          </mat-option>
          <mat-option *ngIf="field.isOtherOption" value="other">{{ 'reportsBuilder.other' | translate }}</mat-option>
        </mat-select>
        <mat-error *ngIf="hasError('required',field.id)">{{ 'forms.Required' | translate }}</mat-error>
        <mat-hint *ngIf="field.validationMessage">{{field.validationMessage}}</mat-hint>
      </mat-form-field>
      <mat-form-field class="form-group" hideRequiredMarker *ngIf="field.isOtherOption"
        [hidden]="formGroup.controls[field.id].value !== 'other'">
        <mat-label>Type here</mat-label>
        <input matInput [formControlName]="field.id + '_other'"
          [required]="formGroup.controls[field.id].value === 'other'">
        <mat-error *ngIf="otherHasError(field.id,'required')">{{ 'forms.Required' | translate }}</mat-error>
      </mat-form-field>

      <div class="form-group" *ngIf="field.conditionalogic.length">
        <div class="sub_fields" [hidden]="formGroup.controls[field.id].value !== item.valueId"
          *ngFor="let item of field.conditionalogic; let i = index">
          <ng-container *ngTemplateOutlet="recursiveList,context:{field:item}">
          </ng-container>
        </div>
      </div>
    </ng-container>

    <mat-form-field class="form-group" *ngIf="field.inputTypeId === 1">
      <!-- <mat-label>{{field.inputTitle}}</mat-label> -->
      <input matInput [formControlName]="field.id" [matDatepicker]="DatePicker" readonly>
      <div matSuffix class="d-flex">
        <mat-datepicker-toggle matSuffix [for]="DatePicker"></mat-datepicker-toggle>
        <mat-datepicker #DatePicker></mat-datepicker>
        <button mat-icon-button type="button" (click)="resetDate(field.id)" *ngIf="formGroup.controls[field.id].value">
          <mat-icon>close</mat-icon>
        </button>
      </div>
      <mat-error *ngIf="hasError('required',field.id)">{{ 'forms.Required' | translate }}</mat-error>
      <mat-hint *ngIf="field.validationMessage">{{field.validationMessage}}</mat-hint>
    </mat-form-field>

    <ng-container *ngIf="field.inputTypeId === 4">
      <div class="form-group">
        <div [formArrayName]="field.id">
          <div *ngFor="let item of field.values; let index = index">
            <ng-container [formGroupName]="index">
              <mat-checkbox class="form-group" formControlName="check">
                {{item.title}}
              </mat-checkbox>
            </ng-container>
            <div class="form-group mb-0" *ngIf="checkHaveConditionalogic(field.conditionalogic,item.id).length">
              <div *ngFor="let item of checkHaveConditionalogic(field.conditionalogic,item.id); let i = index">
                <div class="sub_fields" *ngIf="checkIndex(field.id, index)">
                  <ng-container *ngTemplateOutlet="recursiveList,context:{field:item}">
                  </ng-container>
                </div>
              </div>

            </div>
          </div>
          <ng-container *ngIf="field.isOtherOption && field.values.length" [formGroupName]="field.values.length">
            <mat-checkbox class="form-group" formControlName="check">
              {{ 'reportsBuilder.other' | translate }}
            </mat-checkbox>
          </ng-container>

          <!-- <mat-radio-group [formControlName]="field.id"
                class="row">
                <mat-radio-button class="col-6" [value]="'Checked'">Checked
                </mat-radio-button>
                <mat-radio-button class="col-6" [value]="'Not Checked'">Not Checked
                </mat-radio-button>
            </mat-radio-group> -->
          <p class="has-error mt-0 h5" *ngIf="hasError('required',field.id) && isSubmitted">
            {{ 'forms.Required' | translate }}
          </p>
        </div>
        <mat-hint *ngIf="field.validationMessage">
          {{field.validationMessage}}
        </mat-hint>
        <mat-form-field class="form-group" hideRequiredMarker *ngIf="field.isOtherOption && field.values.length"
          [hidden]="!checkIndex(field.id, field.values.length)">
          <mat-label>Type here</mat-label>
          <input matInput [formControlName]="field.id + '_other'"
            [required]="checkIndex(field.id, field.values.length)">
          <mat-error *ngIf="otherHasError(field.id,'required')">{{ 'forms.Required' | translate }}</mat-error>
        </mat-form-field>
      </div>

    </ng-container>

    <ng-container *ngIf="field.inputTypeId === 7">
      <div class="form-group">
        <mat-radio-group [formControlName]="field.id" class="row">
          <mat-radio-button *ngFor="let item of field.values" class="col-12" [value]="item.id">
            {{item.title}}
          </mat-radio-button>
          <mat-radio-button *ngIf="field.isOtherOption" class="col-12" value="other">
            {{ 'reportsBuilder.other' | translate }}
          </mat-radio-button>
        </mat-radio-group>
        <mat-error *ngIf="hasError('required',field.id) && isSubmitted">{{ 'forms.Required' | translate }}</mat-error>
        <mat-hint *ngIf="field.validationMessage">{{field.validationMessage}}</mat-hint>
      </div>

      <mat-form-field class="form-group" hideRequiredMarker *ngIf="field.isOtherOption"
        [hidden]="formGroup.controls[field.id].value !== 'other'">
        <mat-label>Type here</mat-label>
        <input matInput [formControlName]="field.id + '_other'"
          [required]="formGroup.controls[field.id].value === 'other'">
        <mat-error *ngIf="otherHasError(field.id,'required')">{{ 'forms.Required' | translate }}</mat-error>
      </mat-form-field>

      <div class="form-group" *ngIf="field.conditionalogic.length">
        <div class="sub_fields" [hidden]="formGroup.controls[field.id].value !== item.valueId"
          *ngFor="let item of field.conditionalogic; let i = index">
          <ng-container *ngTemplateOutlet="recursiveList,context:{field:item}">
          </ng-container>
        </div>
      </div>
    </ng-container>

    <div class="form-group preview-textboxes" *ngIf="field.inputTypeId === 8">
      <div class="row align-items-stretch" [formArrayName]="field.id">

        <ng-container *ngFor="let item of getArray(field.jsonProps.numberOFTextBoxes); let i = index">
          <div class="col-sm-5">
            <mat-form-field>
              <input matInput [formControlName]="i" maxlength="100">
              <mat-error *ngIf="hasError('required',field.id, i) && isSubmitted">
                {{ 'forms.Required' | translate }}
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-sm-1 suffix_item px-0" *ngIf="getArray(field.jsonProps.numberOFTextBoxes).length !== (i + 1)">
            <span class="sperator" *ngIf="field.jsonProps.equationTypeCode">
              {{field.jsonProps.equationTypeTitle}}
            </span>
          </div>
        </ng-container>
        <div class="col-sm-1 suffix_item px-0" *ngIf="field.jsonProps.hasAUnit">
          <span class="unit-name">
            {{field.jsonProps.unitName}}
          </span>
        </div>
      </div>
      <mat-hint *ngIf="field.validationMessage">
        <small>{{field.validationMessage}}</small>
      </mat-hint>
    </div>
  </ng-template>



</div>