import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import * as saveAs from 'file-saver';

@Component({
  selector: 'app-files-viewer',
  templateUrl: './files-viewer.component.html',
})
export class FilesViewerComponent implements OnInit {
  @Input() files: any = [];
  @Input() cols = 1;
  @Input() downloadWithApi = false;
  @Input() enableDownload = true;
  @Input() viewFile = false;
  @Output() viewFileEvent = new EventEmitter<any>();
  docsEditable = false;
  requestToken: string;

  constructor() { }

  ngOnInit() {
  }
  fileType(fileName) {
    const extension = fileName.split('.').pop().toLowerCase();
    switch (extension) {
      case 'pdf':
        return 'pdf';
      case 'jpeg':
      case 'mp4':
        return 'mp4';
      case 'jpg':
        return 'jpg';
      case 'png':
        return 'png';
      case 'doc':
      case 'docx':
      case 'wpd':
      case 'tex':
        return 'doc';
      case 'xlsx':
      case 'xls':
        return 'xls';
      case 'zip':
      case 'rar':
        return 'zip';
      case 'ppt':
      case 'pptx':
        return 'pptx';
      case 'svs':
      case 'tif':
      case 'ndpi':
      case 'scn':
      case 'mrxs':
      case 'svslide':
      case 'bif':
        return 'wsi';
      default:
        return false;
    }
  }

  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  formatBytes(bytes, decimals = 2) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }


  downloadImg(file: any) {
    saveAs(file.path, file.name);
  }
}
