<div class="stream-container">
  <div *ngIf='dataLoading' class="dataLoading">
    <mat-spinner class="m-auto" diameter="50"></mat-spinner>
  </div>
  <div class="channel-area" [ngClass]="{'has-side': showChat}">
    <div class="video-wrapper">
      <h4 class="streamMsg" *ngIf="streamMsg">{{streamMsg}}</h4>
      <video id="localVideo" #localVideo [muted]="false" [controls]="false"></video>
    </div>
    <div class="side-wrapper" *ngIf="showChat">
      <div class="chat-wrapper">
        <div class="chat-inputs">
          <div class="input-group">
            <textarea type="text" class="form-control"
              (keyup)="textAreaAdjust($event)" (keydown.enter)="sendMsg($event)"
              rows="1" [(ngModel)]="message" placeholder="Write your message..."></textarea>
          </div>
          <div>
            <button mat-icon-button class="mat-primary btn-send" (click)="sendMsg()" [disabled]="!message">
              <mat-icon>send</mat-icon>
            </button>
          </div>
        </div>
        <div class="chat-list">
          <div class="no-message" *ngIf="!chatList.length">
            <div>
              <img src="./assets/images/icons/chat-empty.svg" alt="">
              <p>No messages yet</p>
            </div>
          </div>
          <ul *ngIf="chatList.length">
            <li *ngFor="let message of chatList" [class.mine]='message.mine'>
              <div>
                <p>{{ message.text }}</p>
                <p class="time">{{ message.time | date:'hh:mm aaa' }}</p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <div class="toolbar" [hidden]="dataLoading">
    <div class="row align-items-center">

      <div class="col-sm-4">
        <div class="text-sm-left text-center">
          <h6 class="m-0">{{details?.courseTitle}}</h6>
        </div>
      </div>

      <div class="col-sm-4">
        <div class="d-flex align-items-center justify-content-center">
          <ng-container *ngIf="!isOwner">
            <button class="btn btn-primary mx-1" *ngIf="!isPlaying" [disabled]="actionLoading"
              (click)="play()">Join Stream</button>
          </ng-container>

          <ng-container *ngIf="isOwner">
            <button class="btn btn-primary mx-1" [disabled]="actionLoading" *ngIf="!webrtcAdaptor"
              (click)="initWebrtc()">Start Stream</button>

            <ng-container *ngIf="webrtcAdaptor">
              <button class="btn btn-primary mx-1" [disabled]="actionLoading"
                [class.loading]="actionLoading == 'publish'" *ngIf="!isPublished"
                (click)="PublishStreaming()">Puplish Stream</button>

              <button mat-icon-button class="mat-grey mx-1" [disabled]="actionLoading" (click)="shareScreen()">
                <mat-icon>present_to_all</mat-icon>
              </button>

              <button mat-icon-button class="mat-warn mx-1" [disabled]="actionLoading"
                [class.loading]="actionLoading == 'stop'" (click)="StopPublishing()">
                <mat-icon>call_end</mat-icon>
              </button>
            </ng-container>
          </ng-container>
        </div>
      </div>

      <div class="col-sm-4">
        <div class="d-flex align-items-center justify-content-center justify-content-sm-end">
          <button mat-icon-button class="mx-1" *ngIf="isPlaying || isPublished" (click)="showChat = !showChat">
            <mat-icon>chat</mat-icon>
          </button>
        </div>
      </div>

    </div>
  </div>

</div>
