import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class QuestionBankService {
  refreshDetails = new BehaviorSubject<boolean>(false);
  constructor(private http: HttpClient) { }


  getQuestionBankList(filter): any {
    return this.http.get(`${environment.apiUrl}/QuestionBank/GetAllQuestionBanks`, {
      params: filter,
    });
  }

  saveQuestionBanckRecord(data) {
    if (data.id) {
      return this.http.put(`${environment.apiUrl}/QuestionBank/UpdateQuestionBank`, data);
    } else {
      return this.http.post(`${environment.apiUrl}/QuestionBank/AddQuestionBank`, data);
    }
  }

  deleteQuestion(id: string) {
    return this.http.delete(
      `${environment.apiUrl}/QuestionBankQuestion/DeleteQuestionBankQuestions?QuestionId=${id}`
    );
  }

  getQuestionBankDetails(id: string) {
    return this.http.get(`${environment.apiUrl}/QuestionBank/GetQuestionBankDetails`, {
      params: { QuestionBankId: id },
    });
  }

  addNewQuestion(data: any, id: string | null) {
    if (id) {
      return this.http.put(`${environment.apiUrl}/QuestionBankQuestion/UpdateQuestionBankQuestion`, data);
    } else {
      return this.http.post(`${environment.apiUrl}/QuestionBankQuestion/AddQuestionBankQuestion`, data);
    }
  }

  getQuestions(id: string) {
    return this.http.get(`${environment.apiUrl}/QuestionBankQuestion/GetQuestionBankQuestionsLists`, {
      params: { QuestionBankId: id },
    });
  }

  orderingQuestions(model:any){
    return this.http.put(`${environment.apiUrl}/QuestionBankQuestion/OrderQuestionBankQuestions`, model);

  }

  getQuestionBankCategories(filter): any {
    return this.http.get(`${environment.apiUrl}/QuestionBankCategory/List`, {
      params: filter,
    });
  }

  getQuestionBankDifficulty(filter): any {
    return this.http.get(`${environment.apiUrl}/QuestionBankDifficulty/List`, {
      params: filter,
    });
  }
  saveCategoryRecord(data) {
    if (data.id) {
      return this.http.put(`${environment.apiUrl}/QuestionBankCategory/Edit`, data);
    } else {
      return this.http.post(`${environment.apiUrl}/QuestionBankCategory/Add`, data);
    }
  }
  saveDifficultyRecord(data) {
    if (data.id) {
      return this.http.put(`${environment.apiUrl}/QuestionBankDifficulty/Edit`, data);
    } else {
      return this.http.post(`${environment.apiUrl}/QuestionBankDifficulty/Add`, data);
    }
  }

  deleteQuestionBank(id) {
    return this.http.delete(
      `${environment.apiUrl}/QuestionBank/DeleteQuestionBank?QuestionBankId=${id}`
    );
  }
  deleteCategoryRecord(id) {
    return this.http.delete(
      `${environment.apiUrl}/QuestionBankCategory/Delete?id=${id}`
    );
  }
  deleteDifficultyRecord(id) {
    return this.http.delete(
      `${environment.apiUrl}/QuestionBankDifficulty/Delete?id=${id}`
    );
  }
}
