import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'dateSuffix'
})
export class DateSuffixPipe implements PipeTransform {

  constructor(private translate: TranslateService) { }

  transform(value: any): any {
    if (!value) return value;

    const datePipe = new DatePipe(this.translate.currentLang);
    const day = datePipe.transform(value, 'd');
    const month = datePipe.transform(value, 'MMMM');
    const year = datePipe.transform(value, 'y');

    const dayWithSuffix = this.getDayWithSuffix(+day);

    // Use translation with parameters
    return this.translate.instant('general.DATE', {
      dayWithSuffix,
      month,
      year
    });
  }

  getDayWithSuffix(day: number): string {
    if (day > 3 && day < 21) return day + 'th';
    switch (day % 10) {
      case 1: return day + 'st';
      case 2: return day + 'nd';
      case 3: return day + 'rd';
      default: return day + 'th';
    }
  }
}
