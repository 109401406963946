import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class HPayementService {

  constructor(private http: HttpClient) { }

  getCopyAndPayId(data: any): any {
    return this.http.post(`${environment.apiUrl}/HPayement/GetCopyAndPayId`, data);
  }

  getCheckoutStatus(params: any): any {
    return this.http.get(`${environment.apiUrl}/HPayement/GetCheckoutStatus`, { params });
  }

}
