import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { TraineeCertificateIssuanceService } from 'src/app/services/trainee-certificate-issuance.service';
export interface DialogData {
  templateId: number;
  courseId: number;
  enrolledTraineeId: number;
  file: string;
}
@Component({
  selector: 'app-preview-certificates',
  templateUrl: './preview-certificates.component.html'
})
export class PreviewCertificatesComponent implements OnInit {
  templateId: number;
  courseId: number;
  file: any;
  dataLoading = false;

  constructor(
    public dialogRef: MatDialogRef<PreviewCertificatesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private sanitizer: DomSanitizer,
    private service: TraineeCertificateIssuanceService
  ) { }

  ngOnInit(): void {
    if (this.data.file) {
      this.file = this.sanitizer.bypassSecurityTrustResourceUrl(this.data.file);
    } else {
      this.previewCertificate();
    }
  }

  previewCertificate() {
    this.dataLoading = true;
    const data = {
      templateId: this.data.templateId,
      courseId: this.data.courseId,
      enrolledTraineeId: this.data.enrolledTraineeId
    }
    this.service.previewCertificate(data).subscribe(
      data => {
        // const blob = new Blob([data], { type: 'application/pdf' })
        this.file = this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(data));
        this.dataLoading = false;
      },
      error => {
        this.dataLoading = false;
      });
  }

}
