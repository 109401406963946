import { Lookup } from '../lookups/Lookup';
import { EntityEnumSLA } from '../sla/enums/EntityEnumSLA';
import { statusList } from '../sla/enums/statusList';
import { CommunicationMethod } from '../global/CommunicationMethod';
import { LabType } from './enums/LabType';

export class LabDetails {
  id?: number;
  labType: LabType;
  mainLab: Lookup;
  labName: string;
  logo: string;
  plagiarismThreshold: string;
  basicInfo: {
    address: string;
    contactNumberFlag: string;
    contactNumberExtension: string;
    contactNumber: string;
    email: string;
    managerName: string;
    managerFirstName: string;
    managerLastName: string;
    isEmailVerified: boolean;
    isMobileVerified: boolean;
  };
  country: Lookup;
  region: Lookup;
  city: Lookup;
  managerName: string;
  communicationMethod: CommunicationMethod[];
  labSLA: {
    slaId: number;
    slaStatus: statusList;
    creationDate: Date;
    rejectionReason: string;
    updatedDate: Date;
    rejectionDate: Date;
  };
  lastSLA: {
    id: number;
    status: statusList;
    rejectionReason: string;
    updatedDate: Date;
    rejectionDate: Date;
  };
  // canSendToSC: boolean;
  // canSendToVL: boolean;
  // scanningCenters: Lookup[];
  casesCount: number;
  labUserDetails: Lookup[];
  branches: Lookup[];
  canDeleteLab: boolean;
  createdOn: string;
  entity: {
    entityId: number;
    entityType: EntityEnumSLA,
    entityName: string;
    logoUrl: string;
  };
  closedCasesCount: number;
  openCasesCount: number;
}
