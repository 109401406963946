<footer data-scroll-section>
  <div class="container">
    <div class="logo-container">
      <div class="logo">
        <img src="assets/images/logo-for-footer.svg" alt="">
      </div>
      <div class="title">{{"footer.bridgingBordersConnectingPathologists"|translate}}</div>
    </div>

    <div class="footer-content">
      <div class="row">
        <div class="col-lg-3 col-6 my-lg-0 my-4">
          <div class="footer-titel">{{"footer.company"|translate}}</div>
          <ul class="footer-list">
            <li><a [routerLink]="['/']">{{"footer.about"|translate}}</a></li>
            <li><a [routerLink]="['/']">{{"footer.career"|translate}}</a></li>
            <li><a [routerLink]="['/']">{{"footer.medicalAdvisoryBoard"|translate}}</a></li>
            <li><a [routerLink]="['/']">{{"footer.trainingPrograms"|translate}}</a></li>
            <li><a [routerLink]="['/']">{{"footer.newsAndEvents"|translate}}</a></li>
          </ul>
        </div>
        <div class="col-lg-3 col-6 my-lg-0 my-4">
          <div class="footer-titel">{{"footer.help"|translate}}</div>
          <ul class="footer-list">
            <li><a [routerLink]="['/contact-us']">{{"footer.contactUs"|translate}}</a></li>
            <li><a [routerLink]="['/']">{{"footer.helpCenter"|translate}}</a></li>
            <li><a [routerLink]="['/']">{{"footer.faq"|translate}}</a></li>
            <li><a [routerLink]="['/']">{{"footer.privacyPolicy"|translate}}</a></li>
            <li><a [routerLink]="['/']">{{"footer.termsAndConditions"|translate}}</a></li>
          </ul>
        </div>
        <div class="col-lg-3 col-6 my-lg-0 my-4">
          <div class="footer-titel">{{"footer.products"|translate}}</div>
          <ul class="footer-list">
            <li><a [routerLink]="['/']">PathoDX</a></li>
            <li><a [routerLink]="['/']">EduPath</a></li>
            <li><a [routerLink]="['/']">PathoMeet</a></li>
            <li><a [routerLink]="['/']">CytoDX</a></li>
          </ul>
        </div>
        <div class="col-lg-3 col-6 my-lg-0 my-4">
          <div class="footer-titel">{{"footer.services"|translate}}</div>
          <ul class="footer-list">
            <li><a [routerLink]="['/']">{{"footer.aiValidation"|translate}}</a></li>
            <li><a [routerLink]="['/']">{{"footer.slidesScanning"|translate}}</a></li>
            <li><a [routerLink]="['/']">{{"footer.buildingTheCancerRegistry"|translate}}</a></li>
            <li><a [routerLink]="['/']">{{"footer.managingScreeningPrograms"|translate}}</a></li>
            <li><a [routerLink]="['/']">{{"footer.secondOpinionAndConsultation"|translate}}</a></li>
            <li><a [routerLink]="['/']">{{"footer.digitizingTheSlidesArchives"|translate}}</a></li>
          </ul>
        </div>
      </div>
    </div>

    <div class="footer-bottom">
      <div class="social">
        <span>{{"footer.followUsOn"|translate}}:</span>
        <ul class="social-links">
          <li><a [routerLink]="['/']"><img src="assets/images/icons/ic_facebook.svg" alt=""></a></li>
          <li><a [routerLink]="['/']"><img src="assets/images/icons/ic_x.svg" alt=""></a></li>
          <li><a [routerLink]="['/']"><img src="assets/images/icons/ic_linkedin.svg" alt=""></a></li>
        </ul>
      </div>
      <div class="copy-right">
        {{"footer.copy-right"|translate:{date:2024} }}
      </div>
    </div>
  </div>
</footer>