<div class="pdf-viewer" *ngIf="pdfData">
  <object [data]="getUrl()" type="application/pdf"></object>
  <div class="controls">
    <!-- ... (previous controls) ... -->
    <div class="page-navigation">
      <label for="pageInput">Go to Page:</label>
      <input id="pageInput" type="number" min="1" max="{{ totalPages }}" [(ngModel)]="currentPage">
      <button (click)="goToPage()">Go</button>
    </div>
  </div>
</div>