import { Directive, ElementRef, HostBinding, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appDropDown]'
})
export class DropDownDirective {
  @Input() wasInside: boolean;

  constructor(private eRef: ElementRef) { }

  @HostBinding('class.active') isOpen = false;

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (this.eRef.nativeElement.contains(event.target)) {
      if ((this.wasInside && !document.querySelector('.dropdown-inner-menu').contains(event.target)) || !this.wasInside) {
        this.isOpen = !this.isOpen;
      }
    } else {
      this.isOpen = false;
    }
  }

}


/* if (this.eRef.nativeElement.contains(event.target)) {
  if ((this.eRef.nativeElement.attributes).hasOwnProperty('appdropdownlink')) {
    console.log((this.eRef.nativeElement.attributes).hasOwnProperty('appdropdownlink'));
    this.isOpen = !this.isOpen;
    console.log(this.isOpen);
  } else {
    if (this.wasInside) {
      this.isOpen = true;
    } else {
      this.isOpen = !this.isOpen;
    }
  }
  console.log('clicked inside');
} else {
  this.isOpen = false;
}
this.toggleClass();
toggleClass() {
  if (this.isOpen) {
    this.render.addClass(this.eRef.nativeElement, 'active');
  } else {
    this.render.removeClass(this.eRef.nativeElement, 'active');
  }
}
 */
