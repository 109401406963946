import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-select-with-search',
  templateUrl: './select-with-search.component.html'
})
export class SelectWithSearchComponent implements OnInit, OnChanges {
  @ViewChild('doctorSelect') selectElem: MatSelect;
  @Input() filterFormGroup: FormGroup;
  @Input() sourcData: any[];
  @Input() isSubmitted: boolean;
  @Input() controlName: any;
  @Input() fieldLabel: String;
  @Input() keyTitle: any = 'name';
  @Input() multiple: boolean = true;
  @Input() hasApi: boolean = false;
  @Output() searchEvent = new EventEmitter<string>();
  @Output() setValueEvent = new EventEmitter<boolean>();
  @Output() scrollEvent = new EventEmitter<boolean>();
  @Input() pageCount: number;
  @Input() dataLoading: boolean;
  //
  @ViewChild('searchInput', { static: false })
  searchInput: ElementRef<HTMLInputElement>;
  searchControl = new FormControl();
  //
  constructor() { }

  ngOnInit(): void {
    this.searchControl.valueChanges.pipe(
      debounceTime(100),
      distinctUntilChanged()
    ).subscribe(value => {
      console.log(value)
      // Your code here
      this.applyFilter(value);
    });

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.isSubmitted && changes.isSubmitted.currentValue) {
      this.filterFormGroup.controls[this.controlName].markAllAsTouched();
    }
  }

  applyFilter(value: string) {
    const filterValue = value.toLowerCase();
    // console.log(value)
    if (this.hasApi) {
      this.searchEvent.emit(value);
    } else {
      this.sourcData.forEach((item) => {
        item.hidden = true;
        if (filterValue === '') {
          item.hidden = false;
        } else if (item[this.keyTitle].toLowerCase().includes(filterValue)) {
          item.hidden = false;
        }
      });
    }
  }
  openEvent(opened) {
    if (opened) {
      if (this.hasApi) {
        const panel = this.selectElem.panel.nativeElement;
        if (panel) {
          panel.addEventListener('scroll', () => this.onScroll(panel));
        }
      }
      this.searchInput.nativeElement.focus();
    } else {
      if (this.hasApi) {
        // this.setValueEvent.emit(true);
      } else {
        this.clearFilter();
      }
    }
  }

  clearFilter() {
    this.searchInput.nativeElement.value = '';
    this.applyFilter('');
  }

  onScroll(panel: HTMLElement) {
    console.log(Math.round(panel.scrollTop + panel.clientHeight), panel.scrollHeight)
    if (Math.round(panel.scrollTop + panel.clientHeight + 1) >= panel.scrollHeight && !this.dataLoading) {
      this.scrollEvent.emit(true);
    }
  }

  hasError(controlName: string, errorName: string) {
    return this.filterFormGroup.controls[controlName].hasError(errorName);
  }
}
