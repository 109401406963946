import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LocalStoreService } from './local-store.service';
import { Direction, Directionality } from '@angular/cdk/bidi';

@Injectable({
  providedIn: 'root'
})
export class TranslationService {
  direction = 'ltr';

  constructor(
    private translate: TranslateService,
    private store: LocalStoreService
  ) { }

  setLanguage(lang?) {
    if (!lang) {
      lang = localStorage.getItem('language') || this.translate.getDefaultLang();
    }
    this.translate.use(lang);
    localStorage.setItem('language', lang);
    this.direction = lang === 'ar' ? 'rtl' : 'ltr';
    let style = 'assets/css/en-US/style.css';
    if (lang == 'ar') {
      style = 'assets/css/ar-SA/style.css';
    }
    document.getElementById('mainStyle').setAttribute('href', style);
    document.getElementsByTagName('html')[0].setAttribute('lang', lang);
    document.getElementsByTagName('html')[0].setAttribute('dir', this.direction);
    document.getElementsByTagName('html')[0].setAttribute('style', 'direction: ' + this.direction + '');
  }

  /**
   * Returns selected language
   */
  getSelectedLanguage(): any {
    return this.store.getItem('language') || this.translate.getDefaultLang();
  }
}
