<nav class="navbar head-nav">
  <h2>{{"profile.myProfile"|translate}}</h2>
  <ol class="breadcrumb">
    <li class="breadcrumb-item">
      <a [routerLink]='["/"]'>{{"general.home"|translate}}</a>
    </li>
    <li class="breadcrumb-item">{{"profile.myProfile"|translate}} </li>
  </ol>
</nav>
<div *ngIf='dataLoading' class="dataLoading">
  <mat-spinner class="m-auto" diameter="50"></mat-spinner>
</div>

<ng-container *ngIf='!dataLoading && this.user'>
  <div class="container-fluid px-0">
    <div class="row mx-0 justify-content-center">
      <div class="col-sm-12"
        [ngClass]="(checkRole('Pathologist') || checkRole('VLPathologist') || checkRole('ScanningCenterTechnician') || checkRole('ScanningCenterRepresentative') || checkRole('VLTechnician') || checkRole('VLRepresentative')) ? 'col-lg-5':'col-md-8'">
        <div class="card_info_profile">
          <div class="card_info_profile_content">
            <div class="top_profile_user">
              <div class="user_info_avatar">
                <div *ngIf="avatarSrc" class="user_info_img" [style.backgroundImage]="'url(' + avatarSrc + ')'"></div>
                <img *ngIf="avatarSrc" [src]="avatarSrc" (error)='avatarSrc = ""'>
                <span *ngIf="!avatarSrc">{{ user.fullName | shortName }}</span>
                <label class="icon-upload" for="uploadAvatar">
                  <input type='file' class="d-none" id="uploadAvatar" accept="image/jpeg, image/pjpeg, image/png"
                    (change)="uploadAvatar($event)">
                  <img src="assets/images/icons/insert-picture-icon.svg" alt="">
                </label>
              </div>
              <p class="invalid-feedback mt-2 d-block" *ngIf="fileTypeError">{{ 'forms.InvalidFile' | translate }}</p>
              <p class="invalid-feedback mt-2 d-block" *ngIf="fileSizeError">{{ 'forms.Max-files' | translate }}
                {{maxFileSize}} Mb</p>
              <div class="actions_profile mb-3" [hidden]="!avatar">
                <button mat-raised-button [disabled]="loading" [class.loading]='loading' class="mat-outline primary"
                  type="button" (click)="clear()">
                  {{"profile.cancel"|translate}}
                </button>
                <button mat-raised-button [disabled]="loading" [class.loading]='loading' color="primary" type="button"
                  (click)="updateAvatar()">
                  {{"profile.saveChanges"|translate}}
                </button>
              </div>
              <h4>
                {{user.fullName}}
                <span>({{"profile.you"|translate}})</span>
              </h4>
              <h5>
                {{user.userName}}
                <button mat-button color="primary" class="btn_link_with_icon" (click)="changeUsername()">
                  {{"general.edit"|translate}}
                </button>
              </h5>
              <ul>
                <li class="tag-item" *ngFor="let role of user.rolesNames">
                  {{role}}
                </li>
              </ul>
            </div>
            <div class="profile_detail text-center">
              <ul class="list-items border-0">
                <li>
                  <span class="title">
                    {{"general.memberSince"|translate}}
                  </span>
                  <div class="desc">
                    {{user.memberSince| date:'d MMM, y, h:mm a'}}
                  </div>
                </li>

                <li *ngIf="user.identificationType?.name">
                  <span class="title">
                    {{"profile.identificationType"|translate}}
                  </span>
                  <div class="desc">
                    {{user.identificationType?.name}}
                  </div>
                </li>

                <li *ngIf="user.identityNumber">
                  <span class="title">
                    {{"profile.identityNumber"|translate}}
                  </span>
                  <div class="desc">
                    {{user.identityNumber}}
                  </div>
                </li>

                <li *ngIf="user.scfhs && hasPermission('Trainee')">
                  <span class="title">
                    scfhs
                  </span>
                  <div class="desc">
                    {{user.scfhs}}
                  </div>
                </li>

                <li *ngIf="user.certifcateLanguage && hasPermission('Trainee')">
                  <span class="title">
                    {{"userForm.preferredLanguage"|translate}}
                  </span>
                  <div class="desc">
                    {{user.certifcateLanguage.name}}
                  </div>
                </li>

                <li>
                  <span class="title">
                    {{"profile.contactNo"|translate}}
                  </span>
                  <div class="desc">
                    {{user.contactNumberExtension + ' ' + user.contactNumber}}
                  </div>
                  <div class="conatnier_actions">
                    <ng-container *ngIf="!user.isVerfiedMobile">
                      <span class="badge_status_progress orange">
                        {{"profile.mobileNoNotVerified"|translate}}
                      </span>
                      <a class="button_with_icon" mat-raised-button color="primary" (click)="verifyMobile()">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="19.008" viewBox="0 0 16 19.008">
                          <g id="verify-icon" transform="translate(-8.887 -2.496)" opacity="0.85">
                            <path id="Path_17564" data-name="Path 17564"
                              d="M54.572,2.915,47.766.054a.686.686,0,0,0-.533,0L40.428,2.915A.7.7,0,0,0,40,3.564V7.042a11.776,11.776,0,0,0,7.238,10.906.687.687,0,0,0,.525,0A11.776,11.776,0,0,0,55,7.042V3.564A.7.7,0,0,0,54.572,2.915Zm-.961,4.127A10.377,10.377,0,0,1,47.5,16.533a10.377,10.377,0,0,1-6.111-9.491V4.034L47.5,1.465l6.111,2.569Z"
                              transform="translate(-30.613 3)" fill="#fff" stroke="rgba(0,0,0,0)" stroke-width="1"
                              opacity="0.7" />
                            <path id="Path_17565" data-name="Path 17565"
                              d="M46.732,9.412,49.717,6.39a.688.688,0,0,1,.982,0,.709.709,0,0,1,0,.994L47.223,10.9a.688.688,0,0,1-.982,0L44.3,8.939a.709.709,0,0,1,0-.994.688.688,0,0,1,.982,0Z"
                              transform="translate(-30.613 3)" fill="#fff" stroke="rgba(0,0,0,0)" stroke-width="1" />
                          </g>
                        </svg>
                        <span class="value">{{"profile.verifyNow"|translate}}</span>
                      </a>
                    </ng-container>
                    <ng-container *ngIf="user.isVerfiedMobile">
                      <span class="badge_status_progress success">
                        ({{"profile.verified"|translate}})
                      </span>
                    </ng-container>

                  </div>
                </li>
                <li>
                  <span class="title">
                    {{"general.email"|translate}}
                  </span>
                  <div class="desc">
                    <a [href]="'mailto:'+user.email">{{user.email}}</a>
                  </div>
                  <!-- <ng-container *ngIf="user.isVerfiedMobile">
                    <span class="badge_status_progress success">
                      (Verified)
                    </span>
                  </ng-container> -->
                </li>
                <li *ngIf="user.canShareToConCural">
                  <div class="conatnier_actions">
                    <span class="badge_status_progress dark-orange">
                      {{"profile.canShareToConcural"|translate}}
                    </span>
                  </div>
                </li>
                <li>
                  <span class="title">
                    {{"general.address"|translate}}
                  </span>
                  <div class="desc">
                    <p>{{user.address}}</p>
                    <p *ngIf="user.city">{{user.city.name}}, {{user.region.name}}</p>
                    <p>{{user.country?.name}}</p>
                  </div>
                </li>
              </ul>
              <div class="actions_profile">
                <button mat-raised-button class="mat-outline primary my-1" type="button" (click)="changePassword()">
                  {{"profile.changePassword"|translate}}
                </button>
                <button mat-raised-button color="primary" type="button" class="button_with_icon"
                  (click)="editProfile()">
                  <svg width="16" height="16.004" viewBox="0 0 16 16.004">
                    <path id="edit-icon"
                      d="M5.82,14l-3.63-3.63,8.78-8.78,3.63,3.63ZM1.851,11.011l3.33,3.33L.188,16Zm13.865-6.9-.63.63L11.451,1.1l.63-.63a1.624,1.624,0,0,1,2.3,0l1.338,1.338a1.633,1.633,0,0,1,0,2.3Zm0,0"
                      transform="translate(-0.188 0.001)" />
                  </svg>
                  <span class="value">{{"profile.editMyProfile"|translate}}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-7" [hidden]="!checkRole('Pathologist') && !checkRole('VLPathologist')">
        <div class="card_info_profile">
          <div class="card_info_profile_content">
            <div class="card_profile_user_head">
              <h4>{{"profile.subspecialty"|translate}}</h4>
            </div>
            <div class="profile_detail">
              <ul class="list-items border-0 pb-0">
                <li>
                  <span class="title">
                    {{"profile.subspecialty"|translate}}
                  </span>
                  <div class="desc">
                    <ul class="list_style_square">
                      <li class="item_style_square" *ngFor="let subSpeciality of user.subSpeciality">
                        <i class="material-icons">
                          check_circle_outline
                        </i>
                        {{subSpeciality.name}}
                      </li>
                    </ul>
                  </div>
                </li>
                <li *ngIf="user.reviwer">
                  <span class="title">
                    {{"profile.reviewer"|translate}}
                  </span>
                  <div class="desc">
                    <div class="item_avatar_inner">
                      <img *ngIf="user.reviwer.logo" [src]="user.reviwer.logo" (error)='user.reviwer.logo = ""'>
                      <span *ngIf="!user.reviwer.logo">
                        {{ user.reviwer.reviewerName | shortName }}
                      </span>
                      <div class="item_avatar_content">
                        <h4>
                          {{user.reviwer.reviewerName}}
                        </h4>
                        <ng-container *ngFor="let item of user.reviwer.reviewerRolesName; index as i">
                          <span>{{(i !== 0 ? ' ,' : '') + item}}</span>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                </li>
                <li *ngIf="user.canSendScondOpinion">
                  <div class="desc">
                    <ul class="list_style_square">
                      <li class="item_style_square">
                        <i class="material-icons">
                          check_circle_outline
                        </i>
                        {{"profile.canRequestSecondOpinion"|translate}}
                      </li>
                    </ul>
                  </div>
                </li>
                <!-- <li *ngIf="user.availability && user.availability.length">
                  <span class="title">
                    {{"profile.availability"|translate}}
                  </span>
                  <div class="desc">
                    <div class="row">
                      <div class="col-xl-8 col-lg-8 col-md-12">
                        <ul class="list_schedules">
                          <li class="item_schedule" *ngFor="let item of user.availability">
                            <img src="assets/images/icons/datetime-icon.svg" alt="datetime">
                            <div class="item_schedule_inner">
                              <h4>{{'days.'+item.availableDay|translate}}</h4>
                              <span>
                                {{'0000-00-00T' + item.availableTimeFrom | date:'hh:mm aa'}}
                              </span> :
                              <span>
                                {{'0000-00-00T' + item.availableTimeTo | date:'hh:mm aa'}}
                              </span>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </li> -->
              </ul>
            </div>
          </div>
        </div>
        <div class="card_info_profile">
          <div class="card_info_profile_content">
            <div class="card_profile_user_head mb-4">
              <h4>{{"profile.subscribedTo"|translate}}</h4>
            </div>
            <div class="profile_detail">
              <!---->
              <div class="details-section-container" *ngFor="let item of user.worksFor">
                <div class="work-at-details">
                  <div class="img-circle">
                    <div class="image m-0">
                      <img *ngIf="item.labLogo" [src]="item.labLogo" (error)='item.labLogo = ""'>
                      <span *ngIf="!item.labLogo">{{ item.labName | shortName }}</span>
                    </div>
                  </div>
                  <div>
                    <div class="lab-title">{{item.labName}}</div>
                    <div class="joined-date">{{"profile.joinedOn"|translate}}: {{item.joinedAt | date:'d MMM, y, h:mm a'}}</div>
                    <!-- <ng-container *ngIf="item.hasReviewer;else noReviwer">
                      <div class="reviewer-details" *ngIf="item.reviwer?.reviewerName">
                        <div class="reviewer-title">Reviewer</div>
                        <div class="img-circle">
                          <div class="image m-0">
                            <img *ngIf="item.reviwer?.logo" [src]="item.reviwer?.logo" (error)='item.reviwer.logo = ""'>
                            <span *ngIf="!item.reviwer?.logo">{{ item.reviwer?.reviewerName | shortName }}</span>
                          </div>
                        </div>
                        <div class="reviewer-name">{{item.reviwer?.reviewerName}}</div>
                      </div>
                      <div class="reviewer-details" *ngIf="item.reviwer?.reviewerName">
                        <div class="reviewer-title">Reviewer Role: </div>
                        <div class="reviewer-name ml-2">
                          <ng-container *ngFor="let role of item.reviwer.reviewerRolesName; index as i">
                            <span>{{(i !== 0 ? ' ,' : '') + role}}</span>
                          </ng-container>
                        </div>
                      </div>
                    </ng-container>

                    <ng-template #noReviwer>
                      Has no reviewer
                    </ng-template> -->

                    <div class="desc" *ngIf="item.canSendScondOpinion">
                      <ul class="list_style_square">
                        <li class="item_style_square">
                          <i class="material-icons">
                            check_circle_outline
                          </i>
                          {{"profile.canRequestSecondOpinion"|translate}}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <app-empty-state *ngIf="!user.worksFor?.length" [img]='"./assets/images/seconed-opinion-doctor.svg"'
                [desc]='"profile.noLabExperience"|translate' [linkBoolean]="false">
              </app-empty-state>
              <!---->
            </div>
          </div>
        </div>
        <div class="card_info_profile">
          <div class="card_info_profile_content">
            <div class="card_profile_user_head mb-4">
              <h4>{{"userDetails.myIdentityCard"|translate}}</h4>
              <button mat-icon-button *ngIf="user.identityImage" (click)="openImageGuidelines()">
                <svg xmlns="http://www.w3.org/2000/svg" width="46" height="46" viewBox="0 0 46 46">
                  <g id="Edit_btn" data-name="Edit btn" transform="translate(0.065)">
                    <g id="BG" transform="translate(-0.065)" fill="#efe4f3" stroke="#dec9e6" stroke-width="1">
                      <rect width="46" height="46" rx="23" stroke="none"/>
                      <rect x="0.5" y="0.5" width="45" height="45" rx="22.5" fill="none"/>
                    </g>
                    <path id="edit-icon" d="M5.82,14l-3.63-3.63,8.78-8.78,3.63,3.63ZM1.851,11.011l3.33,3.33L.188,16Zm13.865-6.9-.63.63L11.451,1.1l.63-.63a1.624,1.624,0,0,1,2.3,0l1.338,1.338a1.633,1.633,0,0,1,0,2.3Zm0,0" transform="translate(14.812 15.001)" fill="#7d4393"/>
                  </g>
                </svg>
              </button>
            </div>
            <div class="profile_detail">
                <div class="identity-card" *ngIf="!user.identityImage">
                  <svg id="illustration_id" xmlns="http://www.w3.org/2000/svg" width="133" height="93" viewBox="0 0 133 93">
                    <path id="Fill" d="M132.928,163.289v53.866a15.916,15.916,0,0,1-15.82,16.01H23.754a15.916,15.916,0,0,1-15.82-16.01V163.289a15.916,15.916,0,0,1,15.82-16.01h93.354A15.916,15.916,0,0,1,132.928,163.289Z" transform="translate(-3.931 -143.828)" fill="#ebedef"/>
                    <path id="Outline" d="M23.755,147.285a15.831,15.831,0,0,0-15.82,15.842v53.3a15.831,15.831,0,0,0,15.82,15.842h93.357a15.831,15.831,0,0,0,15.82-15.842v-53.3a15.831,15.831,0,0,0-15.82-15.842H23.755m0-4.006h93.357a19.829,19.829,0,0,1,19.821,19.849v53.3a19.815,19.815,0,0,1-19.821,19.849H23.755A19.829,19.829,0,0,1,3.934,216.43v-53.3a19.815,19.815,0,0,1,19.821-19.849Z" transform="translate(-3.934 -143.279)" fill="#d6dbdf"/>
                    <path id="Info" d="M85.332,238.279a12.319,12.319,0,1,0-12.287-12.312A12.32,12.32,0,0,0,85.332,238.279Zm0-20.6a8.279,8.279,0,1,1-8.255,8.286A8.288,8.288,0,0,1,85.332,217.68Zm8.065,23.6H77.282a9.4,9.4,0,0,0-8.749,12.77c.4.556,3.293,13.949,16.808,13.949,13.263,0,16.54-13.575,16.806-13.948A9.4,9.4,0,0,0,93.4,241.279ZM98.383,252.6h0c-.554.8-2.508,11.359-13.042,11.359S72.761,253.271,72.3,252.6a5.348,5.348,0,0,1,4.986-7.279H93.4A5.348,5.348,0,0,1,98.383,252.6Zm17.625-23.419h9.962a2.02,2.02,0,0,0,0-4.04h-9.962a2.02,2.02,0,0,0,0,4.04Zm42.711,9.62H116.008a2.02,2.02,0,0,0,0,4.04h42.711a2.02,2.02,0,0,0,0-4.04Zm0,13.659H116.008a2.02,2.02,0,0,0,0,4.04h42.711a2.02,2.02,0,0,0,0-4.04Z" transform="translate(-47.818 -194.286)" fill="#aeb6bf"/>
                  </svg>
                  <p [innerHTML]="'userDetails.myIdentityCardText' | translate"></p>
                  <div class="details-section-container">
                    <ul>
                      <li>
                        <svg id="bullet" xmlns="http://www.w3.org/2000/svg" width="14.996" height="14.996" viewBox="0 0 14.996 14.996">
                          <path id="circle" d="M955.654,543.6a7.5,7.5,0,1,1,7.5-7.5A7.507,7.507,0,0,1,955.654,543.6Z" transform="translate(-948.155 -528.599)" fill="#7d4393"/>
                          <path id="correct" d="M2.987,5.453A.565.565,0,0,1,2.6,5.3L0,2.835l.78-.741L2.987,4.189,7.4,0l.78.741L3.377,5.3A.565.565,0,0,1,2.987,5.453Z" transform="translate(3.408 4.772)" fill="#f7f7f7"/>
                        </svg>
                        <div>
                          <h4>{{'userDetails.MandatoryAccess' | translate}}</h4>
                          <p>{{'userDetails.UploadingYourID' | translate}}</p>
                        </div>
                      </li>
                      <li>
                        <svg id="bullet" xmlns="http://www.w3.org/2000/svg" width="14.996" height="14.996" viewBox="0 0 14.996 14.996">
                          <path id="circle" d="M955.654,543.6a7.5,7.5,0,1,1,7.5-7.5A7.507,7.507,0,0,1,955.654,543.6Z" transform="translate(-948.155 -528.599)" fill="#7d4393"/>
                          <path id="correct" d="M2.987,5.453A.565.565,0,0,1,2.6,5.3L0,2.835l.78-.741L2.987,4.189,7.4,0l.78.741L3.377,5.3A.565.565,0,0,1,2.987,5.453Z" transform="translate(3.408 4.772)" fill="#f7f7f7"/>
                        </svg>
                        <div>
                          <h4>{{'userDetails.MaintainExamIntegrity' | translate}}</h4>
                          <p>{{'userDetails.YourIDWillSecureAuthentication' | translate}}</p>
                        </div>
                      </li>
                      <li>
                        <svg id="bullet" xmlns="http://www.w3.org/2000/svg" width="14.996" height="14.996" viewBox="0 0 14.996 14.996">
                          <path id="circle" d="M955.654,543.6a7.5,7.5,0,1,1,7.5-7.5A7.507,7.507,0,0,1,955.654,543.6Z" transform="translate(-948.155 -528.599)" fill="#7d4393"/>
                          <path id="correct" d="M2.987,5.453A.565.565,0,0,1,2.6,5.3L0,2.835l.78-.741L2.987,4.189,7.4,0l.78.741L3.377,5.3A.565.565,0,0,1,2.987,5.453Z" transform="translate(3.408 4.772)" fill="#f7f7f7"/>
                        </svg>
                        <div>
                          <h4>{{'userDetails.CertificateEligibility' | translate}}</h4>
                          <p>{{'userDetails.VerifiedProfiles' | translate}}</p>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <button mat-button class="w-25" color="primary" (click)="openImageGuidelines()">
                    {{"userDetails.UploadID"|translate}}
                  </button>
              </div>
                <div class="identity-card" *ngIf="user.identityImage">
                  <div class="row">
                    <div class="col-md-6">
                      <img [src]="user.identityImage.path" class="img-fluid" alt="Identity Card">
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-7"
        [hidden]="!checkRole('ScanningCenterTechnician') && !checkRole('ScanningCenterRepresentative')">
        <div class="card_info_profile">
          <div class="card_info_profile_content">
            <div class="card_profile_user_head">
              <h4>{{"profile.coveredAreas"|translate}}</h4>
            </div>
            <div class="profile_detail card_info_profile_body">
              <h5 class="pt-3">{{"profile.regionsAndCities"|translate}}:</h5>
              <ul class="list_with_check">
                <li *ngFor="let item of user.coverageAreas">
                  <div class="icon">
                    <span class="material-icons">
                      check_circle_outline
                    </span>
                  </div>
                  <div class="content">
                    <h4>{{item.name}}</h4>
                    <p>
                      {{"profile.cities"|translate}}
                    </p>
                    <div class="list_with_dots mt-0" *ngIf="item.childs && item.childs.length">
                      <ul>
                        <li *ngFor="let child of item.childs">
                          {{child.name}}
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-7" [hidden]="!checkRole('VLTechnician') && !checkRole('VLRepresentative')">
        <div class="card_info_profile">
          <div class="card_info_profile_content">
            <div class="card_profile_user_head">
              <h4>{{"profile.coveredAreas"|translate}}</h4>
            </div>
            <div class="profile_detail card_info_profile_body">
              <h5 class="pt-3">{{"profile.regionsAndCities"|translate}}:</h5>
              <ul class="list_with_check multi_levels">
                <li *ngFor="let item of user.coverageAreasVLProfile">
                  <h3 class="subtitle-form">{{item.name}}</h3>
                  <div class="level_inner mb-3" *ngFor="let node of item.childs">
                    <div class="icon">
                      <span class="material-icons">
                        check_circle_outline
                      </span>
                    </div>
                    <div class="content">
                      <h4 class="mb-0">{{node.name}}</h4>
                      <p>
                        {{"profile.cities"|translate}}
                      </p>
                      <div class="list_with_dots mt-0" *ngIf="node.childs && node.childs.length">
                        <ul>
                          <li *ngFor="let child of node.childs">
                            {{child.name}}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
