import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { CustomValidator } from 'src/app/helpers/custom.validator';

import { AuthenticationService } from 'src/app/services/authentication.service';

import { Lookup } from 'src/app/models/lookups/Lookup';
import { TranslateService } from '@ngx-translate/core';
import { SupportTicketService } from 'src/app/services/support-ticket.service';
import { SubmissionType } from 'src/app/models/support-ticket/enums/SubmissionType.enum';
import { AuthUser } from 'src/app/models/auth/AuthUser';
import { SupportTicketForm } from 'src/app/models/support-ticket/SupportTicketForm';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { PersonalData } from 'src/app/models/support-ticket/PersonalData';
import { ConfirmationDialogComponent } from 'src/app/partial/confirmation-dialog/confirmation-dialog.component';

export interface DialogData {

}

@Component({
  selector: 'app-support-ticket-form',
  templateUrl: './support-ticket-form.component.html'
})
export class SupportTicketFormComponent implements OnInit {
  currentUser = new AuthUser();

  dataLoading = false;
  loading = false;
  formGroup: FormGroup;
  isSubmitted = false;

  roleList: Lookup[] = [];
  roleListLoading = false;
  submissionTypeList = [
    { name: 'supportTicket.suggestion', value: SubmissionType.Suggestion },
    { name: 'supportTicket.complaint', value: SubmissionType.Complaint }
  ];
  catergoryList: Lookup[] = [];
  catergoryListLoading = false;
  // Attachments
  DeletedOtherAttachments: number[] = [];
  attachments = [];
  allowedExt = ['jpeg', 'jpg', 'png', 'pdf'];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<SupportTicketFormComponent>,
    private formBuilder: FormBuilder,
    private service: SupportTicketService,
    private auth: AuthenticationService,
    public dialog: MatDialog,
    private translate: TranslateService
  ) {
    this.currentUser = this.auth.currentUserValue;
  }

  ngOnInit(): void {
    this.buildForm();
    this.getRoles();
    this.getSupportTicketCategory();
    if (this.currentUser) {
      this.getPersonalData();
    }
  }

  buildForm() {
    this.formGroup = this.formBuilder.group({
      isAnonymous: [false],
      name: [null, [CustomValidator.noWhiteSpace, Validators.maxLength(50)]],
      email: [
        null,
        [
          Validators.required,
          Validators.email,
          Validators.pattern(
            '[a-zA-Z0-9._-]{1,}@[a-zA-Z0-9.-]{2,}[.]{1}[a-zA-Z]{2,}'
          ),
        ],
      ],
      role: [null, [Validators.required]],
      submissionType: [SubmissionType.Suggestion],
      supportRequestCategoryId: [null, [Validators.required]],
      description: [null, [Validators.maxLength(500)]],
    });
  }

  getPersonalData() {
    this.dataLoading = true;
    this.service.getPersonalData().subscribe(
      (data: PersonalData) => {
        this.dataLoading = false;
        if (data) {
          this.bindDataToUpdate(data);
        }
      },
      error => {
        this.dataLoading = false;
      }
    );
  }

  bindDataToUpdate(record: PersonalData) {
    this.formGroup.controls.name.setValue(record.name);
    this.formGroup.controls.email.setValue(record.email);
    this.formGroup.controls.role.setValue(record.role);
  }

  getRoles() {
    this.roleListLoading = true;
    this.service.getRoles().subscribe(
      data => {
        this.roleList = data as [];
        this.roleListLoading = false;
      }, err => {
        console.error(err);
        this.roleListLoading = false;
      });
  }

  getSupportTicketCategory() {
    this.catergoryListLoading = true;
    this.service.getSupportTicketCategory().subscribe(
      data => {
        this.catergoryList = data as [];
        this.catergoryListLoading = false;
      }, err => {
        console.error(err);
        this.catergoryListLoading = false;
      });
  }

  changeAnonymousToggle(e: MatSlideToggleChange) {
    if (e && e.checked) {
      this.formGroup.controls.name.clearValidators();
      this.formGroup.controls.email.clearValidators();
      this.formGroup.controls.role.clearValidators();
    } else {
      this.formGroup.controls.name.setValidators([CustomValidator.noWhiteSpace, Validators.maxLength(50)]);
      this.formGroup.controls.role.setValidators([Validators.required, Validators.email, Validators.pattern('[a-zA-Z0-9._-]{1,}@[a-zA-Z0-9.-]{2,}[.]{1}[a-zA-Z]{2,}')]);
      this.formGroup.controls.role.setValidators([Validators.required]);
    }
    this.formGroup.controls.name.updateValueAndValidity();
  }

  saveRecord() {
    this.isSubmitted = true;
    if (this.formGroup.valid) {
      this.loading = true;
      const controls = this.formGroup.controls;
      const formData = new FormData();
      let data = {
        IsAnonymous: controls.isAnonymous.value,
        Name: controls.isAnonymous.value ? null : controls.name.value,
        Email: controls.isAnonymous.value ? null : controls.email.value,
        Role: controls.isAnonymous.value ? null : controls.role.value,
        SubmissionType: controls.submissionType.value,
        SupportRequestCategoryId: controls.supportRequestCategoryId.value,
        Description: controls.description.value
      } as SupportTicketForm;
      if (this.attachments && this.attachments.length) {
        this.attachments.forEach(file => {
          if (!file.id) {
            formData.append('files', file, file.name);
          }
        });
      }

      formData.append('data', JSON.stringify(data));

      this.service.submitTicket(formData, !!this.currentUser).subscribe(response => {
        this.loading = false;
        this.dialogRef.close(true);
        this.confirmation(controls.isAnonymous.value, response.InsertedValue);
      }, err => {
        this.loading = false;
        this.handleErrors(err.detail);
      });
    }
  }

  confirmation(isAnonymous: boolean, id) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '750px',
      data: {
        img: 'assets/images/icons/done_illustration.svg',
        title: this.translate.instant('supportTicket.suggestionSubmitted'),
        message: isAnonymous ? this.translate.instant('supportTicket.referenceNumberMessage', { id }) : '',
        btnTitle: this.translate.instant("trainee.done"),
        btnClass: 'larg-btn',
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {

      }
    });
  }

  handleErrors(errors) {
    errors = JSON.parse(errors);
    errors.ErrorDTO.forEach(error => {
      if (error.ErrorCode === 'Repeated') {
        this.formGroup.controls[error.ErrorDetail].setErrors({ notUniqe: true });
      }
    });
  }

  hasError(controlName: string, errorName: string) {
    return this.formGroup.controls[controlName].hasError(errorName);
  }
}
