<div mat-dialog-title>
  <h2 class="d-block">
    SLAs
  </h2>
  <button type="button" class="close" mat-dialog-close aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<mat-dialog-content class="assign-dialog-content multiple_slides" *ngIf="slaList.length" infiniteScroll
  [scrollWindow]="false" [infiniteScrollDistance]="2" [infiniteScrollThrottle]="pageCount" (scrolled)="getNextPage()">
  <div class="page">
    <div class="page-filters">
      <div class="sort-item">
        <span class="count-page">
          <span>{{recordsData.slAsCount}}</span> SLAs
        </span>
      </div>

      <table class="table table-primary table_old_slas px-0">
        <tbody>
          <ng-container *ngFor="let record of slaList; let i = index">
            <tr>
              <td>
                <div class="container_duration_periods">
                  <span class="span_index"># <span class="number_index">{{i + 1}}</span></span>
                  <ul>
                    <li>
                      From: <span class="date_time">{{record.startDate | date:'d MMM, y'}}</span>
                    </li>
                    <li>
                      To: <span class="date_time">{{record.endDate | date:'d MMM, y'}}</span>
                    </li>
                  </ul>
                </div>
              </td>
              <td>
                <app-sla-status [status]="record.slaStatus"></app-sla-status>
              </td>
              <td>
                <a mat-dialog-close
                  [routerLink]="['/admin/sla/'+this.data.entityType + '/preview-sla/' + record.slaId +'/'+ this.data.isProfile]"
                  mat-button color="primary" class="btn_link_with_icon p-0">
                  View SLA
                  <i class="material-icons">
                    launch
                  </i>
                </a>
              </td>
            </tr>
          </ng-container>

        </tbody>

      </table>
    </div>
  </div>
</mat-dialog-content>

<div *ngIf='dataLoading' class="dataLoading">
  <mat-spinner class="m-auto" diameter="50"></mat-spinner>
</div>

<ng-template #dataEmpty>
  <app-empty-state [title]='"general.noDataFound"|translate'></app-empty-state>
</ng-template>