<div [formGroup]="filterFormGroup">
  <mat-form-field class="field_with_bg">
    <mat-label>{{fieldLabel}}</mat-label>
    <mat-select disableOptionCentering [formControlName]="controlName" [multiple]="multiple"
      (openedChange)="openEvent($event)" #doctorSelect>
      <div class="select_search_group">
        <div class="select_search_group_inner">
          <input matInput #searchInput [formControl]="searchControl" class="select_search_field form-control"
            placeholder="{{'courses.search' | translate}}" (keyup.enter)="applyFilter(searchInput.value)"
            (keydown)="$event.stopPropagation()">
          <button mat-icon-button (click)="clearFilter()" *ngIf="searchInput.value">
            <mat-icon>clear</mat-icon>
          </button>
          <!-- <button mat-icon-button (click)="applyFilter(searchInput.value)">
            <mat-icon>search</mat-icon>
          </button> -->
        </div>
      </div>
      <mat-select-trigger>
        <ng-container *ngIf="multiple">
          <span *ngFor="let item of filterFormGroup.get(controlName).value">
            {{item[keyTitle]}}
          </span>
        </ng-container>
        <ng-container *ngIf="!multiple && filterFormGroup.get(controlName).value">
          {{filterFormGroup.get(controlName).value[keyTitle]}}
        </ng-container>
      </mat-select-trigger>
      <div *ngFor="let item of sourcData">
        <mat-option [value]="item" [hidden]="item.hidden"
          [matTooltip]="item?.subSpeciality ? item?.subSpeciality : item[keyTitle]" matTooltipClass="wrap-tooltip">
          <span>{{item[keyTitle]}}</span>
        </mat-option>
      </div>
      <div *ngIf='dataLoading' class="dataLoading py-2">
        <mat-spinner class="m-auto" diameter="20"></mat-spinner>
      </div>
    </mat-select>
    <mat-error *ngIf="hasError(controlName, 'required')">
      {{'forms.Required' | translate}}
    </mat-error>
  </mat-form-field>
</div>