<div class="details_module assignment">
  <div class="sidenav-user-toolbar">
    <p>
      {{'search.SearchResultsFor' | translate}}
    </p>
    <h4>
      {{selectedText}}
    </h4>
  </div>
  <div class="sidenav-user-container">
    <div *ngIf='dataLoading' class="dataLoading">
      <mat-spinner class="m-auto" diameter="50"></mat-spinner>
    </div>
    <ng-container *ngIf='!dataLoading'>
      <div *ngIf="recordsData && recordsData.pageData.length; else dataEmpty">
        <div class="page">
          <div class="sidenav-assignment-content">
            <p>
              {{recordsData.resultsCount}} {{"search.resultsFound" | translate}}
            </p>
            <div class="search-card" *ngFor="let item of recordsData.pageData" [class.link-visited]="isLinkVisited(item.lessonId)">
              <p class="sub-title">{{item.courseTitle}}</p>
              <a class="title" href="javascript:void(0)" (click)="selectedLesson.emit(item.lessonId)" [innerHTML]="item.lessonTitle | sanitizeHtml"></a>
              <p class="text" [innerHTML]="item.lessonDescription | sanitizeHtml"></p>
              <div class="attchment details-items pt-0 pb-2" *ngIf="item.attachments && item.attachments.length">
                <app-files-viewer [enableDownload]="true" [files]="item.attachments"></app-files-viewer>
              </div>
              <label class="label">
                <ng-container *ngIf="item.lessonType == LevelType.Exam">{{"search.Exam"|translate}}</ng-container>
                <ng-container *ngIf="item.lessonType == LevelType.Reading">{{"trainee.reading"|translate}}</ng-container>
                <ng-container *ngIf="item.lessonType ==  LevelType.Video">{{"trainee.video"|translate}} </ng-container>
                <ng-container *ngIf="item.lessonType == LevelType.Quiz">{{"trainee.quiz"|translate}}</ng-container>
                <ng-container *ngIf="item.lessonType == LevelType.Assignment">{{"assignment.Assignment"|translate}}</ng-container>
                <ng-container *ngIf="item.lessonType == LevelType.LiveSession">{{"assignment.LiveSession"|translate}}</ng-container>
              </label>
              <label class="mx-2 label">{{'search.LastUpdate' | translate}}: <strong>{{item.lastUpdateDate | date:'d MMM, y hh:mm a'}}</strong></label>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-template #dataEmpty>
      <app-empty-state [img]='"./assets/images/viewer-icons/no-remarks-illustration.svg"'
        [title]='"general.noDataFound"|translate'>
      </app-empty-state>
    </ng-template>
  </div>
</div>
