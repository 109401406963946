import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeZoneUtc'
})
export class TimeZoneUtcPipe implements PipeTransform {


  constructor(private datePipe: DatePipe) {}

  transform(value: string, format: string = 'h:mm a'): string {
    if (!value) {
      return '';
    }

    // Convert the date using the provided value and ensure it's treated as UTC
    const date = new Date(new Date(value).toLocaleString() + ' UTC').toString();

    // Use Angular DatePipe to format the date
    return this.datePipe.transform(date, format) || '';
  }
}
