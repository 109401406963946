<div mat-dialog-title>
  <h2>{{'certificate.contactSupport' | translate}}</h2>
  <button type="button" class="close" mat-dialog-close aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<mat-dialog-content>
  <div class="mat-dialog-body">
    <div *ngIf='dataLoading' class="dataLoading">
      <mat-spinner class="m-auto" diameter="50"></mat-spinner>
    </div>
    <form [formGroup]="formGroup" [hidden]="dataLoading">

      <div class="fields-group">
        <h3 class="fields-title">{{'supportTicket.yourMainInfo' | translate}}</h3>
        <mat-slide-toggle class="custom_slide_toggle_field" formControlName="isAnonymous" [labelPosition]="'before'"
          (change)="changeAnonymousToggle($event)">
          <strong>{{'supportTicket.sendAnonymous' | translate}}</strong>
          <i>
            {{'supportTicket.anonymousFeatureInfo' | translate}}
          </i>
        </mat-slide-toggle>
      </div>
      <div class="mat-dialog-inner" [hidden]="formGroup.controls.isAnonymous.value">
        <mat-form-field>
          <mat-label>{{'forms.Name' | translate}}</mat-label>
          <input matInput formControlName="name" maxlength="250">
          <mat-error *ngIf="hasError('name', 'required')">{{ 'forms.Required' | translate }}</mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label>{{"general.email"|translate}}</mat-label>
          <input matInput formControlName="email" maxlength="50">
          <mat-error *ngIf="hasError('email', 'required')">{{ 'forms.Required' | translate }}</mat-error>
          <mat-error *ngIf="hasError('email', 'invalid')">{{ 'forms.Invalid-Email' | translate }}</mat-error>
          <mat-error *ngIf="hasError('email', 'pattern')">{{ 'forms.Invalid-Email' | translate }}</mat-error>
        </mat-form-field>
        <mat-form-field [class.loading-field]='roleListLoading'>
          <mat-label>{{'userManagment.Role' | translate}}</mat-label>
          <mat-select disableOptionCentering formControlName="role" [disabled]="roleListLoading">
            <mat-option [value]="item.code" *ngFor="let item of roleList">
              <span>{{item.name}}</span>
            </mat-option>
          </mat-select>
          <mat-error *ngIf="hasError('role', 'required')">
            {{ 'forms.Required' | translate }}
          </mat-error>
        </mat-form-field>
      </div>

      <div class="fields-group">
        <h3 class="fields-title">{{'supportTicket.submissionType' | translate}}</h3>
        <div class="form-group">
          <div class="radio-selector">
            <mat-radio-group formControlName="submissionType" class="row">
              <mat-radio-button *ngFor="let item of submissionTypeList" class="col-6" [value]="item.value">
                {{item.name | translate}}
              </mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
      </div>

      <div class="fields-group">
        <h3 class="fields-title">{{'supportTicket.issueDetails' | translate}}</h3>
        <mat-form-field [class.loading-field]='catergoryListLoading'>
          <mat-label>{{'supportTicket.selectCategory' | translate}}</mat-label>
          <mat-select disableOptionCentering formControlName="supportRequestCategoryId"
            [disabled]="catergoryListLoading">
            <mat-option [value]="item.id" *ngFor="let item of catergoryList">
              <span>{{item.name}}</span>
            </mat-option>
          </mat-select>
          <mat-error *ngIf="hasError('supportRequestCategoryId', 'required')">
            {{ 'forms.Required' | translate }}
          </mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label>{{'reportsBuilder.description' | translate}}</mat-label>
          <textarea matInput formControlName="description" maxlength="500"></textarea>
          <mat-error *ngIf="hasError('description', 'maxLength')">
            {{"forms.maxLengthIsCharacters"|translate:{num:500} }}
          </mat-error>
        </mat-form-field>
      </div>

      <div class="fields-group">
        <h3 class="fields-title">
          {{'forms.Attachments' | translate}} <span>({{'forms.Optional' | translate}})</span>
        </h3>
        <div class="fields-group">
          <app-file-manager [isRequired]="false" [managerIndex]="'attachements'" [isSubmitted]="isSubmitted"
            [allowedExt]="allowedExt" [maxSize]="5" [label]="'slideDetails.dragAndDropFilesHere'|translate"
            [files]="attachments" (selectedFiles)="attachments = $event"
            (deletedFilesList)="DeletedOtherAttachments = $event">
          </app-file-manager>
        </div>
      </div>

      <button class="w-100" mat-raised-button color="primary" [disabled]="loading" [class.loading]='loading'
        (click)="saveRecord()">
        {{ 'forms.Submit' |translate }}
      </button>
    </form>
  </div>
</mat-dialog-content>