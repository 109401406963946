import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-pdf-viewer',
  templateUrl: './pdf-viewer.component.html'
})
export class PdfViewerComponent implements OnInit {
  @Input() pdfUrl = null;
  pdfData: any;
  currentPage = 1;
  totalPages = 1;

  constructor(private sanitizer: DomSanitizer, private http: HttpClient) { }

  async ngOnInit() {

    this.pdfData = await this.getPDF();
    // console.log(this.pdfData)
    this.totalPages = this.getPdfTotalPages();
  }

  goToPage(): void {
    if (this.currentPage < 1) {
      this.currentPage = 1;
    } else if (this.currentPage > this.totalPages) {
      this.currentPage = this.totalPages;
    }
  }

  getPdfTotalPages(): number {
    // Implement logic to get the total number of pages from the PDF data
    return 10; // Replace with the actual total pages count
  }

  goToPreviousPage(): void {
    if (this.currentPage > 1) {
      this.currentPage--;
    }
  }

  goToNextPage(): void {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
    }
  }

  getPDF(): Promise<Blob> {
    return this.http.get(this.pdfUrl, { responseType: 'blob' })
      .toPromise();
  }

  getUrl() {
    this.sanitizer.bypassSecurityTrustResourceUrl(this.pdfData);
  }

}
