<ng-container *ngIf='record'>
  <div class="sidenav-user-toolbar justify-content-start">
    <button class="btn-back" mat-icon-button [matTooltip]="'back'" (click)="closeSidenavCaseLog()">
      <svg xmlns="http://www.w3.org/2000/svg" width="15.583" height="15.175" viewBox="0 0 15.583 15.175">
        <path id="Path_18837" data-name="Path 18837"
          d="M19,11H7.83l4.88-4.88a1.008,1.008,0,0,0,0-1.42,1,1,0,0,0-1.41,0L4.71,11.29a1,1,0,0,0,0,1.41l6.59,6.59a1,1,0,0,0,1.41-1.41L7.83,13H19a1,1,0,0,0,0-2Z"
          transform="translate(-4.417 -4.407)" fill="#555" />
      </svg>
    </button>
    <h4>
      {{'slideDetails.actionDetails'|translate}}
    </h4>
  </div>
  <div class="sidenav-user-container">
    <div class="profile_detail">
      <div class="item_timeline">
        <div class="timeline_head">
          <div class="state_log">
            <span [ngClass]="{'danger':
            (
            record.status === 'GROSSING' &&
            record.procedure === 'REJECT_GROSSING'
            ) ||
            (
              record.status === 'CASE_REVIEWED_SUCCESFULLY' &&
              record.procedure === 'WITH_DRAW'
            ) ||
            (
              record.status === 'CASE_REVIEWED_SUCCESFULLY' &&
              record.procedure === 'REJECTED'
            ) ||
            (
              record.status === 'MATCH_MAKING' &&
              record.procedure === 'ENDORSEMENT_REJECTED'
            ) ||
            (
              record.status === 'MATCH_MAKING' &&
              record.procedure === 'PATH_SENT_BACK'
            ) ||
            (
              record.status === 'ACCEPTED_PROCESS_ONLY' &&
              record.procedure === 'CASE_SENT_BACK'
            ) ||
            (
              record.status === 'ACTION_TAKEN_TECH' &&
              record.procedure === 'CASE_SENT_BACK'
            ) ||
            (
              record.status === 'CASE_HAS_APPROVED_REQUEST' &&
              record.procedure === 'CASE_SENT_BACK'
            ) ||
            (
              record.status === 'MATCH_MAKING' &&
              record.procedure === 'CASE_SENT_BACK'
            ) ||
            (
            record.status === 'MATCH_MAKING' &&
            record.procedure === 'WITH_DRAW'
            ) ||
            (
            record.status === 'MATCH_MAKING' &&
            record.procedure === 'ENDORSEMENT_WITHDRAW'
            ) ||
            (
            record.status === 'SUPER_SENT_BACK_FROM_OTHER' &&
            record.procedure === 'SUPER_SENT_BACK_FROM_OTHER'
            ) ||
            (
            record.status === 'CASE_HAS_REJECTED_REQUEST' &&
            record.procedure === 'CASE_HAS_REJECTED_REQUEST'
            ) ||
            (
              record.status === 'MATCH_MAKING' &&
              record.procedure === 'REJECTED'
            ) ||(
                record.status === 'SUPERVISOR_REJECT_CASE_DETAILS_CASE' &&
                record.procedure === 'SUPERVISOR_REJECT_CASE_DETAILS_CASE'
              ) ||
               record.status === 'SUPERVISOR_REJECT_UPLOADED_SLIDES' ||
               record.status === 'SUPERVISOR_REJECT_CASE_DETAILS' ||
               record.status === 'REJECTED' ||
               record.status === 'TECH_REF_SUPER_SENT_BACK' ||
               record.status === 'TECH_REF_SUPER_SENT_BACK_FROM_OTHER' ||
               record.status === 'SUPER_SENT_BACK'}"></span>
          </div>
          <p>
            {{ record.message }}
          </p>
        </div>
        <div class="timeline_inner">
          <div class="item_author">
            <img *ngIf="record.image" [src]="record.image" (error)='record.image = ""'>
            <span *ngIf="!record.image">
              {{ record.name | shortName }}
            </span>
            <div class="item_avatar_content">
              <span>
                {{
                (
                record.status === 'GROSSING' &&
                record.procedure === 'REJECT_GROSSING'
                ) ||

                (
                record.status === 'CASE_REVIEWED_SUCCESFULLY' &&
                record.procedure === 'REJECTED'
                ) ||
                (
                record.status === 'MATCH_MAKING' &&
                record.procedure === 'ENDORSEMENT_REJECTED'
                ) ||(
                record.status === 'MATCH_MAKING' &&
                record.procedure === 'REJECTED'
                ) ||(
                record.status === 'SUPERVISOR_REJECT_CASE_DETAILS_CASE' &&
                record.procedure === 'SUPERVISOR_REJECT_CASE_DETAILS_CASE'
                ) ||
                record.status === 'SUPERVISOR_REJECT_UPLOADED_SLIDES' ||
                record.status === 'SUPERVISOR_REJECT_CASE_DETAILS' ||
                record.status === 'REJECTED' ||
                record.status === 'TECH_REF_SUPER_SENT_BACK' ||
                record.status === 'TECH_REF_SUPER_SENT_BACK_FROM_OTHER' ||
                record.status === 'SUPER_SENT_BACK' ||
                record.status === 'CASE_HAS_REJECTED_REQUEST'?('slideDetails.rejectedBy'|translate):
                (
                record.status === 'CASE_REVIEWED_SUCCESFULLY' &&
                record.procedure === 'WITH_DRAW'
                ) ||
                (
                record.status === 'MATCH_MAKING' &&
                record.procedure === 'WITH_DRAW'
                ) ||
                (
                record.status === 'MATCH_MAKING' &&
                record.procedure === 'ENDORSEMENT_WITHDRAW'
                )?('slideDetails.withdrawnBy'|translate):('slideDetails.doneBy'|translate)}}
              </span>
              <h4>
                {{ record.name }}
              </h4>
            </div>
          </div>
          <div class="item_avatar_content">
            <span>
              {{
              (
              record.status === 'GROSSING' &&
              record.procedure === 'REJECT_GROSSING'
              ) ||

              (
              record.status === 'CASE_REVIEWED_SUCCESFULLY' &&
              record.procedure === 'REJECTED'
              ) ||
              (
              record.status === 'MATCH_MAKING' &&
              record.procedure === 'ENDORSEMENT_REJECTED'
              ) ||(
              record.status === 'MATCH_MAKING' &&
              record.procedure === 'REJECTED'
              ) ||(
              record.status === 'SUPERVISOR_REJECT_CASE_DETAILS_CASE' &&
              record.procedure === 'SUPERVISOR_REJECT_CASE_DETAILS_CASE'
              ) ||
              record.status === 'SUPERVISOR_REJECT_UPLOADED_SLIDES' ||
              record.status === 'SUPERVISOR_REJECT_CASE_DETAILS' ||
              record.status === 'REJECTED' ||
              record.status === 'TECH_REF_SUPER_SENT_BACK' ||
              record.status === 'TECH_REF_SUPER_SENT_BACK_FROM_OTHER' ||
              record.status === 'SUPER_SENT_BACK' ||
              record.status === 'CASE_HAS_REJECTED_REQUEST'?('slideDetails.rejectedOn'|translate):
              (
              record.status === 'CASE_REVIEWED_SUCCESFULLY' &&
              record.procedure === 'WITH_DRAW'
              ) ||
              (
              record.status === 'MATCH_MAKING' &&
              record.procedure === 'WITH_DRAW'
              ) ||
              (
              record.status === 'MATCH_MAKING' &&
              record.procedure === 'ENDORSEMENT_WITHDRAW'
              )?('slideDetails.withdrawnOn'|translate):('slideDetails.submittedOn'|translate)}}
            </span>
            <h4>
              {{record.creationDate | date:'d MMM, y, h:mm a'}}
            </h4>
          </div>
        </div>
        <div class="timeline_content" *ngIf="record.comment">
          <h4>{{'slideDetails.reasonsNotes'|translate}}</h4>
          <p>
            {{record.comment}}
          </p>
        </div>
      </div>
    </div>
  </div>
</ng-container>