import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { NotificationPreference } from 'src/app/models/notification/NotificationPreference';
import { NotificationsService } from 'src/app/services/notifications.service';
import { SwalService } from 'src/app/services/swal.service';

@Component({
  selector: 'app-notification-preference',
  templateUrl: './notification-preference.component.html'
})
export class NotificationPreferenceComponent implements OnInit {
  formGroup: FormGroup;
  dataLoading = false;
  loading = false;
  isSubmitted = false;
  details: NotificationPreference = new NotificationPreference();

  constructor(
    private swalService: SwalService,
    private formBuilder: FormBuilder,
    private service: NotificationsService,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    this.buildForm();
    this.getDetails();
  }

  buildForm() {
    this.formGroup = this.formBuilder.group({
      TaskReminder: [false],
      EventNotifications: [false],
      CourseUpdates: [false],
      FeedbackAndGrades: [false],
      ImportantAnnouncements: [false],
      AllowNotificationsDelivery: [false],
      AllowEmailDelivery: [false]
    });
  }

  getDetails() {
    this.dataLoading = true;
    this.service.getNotificationSettings().subscribe(
      data => {
        if (data) {
          this.details = data as NotificationPreference;
          this.bindDataToUpdate();
        }
      },
      error => {
        this.dataLoading = false;
      });
  }

  saveRecord() {
    this.isSubmitted = true;
    this.formGroup.markAllAsTouched();
    if (this.formGroup.valid) {
      this.loading = true;
      this.service.updateNotificationSettings(this.formGroup.value).subscribe(
        (response: any) => {
          this.loading = false;
          this.swalService.swalSuccess(this.translate.instant('swalMsg.updatedsuccessfully'));
          this.getDetails();
        }, err => {
          this.loading = false;
          this.handleErrors(err.detail);
          this.formGroup.markAllAsTouched();
        });
    } else {
      this.formGroup.markAllAsTouched();
    }
  }

  bindDataToUpdate() {
    this.formGroup.controls.TaskReminder.setValue(this.details.taskReminder);
    this.formGroup.controls.EventNotifications.setValue(this.details.eventNotifications);
    this.formGroup.controls.CourseUpdates.setValue(this.details.courseUpdates);
    this.formGroup.controls.FeedbackAndGrades.setValue(this.details.feedbackAndGrades);
    this.formGroup.controls.ImportantAnnouncements.setValue(this.details.importantAnnouncements);
    this.formGroup.controls.AllowNotificationsDelivery.setValue(this.details.allowNotificationsDelivery);
    this.formGroup.controls.AllowEmailDelivery.setValue(this.details.allowEmailDelivery);
  }

  handleErrors(errors) {
    errors = JSON.parse(errors);
    errors.ErrorDTO.forEach(error => {
      this.swalService.swalFailed(error.Error);
    });
  }
}
