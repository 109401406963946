<div class="sidenav-user-toolbar">
  <h4>
    {{(type && type === 'request'?'slideDetails.requestsLog':'slideDetails.casesLog')|translate}} 
  </h4>
</div>
<div class="sidenav-user-container">
  <div class="profile_detail mt-3">
    <div class="page">

      <ng-container *ngIf='recordsData; else dataEmpty'>
        <ul class="list_timeLine" *ngIf="recordsData.length; else dataEmpty">
          <li class="item_branch" *ngFor="let record of recordsData" (click)="showCaseLog(record)">
            <div class="state_log">
              <span [ngClass]="{'danger':
              (
                record.status === 'GROSSING' &&
                record.procedure === 'REJECT_GROSSING'
              ) ||
              (
                record.status === 'CASE_REVIEWED_SUCCESFULLY' &&
                record.procedure === 'WITH_DRAW'
              ) ||
              (
                record.status === 'CASE_REVIEWED_SUCCESFULLY' &&
                record.procedure === 'REJECTED'
              ) ||
              (
                record.status === 'MATCH_MAKING' &&
                record.procedure === 'ENDORSEMENT_REJECTED'
              ) ||
              (
                record.status === 'MATCH_MAKING' &&
                record.procedure === 'PATH_SENT_BACK'
              ) ||
              (
                record.status === 'ACCEPTED_PROCESS_ONLY' &&
                record.procedure === 'CASE_SENT_BACK'
              ) ||
              (
                record.status === 'ACTION_TAKEN_TECH' &&
                record.procedure === 'CASE_SENT_BACK'
              ) ||
              (
                record.status === 'CASE_HAS_APPROVED_REQUEST' &&
                record.procedure === 'CASE_SENT_BACK'
              ) ||
              (
                record.status === 'MATCH_MAKING' &&
                record.procedure === 'CASE_SENT_BACK'
              ) ||
              (
              record.status === 'MATCH_MAKING' &&
              record.procedure === 'WITH_DRAW'
              ) ||
              (
                record.status === 'MATCH_MAKING' &&
                record.procedure === 'ENDORSEMENT_WITHDRAW'
              ) ||
              (
                record.status === 'MATCH_MAKING' &&
                record.procedure === 'REJECTED'
              ) ||
              (
                record.status === 'SUPER_SENT_BACK_FROM_OTHER' &&
                record.procedure === 'SUPER_SENT_BACK_FROM_OTHER'
              ) ||
              (
                record.status === 'SUPERVISOR_REJECT_CASE_DETAILS_CASE' &&
                record.procedure === 'SUPERVISOR_REJECT_CASE_DETAILS_CASE'
              ) ||
              (
                record.status === 'CASE_HAS_REJECTED_REQUEST' &&
                record.procedure === 'CASE_HAS_REJECTED_REQUEST'
              ) ||
                record.status === 'SUPERVISOR_REJECT_UPLOADED_SLIDES' ||
                record.status === 'SUPERVISOR_REJECT_CASE_DETAILS' ||
                record.status === 'REJECTED' ||
                record.status === 'TECH_REF_SUPER_SENT_BACK' ||
                record.status === 'TECH_REF_SUPER_SENT_BACK_FROM_OTHER' ||
                record.status === 'SUPER_SENT_BACK'}"></span>
            </div>
            <div class="item_inner">
              <div class="created_date">
                {{record.creationDate | date:'d MMM, y, h:mm a'}}
              </div>
              <p>
                {{record.message}}
              </p>
              <div class="item_author">
                <img *ngIf="record.image" [src]="record.image" (error)='record.image = ""'>
                <span *ngIf="!record.image">
                  {{ record.name | shortName }}
                </span>
                <div class="item_avatar_content">
                  <span>
                    {{ (
                    record.status === 'GROSSING' &&
                    record.procedure === 'REJECT_GROSSING'
                    ) ||
                    (
                    record.status === 'CASE_REVIEWED_SUCCESFULLY' &&
                    record.procedure === 'WITH_DRAW'
                    ) ||
                    (
                    record.status === 'CASE_REVIEWED_SUCCESFULLY' &&
                    record.procedure === 'REJECTED'
                    ) ||
                    (
                    record.status === 'MATCH_MAKING' &&
                    record.procedure === 'ENDORSEMENT_REJECTED'
                    ) ||(
                    record.status === 'MATCH_MAKING' &&
                    record.procedure === 'REJECTED'
                    ) ||(
                    record.status === 'SUPERVISOR_REJECT_CASE_DETAILS_CASE' &&
                    record.procedure === 'SUPERVISOR_REJECT_CASE_DETAILS_CASE'
                    ) ||(
                    record.status === 'CASE_HAS_REJECTED_REQUEST' &&
                    record.procedure === 'CASE_HAS_REJECTED_REQUEST'
                    ) ||
                    record.status === 'SUPERVISOR_REJECT_UPLOADED_SLIDES' ||
                    record.status === 'SUPERVISOR_REJECT_CASE_DETAILS' ||
                    record.status === 'REJECTED' ||
                    record.status === 'TECH_REF_SUPER_SENT_BACK' ||
                    record.status === 'TECH_REF_SUPER_SENT_BACK_FROM_OTHER' ||
                    record.status === 'SUPER_SENT_BACK'?('slideDetails.rejectedBy'|translate):
                    (
                    record.status === 'MATCH_MAKING' &&
                    record.procedure === 'WITH_DRAW'
                    ) ||
                    (
                    record.status === 'MATCH_MAKING' &&
                    record.procedure === 'ENDORSEMENT_WITHDRAW'
                    )?('slideDetails.withdrawnBy'|translate):('slideDetails.doneBy'|translate)}}
                  </span>
                  <h4>
                    {{record.name}}
                  </h4>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </ng-container>
      <ng-template #dataEmpty>
        <app-empty-state [img]='"./assets/images/viewer-icons/no-remarks-illustration.svg"' [title]='"general.noDataFound"|translate'>
        </app-empty-state>
      </ng-template>
    </div>
  </div>
</div>


<app-sidenav-over *ngIf="openCaseLog" [open]="openCaseLog" (closeDetails)="closeAll($event)" custClass="hideSideOver"
  [hideScroll]="true">
  <app-case-log-details *ngIf="selectedRecord" [record]="selectedRecord" (closeDetails)="closeSidenavCaseLog($event)"
    [hideScroll]="true">
  </app-case-log-details>
</app-sidenav-over>