<div mat-dialog-title>
  <h2>{{( recordId ? 'slideDetails.updateSlide' : 'slideDetails.addSlide')|translate }} </h2>
  <button type="button" class="close" mat-dialog-close aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<mat-dialog-content class="slide-dialog-content">
  <div *ngIf='dataLoadingStain' class="dataLoading">
    <mat-spinner class="m-auto" diameter="50"></mat-spinner>
  </div>

  <ng-container *ngIf='!dataLoadingStain'>
    <!-- <div class="mat-dialog-inner bg-light mat-dialog-body">
      <div class="table-responsive">
        <table class="table table-primary">
          <thead>
            <tr>
              <th>Case</th>
              <th class="text-right">Slide No.</th>
            </tr>
          </thead>
          <tbody>
            <tr class="active">
              <td>
                <div class="td-inner">
                  <div class="td-content">
                    <h5 class="code text-theme">
                      {{data.caseCode}}
                    </h5>
                  </div>
                  <div class="td-body">
                    <h5>
                      {{data.patientName}}
                    </h5>
                  </div>
                </div>
              </td>
              <td class="text-right">
                <b>#{{data.slideNumber}}</b>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div> -->
    <div class="mat-dialog-body">
      <form [formGroup]="formGroup">
        <mat-form-field hideRequiredMarker>
          <mat-label>{{"slideDetails.selectStainType"|translate}}</mat-label>
          <mat-select disableOptionCentering formControlName="stainType">
            <mat-select-trigger>
              <div class="d-flex justify-content-between align-items-center">
                <span *ngIf="formGroup.controls.stainType.value">{{formGroup.controls.stainType.value?.name}}</span>
                <span *ngIf="formGroup.controls.stainType.value && formGroup.controls.stainType.value?.isSpecial"
                  class="badge-status purple-white">
                  {{"slideDetails.special"|translate}}
                </span>
              </div>
            </mat-select-trigger>
            <mat-option [value]="item" *ngFor="let item of stainList" [matTooltip]="item.description">
              <div class="d-flex justify-content-between align-items-center">
                <span>{{item.name}}</span>
                <span class="badge-status purple-white" *ngIf="item.isSpecial">
                  {{"slideDetails.special"|translate}}
                </span>
              </div>
            </mat-option>
          </mat-select>
          <mat-error *ngIf="hasError('stainType', 'required')">
            {{ 'forms.Required' | translate }}
          </mat-error>
        </mat-form-field>

        <div class="fields-group" *ngIf="!antiBodyList.length">
          <mat-form-field>
            <mat-label>{{"slideDetails.antibody"|translate}}</mat-label>
            <input matInput formControlName="antibody" maxlength="10">
            <mat-error *ngIf="hasError('antibody', 'maxLength')">
              {{"forms.maxLengthIsCharacters"|translate:{num:10} }}
            </mat-error>
            <mat-hint align="end">
              {{"forms.maxLengthIsCharacters"|translate:{num:10} }}
            </mat-hint>
          </mat-form-field>
        </div>

        <div class="fields-group" *ngIf="antiBodyList.length">
          <mat-form-field>
            <mat-label>{{"slideDetails.antibody"|translate}}</mat-label>
            <mat-select formControlName="antibodyList" placeholder="">
              <mat-option></mat-option>
              <mat-option *ngFor="let item of antiBodyList" [value]="item.id">
                {{item.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="fields-group" [hidden]="data.forGrossing || true">
          <mat-form-field>
            <mat-label>{{"slideDetails.slideSpecimenNumber"|translate}}</mat-label>
            <input matInput formControlName="slideSpecimenNumber" maxlength="10">
            <mat-error *ngIf="hasError('slideSpecimenNumber', 'maxLength')">
              {{"forms.maxLengthIsCharacters"|translate:{num:10} }}
            </mat-error>
            <mat-hint align="end">
              {{"forms.maxLengthIsCharacters"|translate:{num:10} }}
            </mat-hint>
          </mat-form-field>
        </div>
        <div class="fields-group" [hidden]="!data.forGrossing">
          <mat-form-field hideRequiredMarker>
            <mat-label>{{"slideDetails.selectContainerID"|translate}}</mat-label>
            <mat-select disableOptionCentering formControlName="ContainerId" [required]="data.forGrossing">
              <mat-option [value]="item.containerId" *ngFor="let item of containerList">
                <span>{{item.containerValue}}</span>
              </mat-option>
            </mat-select>
            <mat-error *ngIf="hasError('ContainerId', 'required')">
              {{ 'forms.Required' | translate }}
            </mat-error>
          </mat-form-field>
        </div>

        <div class="fields-group" hidden>
          <div class="mb-3">
            <mat-slide-toggle formControlName="IsDesktopWSI" [labelPosition]="'before'"
              (change)="changeSlideName($event)">
              <i>{{"slideDetails.uploadedByDesktop"|translate}}</i>
            </mat-slide-toggle>
          </div>
          <mat-form-field>
            <mat-label>{{"slideDetails.slideName"|translate}}</mat-label>
            <input matInput formControlName="slideName" [required]="formGroup.controls.IsDesktopWSI.value"
              maxlength="50" (input)="changeSlideName()">
            <mat-error *ngIf="hasError('slideName', 'maxLength')">
              {{"forms.maxLengthIsCharacters"|translate:{num:50} }}
            </mat-error>
            <mat-error *ngIf="hasError('slideName', 'required')">
              {{ 'forms.Required' | translate }}
            </mat-error>
            <mat-hint align="end">
              {{"forms.maxLengthIsCharacters"|translate:{num:50} }}
            </mat-hint>
          </mat-form-field>
        </div>
        <div class="fields-group" [ngClass]="{'overlay_disabled':formGroup.controls.IsDesktopWSI.value}">
          <h3 class="sub-title mb-1 text-semiBold">
            {{"slideDetails.scannedSlide"|translate}}
          </h3>
          <p class="sub-title mb-3">
            {{"slideDetails.selectHowToAddSlide"|translate}}
          </p>

          <div class="form-group">
            <div class="radio-selector">
              <mat-radio-group formControlName="communicationMethod" class="row" (change)="getSelectedSlides()">
                <mat-radio-button [ngClass]="data.communicationMethods.length == 3 ? 'col-4' : 'col-6'"
                  [value]="'NETWORK'" *ngIf="isInArray(communicationMethods,'NETWORK')">
                  Network
                </mat-radio-button>
                <mat-radio-button [ngClass]="data.communicationMethods.length == 3 ? 'col-4' : 'col-6'" [value]="'FTP'"
                  *ngIf="isInArray(communicationMethods,'FTP')">
                  FTP
                </mat-radio-button>
                <mat-radio-button [ngClass]="data.communicationMethods.length == 3 ? 'col-4' : 'col-6'"
                  [value]="'LOCAL'" *ngIf="isInArray(communicationMethods,'LOCAL')">
                  Local
                </mat-radio-button>
                <mat-radio-button [ngClass]="data.communicationMethods.length == 3 ? 'col-4' : 'col-6'" [value]="'CEPH'"
                  *ngIf="isInArray(communicationMethods,'CEPH')">
                  Ceph
                </mat-radio-button>
              </mat-radio-group>
            </div>
          </div>

          <mat-form-field hideRequiredMarker [hidden]='formGroup.controls.communicationMethod.value === "LOCAL"'
            [class.loading-field]='dataLoading'>
            <mat-label>{{"slideDetails.selectSlide"|translate}}</mat-label>
            <mat-select disableOptionCentering formControlName="slide"
              [required]="formGroup.controls.communicationMethod.value !== 'LOCAL' && !formGroup.controls.IsDesktopWSI.value"
              [disabled]='dataLoading'>
              <mat-option [value]="item" *ngFor="let item of slidesList">
                <span>{{item}}</span>
              </mat-option>
            </mat-select>
            <mat-error *ngIf="hasError('slide', 'required')">
              {{ 'forms.Required' | translate }}
            </mat-error>
          </mat-form-field>
          <ng-container *ngIf="formGroup.controls.communicationMethod.value === 'LOCAL'">
            <p class="sub-title mb-2">
              {{"slideDetails.uploadWSISlideFile"|translate}}
            </p>
            <div class="fields-group">
              <app-file-manager
                [isRequired]="formGroup.controls.communicationMethod.value === 'LOCAL' && !formGroup.controls.IsDesktopWSI.value"
                [managerIndex]="'slide'" [isSubmitted]="isSubmitted"
                [allowedExt]="['svs', 'tif', 'ndpi', 'scn', 'mrxs', 'svslide', 'bif']" [maxSize]="100" [maxFiles]="1"
                [label]="'slideDetails.dragAndDropFilesHere'|translate" [files]="slides"
                (selectedFiles)="slides = $event">
              </app-file-manager>

              <div class="progress custon_progress progress_md mt-2" *ngIf="progress > 0">
                <div class="progress-bar" role="progressbar" [attr.ariavaluenow]="progress" aria-valuemin="0"
                  aria-valuemax="100" [style]="'width:' + progress +'%'">{{progress}}%</div>
              </div>
            </div>
          </ng-container>

        </div>

        <!-- <div class="fields-group" *ngIf="progress">
          <mat-progress-bar mode="buffer"></mat-progress-bar>
        </div> -->

        <button class="w-100" mat-raised-button color="primary" [disabled]="loading || progress > 0"
          [class.loading]='loading || progress > 0' (click)="saveRecord()">
          {{ (recordId ? 'slideDetails.updateSlide' : 'slideDetails.addSlide')|translate }}
        </button>
      </form>
    </div>
  </ng-container>
</mat-dialog-content>