import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-image-guidelines',
  templateUrl: './image-guidelines.component.html',
})
export class ImageGuidelinesComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<ImageGuidelinesComponent>) {}

  ngOnInit(): void {}
}
