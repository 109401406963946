import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Details } from 'src/app/models/cases/Details';
import { CaseType } from 'src/app/models/cases/enums/CaseType';
import { ListData, PagedData } from 'src/app/models/slides/ListData';
import { ListFilter } from 'src/app/models/slides/ListFilter';
import { RolesEnum } from 'src/app/models/users/roles/enums/roles';
import { ViewerService } from 'src/app/services/Viewer.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ConcurelService } from 'src/app/services/concurel.service';
import { SlidesService } from 'src/app/services/slides.service';

export interface DialogData {
  caseId: number;
  case: Details;
  entityId: number;
  pathologistId: number;
  requestId: number;
  pathologistType: string;
  IsEndorsement: string;
  caseIdType: CaseType;
  isShare: boolean;
  notAuthenticated: boolean;
  conCuralUserName: string;
  conCuralPassword: string;
  isCompare: boolean;
  slideIds: number[];
  slideId: number;
  isOtherStain: boolean;
}

@Component({
  selector: 'app-viewer-selector',
  templateUrl: './viewer-selector.component.html',
})
export class ViewerSelectorComponent implements OnInit {
  selected = [];
  dataLoading = false;
  loading = false;
  // table
  pageNumber = 1;
  pageSize = 6;
  pageCount: number;
  recordsData: ListData = new ListData();
  filterModel: ListFilter = new ListFilter();
  slidesList: Array<PagedData> = [];
  idKey = 'slideId';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<ViewerSelectorComponent>,
    private auth: AuthenticationService,
    private activatedRoute: ActivatedRoute,
    private service: SlidesService,
    private viewerService: ViewerService,
    private concurelService: ConcurelService,
    private router: Router
  ) { }

  ngOnInit(): void {
    if (this.data && this.data.isCompare) {
      this.idKey = 'id';
    }
    if (this.data.slideIds.length) {
      this.selected = this.data.slideIds;
    }
    this.getAll(null);
  }

  selectedSlides(id, disabled) {
    const index = this.selected.findIndex((slideId) => Number(slideId) === Number(id));
    if (!disabled) {
      if (index > -1) {
        this.selected.splice(index, 1);
      } else {
        const item = this.slidesList.find((user) => Number(user[this.idKey]) === Number(id));
        this.selected.push(item[this.idKey]);
      }
    }
  }

  getAll(event) {
    this.dataLoading = true;
    if (event) {
      this.pageNumber = 1;
      this.slidesList = [];
    }
    this.filterModel.PageSize = this.pageSize;
    this.filterModel.PageNumber = this.pageNumber;
    if (this.data.isCompare) {
      this.filterModel.selectedSlides = this.selected.join(',');
    }
    if (this.data.slideId && this.data.isOtherStain) {
      this.filterModel.slideId = this.data.slideId;
    }
    let targetService = this.viewerService.getAllAssignedSlides(this.filterModel);
    if (!this.data.isCompare) {
      this.filterModel.IsMulti = true;
      this.filterModel.CaseId = this.data.entityId;
      this.filterModel.caseIdType = this.data.caseIdType;
      if (this.data.pathologistId) {
        this.filterModel.UserId = this.data.pathologistId;
        this.filterModel.UserType = this.data.pathologistType;
      }
      if (
        this.data.case &&
        this.data.case.sentBackDRequest &&
        ((this.hasPermission(
          `${RolesEnum.SuperAdmin},${RolesEnum.VLAdmin},${RolesEnum.VLTechnician}`
        ) &&
          !('userId' in this.activatedRoute.snapshot.queryParams)) ||
          this.hasPermission(
            `${RolesEnum.ScanningCenterAdmin},${RolesEnum.SCTechnician}`
          ))
      ) {
        this.filterModel.SentBackRequestId = this.data.case.sentBackDRequest.id;
      }
      targetService = this.service.getAll(this.filterModel, this.data.notAuthenticated);
    }
    targetService.subscribe(
      (data) => {
        if (data) {
          this.recordsData = data as ListData;
          this.pageCount = Math.ceil(
            (this.data.isCompare ? this.recordsData.slidesCount : this.recordsData.casesCount) / this.filterModel.PageSize
          );
          if (this.recordsData.pagedData && this.recordsData.pagedData.length) {
            this.slidesList = this.slidesList.concat(
              this.recordsData.pagedData
            );
          }
        }
        this.dataLoading = false;
      },
      (error) => {
        this.dataLoading = false;
      }
    );
  }

  getNextPage() {
    if (this.pageNumber <= this.pageCount - 1) {
      this.pageNumber += 1;
      this.getAll(false);
    }
  }

  isLocalStorageSaved(): boolean {
    const concurelUser = localStorage.getItem('concurelUser');
    return concurelUser !== null && concurelUser !== undefined;
  }

  loginConcurel() {
    this.loading = true;
    // if (
    //   (this.data.conCuralUserName ||
    //     this.data.conCuralPassword ||
    //     this.data.conCuralUserName == '' ||
    //     this.data.conCuralPassword == '') &&
    //   this.isLocalStorageSaved()
    // ) {
    //   localStorage.removeItem('concurelUser');
    // }
    if (this.data.conCuralUserName || this.data.conCuralPassword) {
      localStorage.removeItem('concurelUser');
    }
    if (
      this.data.conCuralUserName &&
      this.data.conCuralPassword &&
      !this.isLocalStorageSaved()
    ) {
      const model = {
        username: this.data.conCuralUserName,
        password: this.data.conCuralPassword,
        IsFromCaseDetails: true,
      };
      this.concurelService.loginConcurel(model).subscribe(
        (data) => {
          this.loading = false;
          this.dialogRef.close({ slideList: this.selected });
        },
        (error) => {
          this.loading = false;
          this.dialogRef.close({
            notAuthorized: true,
            slideList: this.selected,
          });
        }
      );
    } else {
      this.dialogRef.close({ notAuthorized: false, slideList: this.selected });
    }
  }

  hasPermission(permission: string) {
    return this.auth.hasPermission(permission);
  }

  isInArray(id: number) {
    return this.selected.some((slideId) => Number(slideId) === Number(id));
  }
  joinArray(items) {
    return items.join(' , ');
  }

  continue() {
    let query = { slide: this.selected };
    let slideId = this.data && this.data.slideId ? this.data.slideId : 0;
    const navArr = [
      '/viewer/',
      this.data.caseIdType,
      this.data.caseId,
      slideId
    ];
    if (this.data.pathologistId) {
      navArr.push(this.data.pathologistId);
    }
    if (this.data.IsEndorsement) {
      if (this.data.pathologistType) {
        navArr.push(this.data.pathologistType);
      }
      navArr.push(this.data.IsEndorsement);
    }
    if (this.data.requestId) {
      navArr.push(this.data.requestId);
    }
    this.router.navigate(navArr, { queryParams: query });
    this.dialogRef.close(true);
  }
}
