import { ModelsEnum } from './../../../models/users/users/enums/ModelsEnum';
import { ChangeUsernameComponent } from './../change-username/change-username.component';
import { ChangePasswordComponent } from './../change-password/change-password.component';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UserDetails } from 'src/app/models/users/users/UserDetails';
import { UsersService } from 'src/app/services/users.service';
import { UserFormComponent } from '../user-management/user-form/user-form.component';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { VerifyMobileComponent } from 'src/app/partial/verify-mobile/verify-mobile.component';
import { SwalService } from 'src/app/services/swal.service';
import { TranslateService } from '@ngx-translate/core';
import { RolesEnum } from 'src/app/models/users/roles/enums/roles';
import { ImageGuidelinesComponent } from './image-guidelines/image-guidelines.component';
import { UploadIdComponent } from './upload-id/upload-id.component';
import { ConfirmationDialogComponent } from 'src/app/partial/confirmation-dialog/confirmation-dialog.component';
import { IdentityEnum } from 'src/app/models/users/users/enums/IdentityEnum';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
})
export class UserProfileComponent implements OnInit {
  user: UserDetails;
  dataLoading = false;
  loading = false;
  userId = 9;
  IdentityEnum = IdentityEnum;

  // image file
  avatar: any;
  avatarSrc: string;
  maxFileSize = 0.8;
  fileTypeError = false;
  fileSizeError = false;

  constructor(
    private service: UsersService,
    public dialog: MatDialog,
    private auth: AuthenticationService,
    private swalService: SwalService,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.getDetails();
    this.auth.currentUserSubject.subscribe((user) => {
      if (user) {
        this.getDetails();
      }
    });
  }

  getDetails(reload = true) {
    this.dataLoading = reload;
    this.service.getUserProfile().subscribe(
      (data) => {
        this.dataLoading = false;
        if (data) {
          this.user = data as UserDetails;
          this.clear();
        }
      },
      (error) => {
        this.dataLoading = false;
      }
    );
  }

  changePassword() {
    const dialogRef = this.dialog.open(ChangePasswordComponent, {
      width: '540px',
      data: {},
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        // console.log(result);
      }
    });
  }

  changeUsername() {
    const dialogRef = this.dialog.open(ChangeUsernameComponent, {
      width: '540px',
      data: {
        userName: this.user.userName,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        // console.log(result);
      }
    });
  }

  editProfile() {
    console.log(ModelsEnum.profile);

    const dialogRef = this.dialog.open(UserFormComponent, {
      width: '620px',
      data: {
        record: this.user,
        userId: this.userId,
        model: ModelsEnum.profile, // LAB, Sc, VL
        isModuleAdmin: null,
        isTrainer: this.hasPermission(RolesEnum.Trainer),
        // isPathologist: true,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.getDetails();
      }
    });
  }

  checkRole(item: string) {
    if (this.user.roles) {
      return this.user.roles.includes(item);
    }
    return false;
  }

  // File preview with validation
  uploadAvatar($event): void {
    const file: File = $event.target.files[0];
    if (file && this.validateFile(file)) {
      this.readThis(file);
      this.avatar = file;
    } else {
      this.clear();
    }
  }
  readThis(file: File): void {
    const myReader: FileReader = new FileReader();
    myReader.readAsDataURL(file);
    myReader.onloadend = (e) => {
      // this.user.userImage = myReader.result as string;
      this.avatarSrc = myReader.result as string;
    };
  }
  validateFile(file: File): any {
    if (this.fileType(file.name)) {
      this.fileTypeError = false;
      if (file.size / (1024 * 1024) <= this.maxFileSize) {
        this.fileSizeError = false;
      } else {
        this.fileSizeError = true;
        return false;
      }
    } else {
      this.fileTypeError = true;
      return false;
    }
    return true;
  }
  fileType(fileName): any {
    const extension = fileName.split('.').pop().toLowerCase();
    switch (extension) {
      case 'jpeg':
      case 'jpg':
        return 'jpg';
      case 'png':
        return 'png';
      default:
        return false;
    }
  }

  updateAvatar() {
    if (this.avatarSrc) {
      this.loading = true;
      const formData = new FormData();
      formData.append('userImage', this.avatar, this.avatar.name);
      this.service.changeProfilePicture(formData).subscribe(
        () => {
          this.loading = false;
          this.getDetails(false);
          this.swalService
            .swalSuccess(this.translate.instant('swalMsg.updatedsuccessfully'))
            .then(() => {});
        },
        (err) => {
          this.loading = false;
        }
      );
    }
  }

  clear() {
    this.avatarSrc = this.user.userImage;
    this.avatar = undefined;
  }

  verifyMobile() {
    const dataRecord = {
      verifyEntityUserMobile: true,
      title: this.translate.instant('profile.verifyMobile'),
      message: this.translate.instant('profile.otpSent'),
    };
    const dialogRef = this.dialog.open(VerifyMobileComponent, {
      width: '750px',
      data: dataRecord,
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.getDetails();
      }
    });
  }
  openImageGuidelines() {
    if (!this.user.identityImage) {
      const dialogRef = this.dialog.open(ImageGuidelinesComponent, {
        width: '750px',
      });
      dialogRef.afterClosed().subscribe((res) => {
        if (res) {
          this.addEditId();
        }
      });
    } else {
      this.addEditId();
    }
  }
  addEditId() {
    const dialogRef = this.dialog.open(UploadIdComponent, {
      width: '550px',
      data:this.user.identityImage
    });
    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
          width: '600px',
          disableClose: true,
          data: {
            title: this.translate.instant(
              'imageGuidelines.IDDocumentValidationProgress'
            ),
            message: this.translate.instant(
              'imageGuidelines.validatingIDdocumentPleaseWaitFewSeconds'
            ),
            lottieFile: '/assets/js/id_verification.json',
            btnClass: 'd-none',
            serviceTarget: 'validateImageAndUpdatePathologyData',
            service: this.service,
            record: data,
            returnErrorDTO: true,
            hideSwalMessageSuccess: true,
            autoConfirm: true,
          },
        });
        dialogRef.afterClosed().subscribe((uploadRes) => {
          if (uploadRes) {
            if (uploadRes instanceof Object) {
              // error
              if (uploadRes.ErrorDTO && uploadRes.ErrorDTO.length) {
                let title;
                if (
                  uploadRes.ErrorDTO[0].ErrorCode ==
                  IdentityEnum.WIDTH_NOT_MATCH_MINWIDTH
                ) {
                  title = this.translate.instant(
                    'imageGuidelines.TheWidthMinimumWidth'
                  );
                } else if (
                  uploadRes.ErrorDTO[0].ErrorCode ==
                    IdentityEnum.HORIZONTALDPI ||
                  uploadRes.ErrorDTO[0].ErrorCode ==
                    IdentityEnum.IMAGE_RESOLUTION
                ) {
                  title = this.translate.instant(
                    'imageGuidelines.ImageResolution'
                  );
                } else if (
                  uploadRes.ErrorDTO[0].ErrorCode == IdentityEnum.NO_FACE_DETECT
                ) {
                  title = this.translate.instant(
                    'imageGuidelines.NoFaceDetect'
                  );
                } else if (
                  uploadRes.ErrorDTO[0].ErrorCode ==
                  IdentityEnum.HEIGHT_NOT_MATCH_MINHEIGHT
                ) {
                  title = this.translate.instant(
                    'imageGuidelines.TheHightMinimumHight'
                  );
                } else if (
                  uploadRes.ErrorDTO[0].ErrorCode == IdentityEnum.IMAGE_ROTATION
                ) {
                  title = this.translate.instant(
                    'imageGuidelines.ImageRotation'
                  );
                }
                const dialogRef = this.dialog.open(
                  ConfirmationDialogComponent,
                  {
                    width: '600px',
                    data: {
                      img: 'assets/images/icons/illustration_identity_not_uploaded.svg',
                      title: title,
                      message: uploadRes.ErrorDTO[0].Error,
                      btnClass: 'larg-btn w-50',
                      btnTitle: this.translate.instant('imageGuidelines.retry'),
                      btnCancel: true,
                      btnCancelClass: 'w-50',
                    },
                  }
                );
                dialogRef.afterClosed().subscribe((retryRes) => {
                  if (retryRes) {
                    if (!this.user.identityImage) {
                      this.openImageGuidelines()
                    } else {
                      this.addEditId();
                    }
                  }
                });
              }
            } else {
              const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
                width: '600px',
                data: {
                  img: 'assets/images/icons/done_illustration.svg',
                  title: this.translate.instant(
                    'imageGuidelines.IDDocumentSuccessfullyValidatedSubmitted'
                  ),
                  btnTitle: this.translate.instant('courses.done'),
                  btnClass: 'larg-btn',
                },
              });
              dialogRef.afterClosed().subscribe(()=> {
                this.getDetails()
              })
            }
          }
        });
      }
    });
  }

  hasPermission(permission: string) {
    return this.auth.hasPermission(permission);
  }
}
