import { DropDownDirective } from './../helpers/drop-down.directive';
import { EmptyStateComponent } from './empty-state/empty-state.component';
import { NgModule } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';

// Helpers
import { DemoMaterialModule } from 'src/app/helpers/material-modules';
import { DndDirective } from 'src/app/helpers/dnd.directive';
import { SanitizeHtmlPipePipe } from 'src/app/helpers/sanitize-html-pipe.pipe';
import { OnlynumberDirective } from 'src/app/helpers/onlynumber.directive';
import { ShortNamePipe } from 'src/app/helpers/shortName.pipe';
import { PrintDirective } from 'src/app/helpers/print.directive';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { PaginationComponent } from './pagination/pagination.component';
import { UsersListSharedComponent } from './users/users-list-shared/users-list-shared.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { RecaptchaModule, RecaptchaSettings, RECAPTCHA_SETTINGS, RECAPTCHA_LANGUAGE } from 'ng-recaptcha';
import { TranslateModule } from '@ngx-translate/core';
import { SidenavOverComponent } from './sidenav-over/sidenav-over.component';

import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';

import { Overlay, BlockScrollStrategy, OverlayContainer, FullscreenOverlayContainer } from '@angular/cdk/overlay';

export function scrollFactory(overlay: Overlay): () => BlockScrollStrategy {
  return () => overlay.scrollStrategies.block();
}

// Note we need a separate function as it's required
// by the AOT compiler.
export function playerFactory() {
  return player;
}

import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { TreeSelectionComponent } from './tree-selection/tree-selection.component';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { CustomPaginator } from '../helpers/CustomPaginatorConfiguration';
import { UserDetailsComponent } from './users/user-details/user-details.component';
import { AccessControlDirective } from '../helpers/access-control.directive';
import { TreeFieldComponent } from './tree-field/tree-field.component';
import { FileManagerComponent } from './file-manager/file-manager.component';
import { FilesViewerComponent } from './files-viewer/files-viewer.component';
import { DynamicFieldComponent } from './dynamic-field/dynamic-field.component';
import { TruncateTextPipe } from '../helpers/truncate-text.pipe';
import { CaseStatusComponent } from './case-status/case-status.component';
import { MAT_SELECT_SCROLL_STRATEGY } from '@angular/material/select';
import { SlaActionsComponent } from './sla-actions/sla-actions.component';
import { RouterModule } from '@angular/router';
import { VerifyMobileComponent } from './verify-mobile/verify-mobile.component';
import { OtpInputComponent } from './otp-input/otp-input.component';
import { KeysPipe } from '../helpers/keys.pipe';
import { CounterDirective } from '../helpers/counter.directive';
import { NgxScannerQrcodeModule } from 'ngx-scanner-qrcode';
import { TransactionsComponent } from './transactions/transactions.component';
import { ConcernedStaffComponent } from './concerned-staff/concerned-staff.component';
import { ChatComponent } from './concerned-staff/chat/chat.component';
import { CaseLogComponent } from './case-log/case-log.component';
import { CaseLogDetailsComponent } from './case-log/case-log-details/case-log-details.component';
import { SlideOtherFormComponent } from './slides/slide-other-form/slide-other-form.component';
import { ViewerSelectorComponent } from './slides/viewer-selector/viewer-selector.component';
import { PrintQrcodeComponent } from './slides/print-qrcode/print-qrcode.component';
import { SlidesListComponent } from './slides/slides-list/slides-list.component';
import { SlideFormComponent } from './slides/slide-form/slide-form.component';
import { SlidesCaseComponent } from './slides/slides-case/slides-case.component';
import { QRCodeModule } from 'angularx-qrcode';
import { ReceiveSlideQrcodeComponent } from './slides/receive-slide-qrcode/receive-slide-qrcode.component';
import { ReceiveSlideManuallyComponent } from './slides/receive-slide-manually/receive-slide-manually.component';
import { ReceiveMethodComponent } from './slides/receive-method/receive-method.component';
import { TimeDiffPipe } from '../helpers/time-left.pipe';
import { SurgeryInformationComponent } from './surgery-information/surgery-information.component';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { PercentageDirective } from '../helpers/percentage.directive';
import { EntityBasicInfoComponent } from './entities/entity-basic-info/entity-basic-info.component';
import { EntityCommunicationMethodsComponent } from './entities/entity-communication-methods/entity-communication-methods.component';
import { EntityIncomingComponent } from './entities/entity-incoming/entity-incoming.component';
import { EntityOutgoingComponent } from './entities/entity-outgoing/entity-outgoing.component';
import { EntityBalanceComponent } from './entities/entity-balance/entity-balance.component';
import { EntityTotalCasesComponent } from './entities/entity-total-cases/entity-total-cases.component';
import { SlaStatusComponent } from '../features/sla/sla-status/sla-status.component';
import { Timezonepipe } from '../helpers/timezone.pipe';
import { ReadMoreComponent } from './read-more/read-more.component';
import { WebSpeechComponent } from './web-speech/web-speech.component';
import { DeepgramFieldComponent } from './deepgram-field/deepgram-field.component';
import { UserPerformanceStatisticsComponent } from './users/user-performance-statistics/user-performance-statistics.component';
import { ChartStatisticComponent } from './chart-statistic/chart-statistic.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ReceiveSpecimenComponent } from './slides/receive-specimen/receive-specimen.component';
import { ImageWithLoadingComponent } from './image-with-loading/image-with-loading.component';
import { LazyImgDirective } from '../helpers/lazy-img.directive';
import { LightGalleryVideoComponent } from './light-gallery-video/light-gallery-video.component';
import { SubmitReasonComponent } from './submit-reason/submit-reason.component';
import { ConcurelAccountComponent } from './slides/concurel-account/concurel-account.component';
import { ConcurelLoginComponent } from './slides/concurel-account/concurel-login/concurel-login.component';
import { MeetingListComponent } from './slides/concurel-account/meeting-list/meeting-list.component';
import { ConcurelAccountMsgComponent } from './slides/concurel-account/concurel-account-msg/concurel-account-msg.component';
import { JsonParsePipe } from '../helpers/json-parse.pipe';
import { SelectWithSearchComponent } from './select-with-search/select-with-search.component';
import { HeaderComponent } from '../layout/admin-layout/header/header.component';
import { LiveStreamComponent } from './live-stream/live-stream.component';
import { PaginationCustomComponent } from './pagination-custom/pagination-custom.component';
import { ChatbotComponent } from './chatbot/chatbot.component';
import { MatFiledCustomDirective } from '../helpers/mat.filed.custom.directive';
import localeAr from '@angular/common/locales/ar';
import { RespondentMessageComponent } from './quiz-type/respondent-message/respondent-message.component';
import { QuizTypeComponent } from './quiz-type/quiz-type.component';
import { QuestionsFormComponent } from './quiz-type/questions-form/questions-form.component';
import { AccessibilityMenuComponent } from './accessibility-menu/accessibility-menu.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { TimeRemainingDirective } from '../helpers/TimeRemaining.directive';
import { TimeZoneUtcPipe } from '../helpers/time-zone-utc.pipe';
import { SearchResultComponent } from './search-result/search-result.component';
import { DateSuffixPipe } from '../helpers/date-suffix.pipe';
import { PdfViewerComponent } from './pdf-viewer/pdf-viewer.component';
import { PreviewCertificatesComponent } from './preview-certificates/preview-certificates.component';
import { FooterComponent } from '../layout/admin-layout/footer/footer.component';
import { EfinanceFormComponent } from './efinance-form/efinance-form.component';
import { SafePipe } from '../helpers/SafePipe';
import { AttachmentDetailsComponent } from './attachment-details/attachment-details.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';

registerLocaleData(localeAr);

const components = [
  HeaderComponent,
  FooterComponent,
  EmptyStateComponent,
  PaginationComponent,
  SidenavOverComponent,
  TreeSelectionComponent,
  UsersListSharedComponent,
  UserDetailsComponent,
  TreeFieldComponent,
  FilesViewerComponent,
  FileManagerComponent,
  ConcernedStaffComponent,
  ChatComponent,
  DynamicFieldComponent,
  CaseStatusComponent,
  SlaActionsComponent,
  VerifyMobileComponent,
  OtpInputComponent,
  TransactionsComponent,
  CaseLogComponent,
  CaseLogDetailsComponent,
  SlideOtherFormComponent,
  ViewerSelectorComponent,
  PrintQrcodeComponent,
  SlidesListComponent,
  SlideFormComponent,
  SlidesCaseComponent,
  ReceiveMethodComponent,
  ReceiveSlideManuallyComponent,
  ReceiveSlideQrcodeComponent,
  SurgeryInformationComponent,
  ConfirmationDialogComponent,
  EntityBasicInfoComponent,
  EntityCommunicationMethodsComponent,
  EntityIncomingComponent,
  EntityOutgoingComponent,
  EntityBalanceComponent,
  EntityTotalCasesComponent,
  SlaStatusComponent,
  ReadMoreComponent,
  WebSpeechComponent,
  DeepgramFieldComponent,
  UserPerformanceStatisticsComponent,
  ChartStatisticComponent,
  ReceiveSpecimenComponent,
  ImageWithLoadingComponent,
  LightGalleryVideoComponent,
  SubmitReasonComponent,
  ConcurelAccountComponent,
  ConcurelLoginComponent,
  MeetingListComponent,
  ConcurelAccountMsgComponent,
  SelectWithSearchComponent,
  PaginationCustomComponent,
  LiveStreamComponent,
  ChatbotComponent,
  QuizTypeComponent,
  RespondentMessageComponent,
  QuestionsFormComponent,
  AccessibilityMenuComponent,
  SearchResultComponent,
  PdfViewerComponent,
  PreviewCertificatesComponent,
  EfinanceFormComponent,
  AttachmentDetailsComponent
];

const directives = [
  DndDirective,
  OnlynumberDirective,
  PercentageDirective,
  PrintDirective,
  DropDownDirective,
  AccessControlDirective,
  CounterDirective,
  LazyImgDirective,
  TimeRemainingDirective
  // MatFiledCustomDirective
];

const pipes = [
  SanitizeHtmlPipePipe,
  ShortNamePipe,
  TruncateTextPipe,
  KeysPipe,
  TimeDiffPipe,
  Timezonepipe,
  JsonParsePipe,
  TimeZoneUtcPipe,
  DateSuffixPipe,
  SafePipe
];

const modules = [
  DemoMaterialModule,
  InfiniteScrollModule,
  ReactiveFormsModule,
  FormsModule,
  RecaptchaModule,
  NgxIntlTelInputModule,
  NgxMaterialTimepickerModule,
  NgxScannerQrcodeModule,
  QRCodeModule,
  NgxChartsModule,
  CarouselModule,
  PdfViewerModule
];

@NgModule({
  declarations: [
    // Directives
    directives,

    // Pipes
    pipes,
    // Components
    components,
  ],
  imports: [
    CommonModule,
    modules,
    TranslateModule.forChild({}),
    LottieModule.forRoot({ player: playerFactory }),
    RouterModule,
  ],
  exports: [
    // Components
    components,
    directives,
    pipes,
    modules,
    TranslateModule,
    LottieModule,
  ],
  providers: [
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: environment.googleSiteKey,
        useValue: localStorage.getItem('language') || 'en'
      } as RecaptchaSettings,
    },
    {
      provide: RECAPTCHA_LANGUAGE,
      useValue: localStorage.getItem('language') || 'en', // use language
    },
    { provide: MatPaginatorIntl, useValue: CustomPaginator() },
    { provide: MAT_SELECT_SCROLL_STRATEGY, useFactory: scrollFactory, deps: [Overlay] },
    { provide: OverlayContainer, useClass: FullscreenOverlayContainer }

  ]
})
export class SharedModule { }
