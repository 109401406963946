import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TenantService {
  refreshDetails = new BehaviorSubject<boolean>(false);
  public defaultTenantSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private http: HttpClient) {

  }

  public get defaultTenantValue(): any {
    return this.defaultTenantSubject.value;
  }

  getTenant(): any {
    return this.http.get("./assets/json/tenant.json", { responseType: 'json' });
  }
}
