import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SupportTicketFormComponent } from 'src/app/features/support-ticket/support-ticket-form/support-ticket-form.component';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html'
})
export class FooterComponent implements OnInit {
  today = new Date();

  constructor(public dialog: MatDialog,) { }

  ngOnInit() {
    // this.openForm();
  }

  openForm() {
    const dialogRef = this.dialog.open(SupportTicketFormComponent, {
      width: '550px',
      data: {}
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {

      }
    });
  }

}
