<div mat-dialog-title>
  <h2>
    {{"certificate.ViewCertificate"|translate}}
  </h2>
  <button type="button" class="close" mat-dialog-close aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<mat-dialog-content>
  <div *ngIf='dataLoading' class="dataLoading">
    <mat-spinner class="m-auto" diameter="50"></mat-spinner>
  </div>

  <ng-container *ngIf='!dataLoading'>
    <img class="mb-4" [src]='file' width='100%' height='100%' alt="">
    <!-- <iframe class="mb-4" [src]='file' width='100%' height='100%' frameborder='0'></iframe> -->
  </ng-container>
</mat-dialog-content>
<mat-dialog-actions align="end" class="assign-dialog-actions flex-row-reverse">
  <div>
    <button mat-raised-button color="grey" mat-dialog-close>{{"swalMsg.cancel"|translate}}</button>
  </div>
</mat-dialog-actions>