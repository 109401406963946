import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-pagination-custom',
  templateUrl: './pagination-custom.component.html'
})
export class PaginationCustomComponent implements OnInit {
  @Input() pageIndex = 0;
  minNUmberPages = 0;
  maxNUmberPages = 5;
  visibleNUmberPages = 6;

  @Input() pageCount = 0;
  @Output() page = new EventEmitter<number>();

  constructor() { }

  ngOnInit() {
    this.paginate(this.pageIndex, true);
  }

  counter(i: number) { return new Array(i); }

  paginate(index: number, event = false) {
    this.pageIndex = index;
    if (
      (this.pageCount - 1) > this.visibleNUmberPages &&
      (this.pageIndex + 3) >= this.visibleNUmberPages
    ) {
      this.minNUmberPages = this.pageIndex === 0 ? 0 : (Math.abs(this.pageCount - this.pageIndex) < this.visibleNUmberPages ? Math.abs(this.pageCount - this.visibleNUmberPages) : this.pageIndex - 1);
      this.maxNUmberPages = ((this.pageCount - 1) == this.pageIndex) ? this.minNUmberPages + this.visibleNUmberPages : (this.minNUmberPages + this.visibleNUmberPages) - 1;
    } else {
      this.minNUmberPages = 0;
      this.maxNUmberPages = 5;
    }
    if (!event) {
      this.page.emit(this.pageIndex);
    }
  }

}
