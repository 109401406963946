export class SearchReasult {
  resultsCount: string
  pageData: pageData[]
}

export class pageData {
  courseId: number
  courseTitle: string
  moduleId: number
  moduleOrder: number
  moduleTitle: string
  lessonId: number
  lessonOrder: number
  lessonType: string
  lessonTitle: string
  lessonDescription: string
  lastUpdateDate: string
  attachments: any[]
}
