<mat-dialog-content class="confirmation">
  <div class="mat-dialog-body">
    <figure class="mb-5 text-center">
      <img class="img-fluid" src="assets/images/auth/otp-illustration.png" alt="">
    </figure>
    <h4 class="label-form text-center bold">
      {{title}}
    </h4>
    <p class="label-helper text-center mb-3">
      {{message}}
    </p>
    <app-otp-input [setting]="settings" (valueChanged)="onInputChange($event)"></app-otp-input>

    <div class="d-flex align-items-center justify-content-center mb-4">
      <button class="larg-btn mt-2" mat-raised-button color="primary" [disabled]="disabled || loading"
        [class.loading]='loading' (click)="saveRecord()">
        {{"labProfile.verify"|translate}}
      </button>
    </div>

    <div class="container_resend">
      {{"labProfile.didntReceiveOTP"|translate}} 
      <a [ngClass]="{'disabled': disabledResend}" (click)="ressendOtp()">
        {{"labProfile.resend"|translate}}
      </a>
    </div>
  </div>
</mat-dialog-content>
