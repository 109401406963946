<div class="sidenav-user-toolbar">
  <h4>
    Branches
    <span *ngIf="this.recordsData?.length > 0">[{{this.recordsData?.length}}]</span>
  </h4>
  <div class="button-actions-toolbar">
    <button type="button" mat-raised-button color="primary" (click)="openForm(true)">
      Add New Branch
    </button>
  </div>
</div>
<div class="sidenav-user-container">
  <div class="profile_detail">
    <div class="page">
      <div *ngIf='dataLoading' class="dataLoading">
        <mat-spinner class="m-auto" diameter="50"></mat-spinner>
      </div>

      <ng-container *ngIf='!dataLoading'>
        <div *ngIf="recordsData; else dataEmpty">
          <ul class="list_branchs" *ngIf="recordsData.length; else dataEmpty">
            <li class="item_branch" *ngFor="let record of recordsData">
              <div class="avatar">
                <img [src]="record.logo" (error)="record.logo = './assets/images/lab/default_avatar.svg'" alt="branche">
              </div>
              <div class="item_branch_inner">
                <div class="item_branch_content">
                  <h4>{{record.name}}</h4>
                </div>
                <div class="item_branch_right">
                  <a mat-icon-button [matTooltip]="'View'" (click)="recordDetails(record.id)">
                    <svg width="20" height="13" viewBox="0 0 20 13">
                      <path id="view-icon"
                        d="M4419.2,17820.545a.72.72,0,0,1,0-.809c.166-.242,3.664-6.094,9.905-6.094s9.74,5.852,9.91,6.094a1.383,1.383,0,0,1,0,.809c-.17.246-3.664,6.1-9.91,6.1S4419.362,17820.791,4419.2,17820.545Zm5.745-.4a4.164,4.164,0,1,0,4.161-4.066A4.073,4.073,0,0,0,4424.941,17820.143Zm1.664,0a2.5,2.5,0,1,1,2.5,2.434A2.423,2.423,0,0,1,4426.6,17820.143Zm2.5-.814a.835.835,0,1,0,.837-.814A.781.781,0,0,0,4429.1,17819.328Z"
                        transform="translate(-4419.072 -17813.643)" fill="#616e7c" />
                    </svg>
                  </a>
                  <a mat-icon-button [matTooltip]="'Delete'" (click)="deleteRecord(record.id)">
                    <svg width="16" height="20.002" viewBox="0 0 16 20.002">
                      <path id="delete-icon"
                        d="M4422.4,17833.639a1.853,1.853,0,0,1-1.844-1.785l-.51-10.822a.2.2,0,0,1,.192-.207H4433.9a.194.194,0,0,1,.192.207l-.505,10.822a1.859,1.859,0,0,1-1.844,1.785Zm7.134-11.252v8.129a.616.616,0,1,0,1.231,0v-8.129a.616.616,0,1,0-1.231,0Zm-3.079,0v8.129a.615.615,0,1,0,1.23,0v-8.129a.615.615,0,1,0-1.23,0Zm-3.075,0v8.129a.615.615,0,1,0,1.23,0v-8.129a.615.615,0,1,0-1.23,0Zm-3.692-2.811a.621.621,0,0,1-.618-.627v-1.248a1.551,1.551,0,0,1,1.54-1.562h3.383v-.627a1.864,1.864,0,0,1,1.849-1.875h2.461a1.86,1.86,0,0,1,1.844,1.875v.627h3.383a1.551,1.551,0,0,1,1.54,1.563v1.248a.617.617,0,0,1-.613.627Zm5.536-4.064v.627h3.692v-.627a.616.616,0,0,0-.613-.621h-2.461A.619.619,0,0,0,4425.223,17815.512Z"
                        transform="translate(-4419.069 -17813.637)" fill="#616e7c" />
                    </svg>
                  </a>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </ng-container>
      <ng-template #dataEmpty>
        <app-empty-state [img]='"./assets/images/viewer-icons/no-remarks-illustration.svg"' [title]='"general.noDataFound"|translate'>
        </app-empty-state>
      </ng-template>
    </div>
  </div>
</div>
