import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ListData } from 'src/app/models/labs/ListData';
import { sortDataList } from 'src/app/models/labs/sortDataList';
import { ListDataFilter } from 'src/app/models/labs/ListDataFilter';
import { LabsService } from 'src/app/services/labs.service';
import { LabFormComponent } from './lab-form/lab-form.component';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Lookup } from 'src/app/models/lookups/Lookup';
import { LookupsService } from 'src/app/services/lookups.service';
import { LabType } from 'src/app/models/labs/enums/LabType';
import { statusList } from 'src/app/models/sla/enums/statusList';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { RolesEnum } from 'src/app/models/users/roles/enums/roles';
import { ActivatedRoute, Router } from '@angular/router';
import { SwalService } from 'src/app/services/swal.service';

@Component({
  selector: 'app-labs',
  templateUrl: './labs.component.html'
})
export class LabsComponent implements OnInit {
  dataLoading = false;
  showFilter = false;
  showSearch = false;
  entityId: number;
  entityType: string;

  sortValue = sortDataList.CreationDateDsc;
  sortItems = [
    { name: 'general.nameAsc', value: sortDataList.LabNameAsc },
    { name: 'general.nameDesc', value: sortDataList.LabNameDsc },
    { name: 'general.creationDateAsc', value: sortDataList.CreationDateAsc },
    { name: 'general.creationDateDesc', value: sortDataList.CreationDateDsc },
  ];
  labTypes = [
    { name: 'labs.main', value: LabType.MAINLAB },
    { name: 'labs.branch', value: LabType.BRANCH },
    // { name: 'Hospital Lab', value: LabType.HOSPITALLAB },
    // { name: 'Clinic Lab', value: LabType.CLINICLAB }
  ];

  statusListAll = [
    { name: 'labs.waitingAcceptance', value: statusList.PENDING },
    { name: 'labs.active', value: statusList.APPROVED },
    { name: 'labs.rejected', value: statusList.REJECTED },
    { name: 'labs.expired', value: statusList.EXPIRED },
    { name: 'labs.terminated', value: statusList.TERMINATED },
    { name: 'labs.notAdded', value: statusList.NOT_ADDED }
  ];

  // table
  pageCount = 0;
  pageIndex = 0;
  recordsData: ListData = new ListData();
  filterModel: ListDataFilter = new ListDataFilter();

  filterFormGroup: FormGroup;
  countryList: Lookup[] = [];
  regionList: Lookup[] = [];
  cityList: Lookup[] = [];
  entityList: any[] = [];
  today = new Date();

  constructor(
    public dialog: MatDialog,
    private service: LabsService,
    private lookupsService: LookupsService,
    private formBuilder: FormBuilder,
    public translate: TranslateService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private auth: AuthenticationService,
    private swalService: SwalService
  ) { }

  ngOnInit(): void {
    this.activatedRoute.queryParamMap.subscribe(query => {
      if (query.get('entityId')) {
        this.entityId = Number(query.get('entityId'));
        this.entityType = query.get('entityType');
      } else {
        this.entityId = null;
        this.entityType = null;
        this.filterModel.EntityId = '';
        this.filterModel.EntityType = '';
      }
    });
    this.buildFilterForm();
    //this.getListHospitalsClinicsLookup();
    this.getAll(null);
    this.getCountries();
  }

  buildFilterForm() {
    this.filterFormGroup = this.formBuilder.group({
      LabType: [null],
      CountryId: [null],
      RegionId: [null],
      CityId: [null],
      SlaStatus: [null],
      dateFrom: [null],
      dateTo: [null],
      entity: [null]
    });
  }

  getAll(event) {
    this.filterModel.pageSize = 10;
    this.dataLoading = true;
    if (event) {
      this.pageIndex = event.pageIndex;
      this.filterModel.pageSize = event.pageSize;
    } else {
      this.pageIndex = 0;
    }

    if (this.entityId && !this.filterModel.EntityId) {
      this.filterModel.EntityId = String(this.entityId);
      this.filterModel.EntityType = this.entityType;
    }

    this.filterModel.pageNumber = this.pageIndex + 1;
    this.service.getAll(this.filterModel).subscribe(
      data => {
        this.dataLoading = false;
        if (data) {
          this.recordsData = data as ListData;
          this.pageCount = Math.ceil(this.recordsData.labsCount / this.filterModel.pageSize);
        }
      },
      error => {
        this.dataLoading = false;
      });
  }

  getListHospitalsClinicsLookup() {
    const query = { WithLabs: true };
    if (this.hasPermission(RolesEnum.SuperAdmin)) {
      this.lookupsService.getListHospitalsClinicsLookup(query).subscribe(
        data => {
          this.entityList = data as [];
          if (this.entityId && this.entityList.length) {
            this.filterFormGroup.controls.entity.setValue(
              this.entityList.find(item => item.id === this.entityId && item.code === this.entityType)
            );
          }
        }, err => {
          console.error(err);
        });
    }
  }

  getCountries() {
    this.lookupsService.getCountries().subscribe(
      data => {
        this.countryList = data as [];
        this.regionList = [];
        this.cityList = [];
      }, err => {
        console.error(err);
      });
  }
  getRegions() {
    this.lookupsService.getRegions(this.filterFormGroup.controls.CountryId.value).subscribe(
      data => {
        this.regionList = data as [];
        this.cityList = [];
        this.filterFormGroup.controls.RegionId.setValue(null);
        this.filterFormGroup.controls.CityId.setValue(null);
      }, err => {
        console.error(err);
      });
  }
  getCities() {
    this.lookupsService.getCities(
      this.filterFormGroup.controls.CountryId.value,
      this.filterFormGroup.controls.RegionId.value).subscribe(
        data => {
          this.cityList = data as [];
          this.filterFormGroup.controls.CityId.setValue(null);
        }, err => {
          console.error(err);
        });
  }

  sortRecords(selected) {
    this.filterModel.orderBy = this.sortValue;
    this.getAll(null);
  }

  makeSearch() {
    if (!this.showSearch) {
      this.showSearch = true; // Show input
    } else {
      if (this.filterModel.LabName) {
        this.getAll(null);
      }
    }
  }

  resetSearch() {
    this.filterModel.LabName = '';
    this.getAll(null);
  }

  makeFilter() {
    this.filterModel = new ListDataFilter();
    this.filterModel.orderBy = this.sortValue;
    if (this.filterFormGroup.controls.entity.value != null) {
      this.filterModel.EntityId = this.filterFormGroup.controls.entity.value.id;
      this.filterModel.EntityType = this.filterFormGroup.controls.entity.value.code;
    }
    if (this.filterFormGroup.controls.SlaStatus.value != null) {
      this.filterModel.SlaStatus = this.filterFormGroup.controls.SlaStatus.value;
    }
    if (this.filterFormGroup.controls.LabType.value) {
      this.filterModel.LabType = this.filterFormGroup.controls.LabType.value;
    }
    if (this.filterFormGroup.controls.CountryId.value) {
      this.filterModel.CountryId = this.filterFormGroup.controls.CountryId.value;
    }
    if (this.filterFormGroup.controls.RegionId.value) {
      this.filterModel.RegionId = this.filterFormGroup.controls.RegionId.value;
    }
    if (this.filterFormGroup.controls.CityId.value) {
      this.filterModel.CityId = this.filterFormGroup.controls.CityId.value;
    }
    if (this.filterFormGroup.controls.dateFrom.value) {
      this.filterModel.dateFrom = this.filterFormGroup.controls.dateFrom.value;
    }
    if (this.filterFormGroup.controls.dateTo.value) {
      this.filterModel.dateTo = this.filterFormGroup.controls.dateTo.value;
    }
    this.getAll(null);
  }

  clearFilter() {
    this.filterModel = new ListDataFilter();
    this.regionList = [];
    this.cityList = [];
    this.filterFormGroup.reset();
    if (this.entityId) {
      this.router.navigate([location.pathname]);
    }
    this.entityId = null;
    this.entityType = null;
    this.getAll(null);
  }

  openCreateForm() {
    const dialogRef = this.dialog.open(LabFormComponent, {
      width: '600px',
      data: {}
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getAll(null);
      }
    });
  }

  deleteRecord(recordID) {
    this.swalService.swalConfirmation(this.translate.instant('swalMsg.Yesdeleteit'))
      .then((result) => {
        if (result.value) {
          this.dataLoading = true;
          this.service.deleteRecord(recordID).subscribe(
            data => {
              this.dataLoading = false;
              this.swalService.swalSuccess()
                .then(() => {
                  this.getAll(null);
                });
            },
            err => {
              this.dataLoading = false;
              // console.log('Try again!, ' + error);
              const errors = JSON.parse(err.detail);
              let message = '';
              if (err.detail) {
                errors.ErrorDTO.forEach((error) => {
                  if (error.ErrorCode === 'CantDelete') {
                    message = error.Error;
                  }
                });
              } else {
                message = err.title;
              }
              this.swalService.swalFailed(message);
            });
        }
      });
  }

  hasPermission(permission: string) {
    return this.auth.hasPermission(permission);
  }
}
