<!-- <button class="textToSpeech mx-2" type="button" #customElement
  (click)="accessibilityResults.menuInterface.decreaseText()">
  <svg xmlns="http://www.w3.org/2000/svg" width="17" height="20" viewBox="0 0 17 20">
    <path id="ic_accessibility"
      d="M154.58-867.761a1.808,1.808,0,0,1-1.331-.552,1.827,1.827,0,0,1-.549-1.338,1.827,1.827,0,0,1,.549-1.338,1.807,1.807,0,0,1,1.331-.552,1.808,1.808,0,0,1,1.331.552,1.827,1.827,0,0,1,.549,1.338,1.827,1.827,0,0,1-.549,1.338A1.808,1.808,0,0,1,154.58-867.761Zm-2.643,15.453v-12.579q-1.336-.112-2.643-.291t-2.529-.461a.931.931,0,0,1-.543-.368.664.664,0,0,1-.111-.612.752.752,0,0,1,.407-.487.961.961,0,0,1,.66-.068,30.729,30.729,0,0,0,3.65.573q1.858.179,3.752.179t3.752-.179a30.73,30.73,0,0,0,3.65-.573.961.961,0,0,1,.66.068.752.752,0,0,1,.407.487.664.664,0,0,1-.111.612.931.931,0,0,1-.543.368q-1.222.282-2.529.461t-2.642.291v12.579a.745.745,0,0,1-.219.547.737.737,0,0,1-.544.221.737.737,0,0,1-.544-.221.745.745,0,0,1-.219-.547V-857.8h-2.232v5.492a.745.745,0,0,1-.219.547.737.737,0,0,1-.544.221.737.737,0,0,1-.544-.221A.745.745,0,0,1,151.937-852.308Z"
      transform="translate(-146.08 871.54)" fill="#c92d7d" />
  </svg>
</button> -->
<div class="dropdown-container" *ngIf="accessibilityResults" appDropDown [wasInside]="true">
  <span class="dropdown-toggler not-after textToSpeech">
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="20" viewBox="0 0 17 20">
      <path id="ic_accessibility"
        d="M154.58-867.761a1.808,1.808,0,0,1-1.331-.552,1.827,1.827,0,0,1-.549-1.338,1.827,1.827,0,0,1,.549-1.338,1.807,1.807,0,0,1,1.331-.552,1.808,1.808,0,0,1,1.331.552,1.827,1.827,0,0,1,.549,1.338,1.827,1.827,0,0,1-.549,1.338A1.808,1.808,0,0,1,154.58-867.761Zm-2.643,15.453v-12.579q-1.336-.112-2.643-.291t-2.529-.461a.931.931,0,0,1-.543-.368.664.664,0,0,1-.111-.612.752.752,0,0,1,.407-.487.961.961,0,0,1,.66-.068,30.729,30.729,0,0,0,3.65.573q1.858.179,3.752.179t3.752-.179a30.73,30.73,0,0,0,3.65-.573.961.961,0,0,1,.66.068.752.752,0,0,1,.407.487.664.664,0,0,1-.111.612.931.931,0,0,1-.543.368q-1.222.282-2.529.461t-2.642.291v12.579a.745.745,0,0,1-.219.547.737.737,0,0,1-.544.221.737.737,0,0,1-.544-.221.745.745,0,0,1-.219-.547V-857.8h-2.232v5.492a.745.745,0,0,1-.219.547.737.737,0,0,1-.544.221.737.737,0,0,1-.544-.221A.745.745,0,0,1,151.937-852.308Z"
        transform="translate(-146.08 871.54)" />
    </svg>
  </span>
  <div class="dropdown-inner-menu with_350">
    <div class="dropdown-menu-header border-0">
      <h3>Accessibility</h3>
      <button mat-icon-button type="button" class="btn border" (click)="accessibilityResults.resetAll();getValues()">
        <svg xmlns="http://www.w3.org/2000/svg" width="13" height="14" viewBox="0 0 13 14">
          <path id="ic_reset"
            d="M164.733-829.03a6.39,6.39,0,0,1-3.412-2.23,5.967,5.967,0,0,1-1.32-3.822,5.931,5.931,0,0,1,.386-2.132,6.42,6.42,0,0,1,1.1-1.857.76.76,0,0,1,.548-.246.792.792,0,0,1,.589.246.745.745,0,0,1,.234.531.871.871,0,0,1-.213.589,4.685,4.685,0,0,0-.752,1.336,4.454,4.454,0,0,0-.264,1.533,4.518,4.518,0,0,0,.965,2.839,4.792,4.792,0,0,0,2.488,1.7.86.86,0,0,1,.437.295.739.739,0,0,1,.173.471.748.748,0,0,1-.284.619A.732.732,0,0,1,164.733-829.03Zm3.534,0a.717.717,0,0,1-.67-.138.775.775,0,0,1-.284-.629.715.715,0,0,1,.173-.452.86.86,0,0,1,.437-.295,4.926,4.926,0,0,0,2.488-1.709,4.478,4.478,0,0,0,.965-2.829,4.474,4.474,0,0,0-1.422-3.34A4.783,4.783,0,0,0,166.5-839.8h-.061l.325.314a.732.732,0,0,1,.223.55.732.732,0,0,1-.223.55.784.784,0,0,1-.569.216.785.785,0,0,1-.569-.216l-1.706-1.65a.688.688,0,0,1-.173-.255.845.845,0,0,1-.051-.295.844.844,0,0,1,.051-.295.688.688,0,0,1,.173-.255l1.706-1.65A.784.784,0,0,1,166.2-843a.784.784,0,0,1,.569.216.733.733,0,0,1,.223.55.732.732,0,0,1-.223.55l-.325.314h.061a6.383,6.383,0,0,1,4.611,1.827,5.969,5.969,0,0,1,1.889,4.46,5.979,5.979,0,0,1-1.32,3.812A6.369,6.369,0,0,1,168.267-829.03Z"
            transform="translate(-160 843)" fill="#5d6d7e" />
        </svg>
      </button>
    </div>

    <div class="dropdown-menu-body pt-1">
      <div class="item_accessibility">
        <div class="item_content">
          <div class="figure_thumbnail">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16" viewBox="0 0 20 16">
              <path id="ic_text_size"
                d="M92-797H88.5a1.446,1.446,0,0,1-1.062-.437A1.446,1.446,0,0,1,87-798.5a1.446,1.446,0,0,1,.438-1.062A1.446,1.446,0,0,1,88.5-800h10a1.446,1.446,0,0,1,1.063.438A1.446,1.446,0,0,1,100-798.5a1.446,1.446,0,0,1-.437,1.063A1.446,1.446,0,0,1,98.5-797H95v11.5a1.446,1.446,0,0,1-.437,1.062A1.446,1.446,0,0,1,93.5-784a1.446,1.446,0,0,1-1.062-.437A1.446,1.446,0,0,1,92-785.5Zm-9,5H81.5a1.446,1.446,0,0,1-1.062-.437A1.446,1.446,0,0,1,80-793.5a1.446,1.446,0,0,1,.438-1.062A1.446,1.446,0,0,1,81.5-795h6a1.446,1.446,0,0,1,1.063.438A1.446,1.446,0,0,1,89-793.5a1.446,1.446,0,0,1-.437,1.063A1.446,1.446,0,0,1,87.5-792H86v6.5a1.446,1.446,0,0,1-.437,1.062A1.446,1.446,0,0,1,84.5-784a1.446,1.446,0,0,1-1.062-.437A1.446,1.446,0,0,1,83-785.5Z"
                transform="translate(-80 800)" />
            </svg>
          </div>
          <h5 class="title_item">Increase text size</h5>
        </div>
        <div class="item_actions">
          <button mat-icon-button type="button" class="btn"
            (click)="accessibilityResults.menuInterface.increaseText();getValues()">
            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
              <path id="ic_plus"
                d="M205.143-753.143h-4.286a.829.829,0,0,1-.611-.246A.829.829,0,0,1,200-754a.829.829,0,0,1,.246-.611.829.829,0,0,1,.611-.246h4.286v-4.286a.829.829,0,0,1,.246-.611A.829.829,0,0,1,206-760a.829.829,0,0,1,.611.246.829.829,0,0,1,.246.611v4.286h4.286a.829.829,0,0,1,.611.246A.829.829,0,0,1,212-754a.829.829,0,0,1-.246.611.829.829,0,0,1-.611.246h-4.286v4.286a.829.829,0,0,1-.246.611A.829.829,0,0,1,206-748a.829.829,0,0,1-.611-.246.829.829,0,0,1-.246-.611Z"
                transform="translate(-200 760)" fill="#fff" />
            </svg>
          </button>
        </div>
      </div>
      <div class="item_accessibility">
        <div class="item_content">
          <div class="figure_thumbnail">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16" viewBox="0 0 20 16">
              <path id="ic_text_size"
                d="M92-797H88.5a1.446,1.446,0,0,1-1.062-.437A1.446,1.446,0,0,1,87-798.5a1.446,1.446,0,0,1,.438-1.062A1.446,1.446,0,0,1,88.5-800h10a1.446,1.446,0,0,1,1.063.438A1.446,1.446,0,0,1,100-798.5a1.446,1.446,0,0,1-.437,1.063A1.446,1.446,0,0,1,98.5-797H95v11.5a1.446,1.446,0,0,1-.437,1.062A1.446,1.446,0,0,1,93.5-784a1.446,1.446,0,0,1-1.062-.437A1.446,1.446,0,0,1,92-785.5Zm-9,5H81.5a1.446,1.446,0,0,1-1.062-.437A1.446,1.446,0,0,1,80-793.5a1.446,1.446,0,0,1,.438-1.062A1.446,1.446,0,0,1,81.5-795h6a1.446,1.446,0,0,1,1.063.438A1.446,1.446,0,0,1,89-793.5a1.446,1.446,0,0,1-.437,1.063A1.446,1.446,0,0,1,87.5-792H86v6.5a1.446,1.446,0,0,1-.437,1.062A1.446,1.446,0,0,1,84.5-784a1.446,1.446,0,0,1-1.062-.437A1.446,1.446,0,0,1,83-785.5Z"
                transform="translate(-80 800)" />
            </svg>
          </div>
          <h5 class="title_item">Decrease text size</h5>
        </div>
        <div class="item_actions">
          <button mat-icon-button type="button" class="btn"
            (click)="accessibilityResults.menuInterface.decreaseText();getValues()">
            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="2" viewBox="0 0 12 2">
              <rect id="ic_minus" width="12" height="2" rx="1" fill="#fff" />
            </svg>
          </button>
        </div>
      </div>
      <div class="item_accessibility cursor-pointer"
        [class.active]="accessibilityResults.stateValues && accessibilityResults.stateValues.invertColors"
        (click)="accessibilityResults.menuInterface.invertColors();getValues()">
        <div class="item_content">
          <div class="figure_thumbnail">
            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="20" viewBox="0 0 17 20">
              <path id="ic_inver_colors"
                d="M168.5-836a8.167,8.167,0,0,1-6.016-2.5A8.28,8.28,0,0,1,160-844.56a8.3,8.3,0,0,1,.664-3.329,8.969,8.969,0,0,1,1.833-2.731l4.887-4.918a1.84,1.84,0,0,1,.531-.34A1.505,1.505,0,0,1,168.5-856a1.505,1.505,0,0,1,.584.122,1.84,1.84,0,0,1,.531.34l4.888,4.918a8.969,8.969,0,0,1,1.833,2.731A8.3,8.3,0,0,1,177-844.56a8.28,8.28,0,0,1-2.484,6.06A8.167,8.167,0,0,1,168.5-836Zm0-2.174v-15.435l-4.516,4.565a5.908,5.908,0,0,0-1.395,2.038,6.421,6.421,0,0,0-.465,2.446,6.176,6.176,0,0,0,1.859,4.511A6.122,6.122,0,0,0,168.5-838.174Z"
                transform="translate(-160 856)" fill="#2a3a4b" />
            </svg>
          </div>
          <h5 class="title_item">Invert Colors</h5>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- <div id="accessibility-menu"></div> -->

<div class="textToSpeech mx-2" cdkDrag [class.active]="isTextToSpeechActive"
  [matTooltip]="'assignment.TextToSpeech' | translate" (click)="textToSpeech($event)">
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
    <path id="ic_text_to_speech"
      d="M93.14-828.741a.763.763,0,0,1-.57-.237.81.81,0,0,1-.23-.587.958.958,0,0,1,.06-.329.743.743,0,0,1,.16-.268,6.61,6.61,0,0,0,1.35-2.039,6.362,6.362,0,0,0,.49-2.513,6.384,6.384,0,0,0-.49-2.5,6.576,6.576,0,0,0-1.35-2.05.744.744,0,0,1-.16-.268.958.958,0,0,1-.06-.33.81.81,0,0,1,.23-.587.762.762,0,0,1,.57-.237.707.707,0,0,1,.31.072.87.87,0,0,1,.25.175,8.15,8.15,0,0,1,1.69,2.585,8.128,8.128,0,0,1,.61,3.141,8.1,8.1,0,0,1-.61,3.152,8.182,8.182,0,0,1-1.69,2.575.867.867,0,0,1-.25.175A.706.706,0,0,1,93.14-828.741Zm-2.28-2.348a.763.763,0,0,1-.57-.237.81.81,0,0,1-.23-.587.765.765,0,0,1,.07-.319.9.9,0,0,1,.17-.258,3.227,3.227,0,0,0,.66-1,3.108,3.108,0,0,0,.24-1.226,3,3,0,0,0-.25-1.226,3.6,3.6,0,0,0-.65-1,.638.638,0,0,1-.18-.257.875.875,0,0,1-.06-.319.81.81,0,0,1,.23-.587.763.763,0,0,1,.57-.237.881.881,0,0,1,.32.062.61.61,0,0,1,.26.185,4.832,4.832,0,0,1,1,1.524,4.778,4.778,0,0,1,.36,1.854,4.777,4.777,0,0,1-.36,1.854,4.831,4.831,0,0,1-1,1.524.607.607,0,0,1-.26.185A.881.881,0,0,1,90.86-831.089Zm-6.06-3.667H83.2v.227a1.988,1.988,0,0,0,.43,1.267,2.068,2.068,0,0,0,1.09.731l.24.062a1.119,1.119,0,0,1,.9,1.03,1.157,1.157,0,0,1-.62,1.236,7.816,7.816,0,0,1-2.14.865,12.744,12.744,0,0,1-2.3.33.7.7,0,0,1-.57-.216.81.81,0,0,1-.23-.587.81.81,0,0,1,.23-.587.838.838,0,0,1,.57-.257,10.546,10.546,0,0,0,1.41-.175,9.065,9.065,0,0,0,1.37-.381,3.533,3.533,0,0,1-1.45-1.37,3.756,3.756,0,0,1-.53-1.947v-1.051a.81.81,0,0,1,.23-.587.762.762,0,0,1,.57-.237h2.4v-1.648a.809.809,0,0,1,.23-.587.763.763,0,0,1,.57-.237h1.9l-2.26-4.655a.769.769,0,0,1-.05-.628.852.852,0,0,1,.41-.484.709.709,0,0,1,.61-.051.842.842,0,0,1,.47.422l2.26,4.655a1.609,1.609,0,0,1-.06,1.607,1.483,1.483,0,0,1-1.36.783H86.4v.824a1.611,1.611,0,0,1-.47,1.164A1.519,1.519,0,0,1,84.8-834.756Z"
      transform="translate(-80 844.741)" />
  </svg>

</div>
