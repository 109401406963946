import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CertificateTemplate {
  refreshDetails = new BehaviorSubject<any>(false);

  constructor(private http: HttpClient) { }

  saveRecord(data, recordId): any {
    if (recordId) {
      return this.http.put(`${environment.apiUrl}/CertificateTemplate/UpdateCertificateTemplate`, data);
    } else {
      return this.http.post(`${environment.apiUrl}/CertificateTemplate/CreateCertificateTemplate`, data);
    }
  }

  getCertificateTemplateDetails(params: any): any {
    return this.http.get(`${environment.apiUrl}/CertificateTemplate/GetCertificateTemplateDetails`, { params });
  }

  getListCertificateElements(): any {
    return this.http.get(`${environment.apiUrl}/CertificateTemplate/ListCertificateElements`);
  }

  getListPredefinedCertificateTemplates(): any {
    return this.http.get(`${environment.apiUrl}/CertificateTemplate/ListPredefinedCertificateTemplates`);
  }

  geListCertificateTemplates(params: any): any {
    return this.http.get(`${environment.apiUrl}/CertificateTemplate/ListCertificateTemplates`, { params });
  }

  deleteRecord(id): any {
    return this.http.delete(`${environment.apiUrl}/CertificateTemplate/DeleteCertificateTemplate?id=${id}`);
  }

  upload(data) {
    return this.http.post(`${environment.apiUrl}/CertificateTemplate/upload`, data, {
      reportProgress: true,
      observe: 'events'
    });
  }

  getListCertificateLanguages(): any {
    return this.http.get(`${environment.apiUrl}/CertificateTemplate/ListCertificateLanguages`);
  }

}
