import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ContentType } from 'src/app/models/courses/Courses';
import { LevelType } from 'src/app/models/courses/enums/LevelType';
import { SearchReasult } from 'src/app/models/courses/SearchReasult';
import { CoursesService } from 'src/app/services/Courses.service';

@Component({
  selector: 'app-search-result',
  templateUrl: './search-result.component.html',
})
export class SearchResultComponent implements OnInit {
  @Input() selectedText: any;
  @Input() selectedReasult: any[];
  @Output() closeDetails = new EventEmitter();
  @Output() selectedLesson = new EventEmitter();
  @Input() courseId;
  recordsData: SearchReasult = new SearchReasult();
  LevelType = LevelType
  dataLoading = false
  isVisited = false
  constructor(private service: CoursesService) {}

  ngOnInit(): void {
    console.log(this.courseId);
    this.getData();
    console.log(this.selectedReasult);

  }

  getData() {
    this.dataLoading = true
    let obj = {
      courseId: this.courseId,
      SearchKeywords: this.selectedText,
    };
    this.service.getSearchInCourseLessonsByKeywords(obj).subscribe((res) => {
      this.dataLoading = false
      this.recordsData = res as SearchReasult;
    }, err => {
      this.dataLoading = false
    });
  }

  isLinkVisited(id){
    return this.selectedReasult.some(item => item === id)
  }

  closeSidenav() {
    this.closeDetails.emit(true);
  }
}
