<div class="app-mian-footer">
  <div class="inner_footer">
    <div class="copy-rights">
      {{ 'footer.copy-right' | translate: {date: today | date:'y'} }}
    </div>
    <div class="navigation_links">
      <a (click)="openForm()">
        {{'header.Support' | translate}}
      </a>
    </div>
  </div>
</div>