
export class NotificationPreference {
  allowNotificationsDelivery: boolean
  allowEmailDelivery: boolean
  taskReminder: boolean
  eventNotifications: boolean
  courseUpdates: boolean
  feedbackAndGrades: boolean
  importantAnnouncements: boolean
}
