<nav class="navbar head-nav">
  <h2>{{( hasPermission('HospitalAdmin') ||
    hasPermission('ClinicAdmin')?'labs.ourLabs':'labs.learningCenters')|translate}}</h2>
  <ol class="breadcrumb">
    <li class="breadcrumb-item"><a [routerLink]='["/"]'>{{"general.home"|translate}}</a></li>
    <li class="breadcrumb-item">
      {{( hasPermission('HospitalAdmin') ||
      hasPermission('ClinicAdmin')?'labs.ourLabs':'labs.learningCenters')|translate}}
    </li>
  </ol>
</nav>

<div class="page">
  <div class="page-filters">

    <div class="sort-item">
      <span class="count-page" *ngIf='!dataLoading'>
        <span>{{recordsData.labsCount}}</span> {{'labs.learningCenters'|translate}}
      </span>

      <label>{{"general.sortedBy"|translate}}:</label>
      <select class="form-control" (change)="sortRecords($event)" [(ngModel)]="sortValue">
        <option *ngFor="let sortItem of sortItems" [value]="sortItem.value">{{sortItem.name|translate}}</option>
      </select>
    </div>

    <div class="filters-container">
      <div class="search-input">
        <mat-form-field appearance="legacy" *ngIf="showSearch">
          <input matInput autocomplete="off" [(ngModel)]="filterModel.LabName" (keydown.enter)="makeSearch()">
          <mat-icon (click)="resetSearch(); showSearch = false;" matSuffix>clear</mat-icon>
        </mat-form-field>
        <button mat-icon-button (click)="makeSearch()">
          <mat-icon>search</mat-icon>
        </button>
      </div>

      <button (click)="showFilter = !showFilter" [ngClass]='{"active": showFilter}'
        class="btn dropdown-custom dropdown-toggle mx-2">
        <img src="./assets/images/icons/filter-icon.svg" alt="">
        <span>{{"general.filter"|translate}}</span>
        <svg width="8" height="5.001" viewBox="0 0 8 5.001">
          <path id="menu-icon" d="M-3.562-9.316-.5-6.215l3.062-3.1.938.949-4,4.051-4-4.051Z"
            transform="translate(4.5 9.316)" fill="#6a6a6a" opacity="0.8" />
        </svg>
      </button>
      <div class="create-item" *ngIf="hasPermission('SuperAdmin')">
        <a class="customBtn" mat-raised-button color="primary" (click)="openCreateForm()">
          <span>{{"labs.addNew"|translate}}</span>
        </a>
      </div>
    </div>

    <div class="filter-section" [hidden]="!showFilter">
      <h4 class="title">{{"general.Filterby"|translate}}</h4>
      <div [formGroup]="filterFormGroup" class="row">
        <div class="col-lg-4 mb-3" [hidden]="!hasPermission('SuperAdmin')">
          <mat-form-field class="form-group no-validation">
            <mat-label>{{"labs.type"|translate}}</mat-label>
            <mat-select disableOptionCentering formControlName="LabType">
              <mat-option [value]="item.value" *ngFor="let item of labTypes">
                <span>{{item.name|translate}}</span>
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-lg-4 mb-3">
          <mat-form-field class="form-group no-validation">
            <mat-label>{{"general.country"|translate}}</mat-label>
            <mat-select disableOptionCentering formControlName="CountryId" (selectionChange)='getRegions()'>
              <mat-option [value]="item.id" *ngFor="let item of countryList">
                <span>{{item.name}}</span>
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-lg-4 mb-3">
          <mat-form-field class="form-group no-validation">
            <mat-label>{{"general.region"|translate}}</mat-label>
            <mat-select disableOptionCentering formControlName="RegionId" (selectionChange)='getCities()'>
              <mat-option [value]="item.id" *ngFor="let item of regionList">
                <span>{{item.name}}</span>
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-lg-4 mb-3">
          <mat-form-field class="form-group no-validation">
            <mat-label>{{"general.city"|translate}}</mat-label>
            <mat-select disableOptionCentering formControlName="CityId">
              <mat-option [value]="item.id" *ngFor="let item of cityList">
                <span>{{item.name}}</span>
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <!-- <div class="col-lg-4 mb-3">
          <mat-form-field class="form-group no-validation">
            <mat-label>SLA status</mat-label>
            <mat-select disableOptionCentering formControlName="SlaStatus">
              <mat-option [value]="item.value" *ngFor="let item of statusListAll">
                <span>{{item.name}}</span>
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div> -->
        <!-- <div class="col-lg-4 mb-3" [hidden]="!hasPermission('SuperAdmin')">
          <mat-form-field class="form-group no-validation">
            <mat-label>Select Hospital / Clinic</mat-label>
            <mat-select disableOptionCentering formControlName="entity">
              <mat-option [value]="item" *ngFor="let item of entityList">
                <span>{{item.name}}</span>
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div> -->
        <div class="col-lg-4 mb-3">
          <mat-form-field class="form-group no-validation">
            <mat-label>{{"general.CreationDateFrom"|translate}}</mat-label>
            <input matInput [matDatepicker]="recentActionDateFrom" formControlName='dateFrom' [max]='today' readonly>
            <mat-datepicker-toggle matSuffix [for]="recentActionDateFrom"></mat-datepicker-toggle>
            <mat-datepicker #recentActionDateFrom></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="col-lg-4 mb-3">
          <mat-form-field class="no-validation">
            <mat-label>{{"general.CreationDateTo"|translate}}</mat-label>
            <input matInput [matDatepicker]="recentActionDateTo" formControlName='dateTo' [max]='today' readonly>
            <mat-datepicker-toggle matSuffix [for]="recentActionDateTo"></mat-datepicker-toggle>
            <mat-datepicker #recentActionDateTo></mat-datepicker>
          </mat-form-field>
        </div>
      </div>


      <div class="btn-container">
        <button mat-raised-button class="mat-grey" (click)="clearFilter()">{{"general.ClearFilter"|translate}}</button>
        <button mat-raised-button color="primary" (click)="makeFilter();showFilter = !showFilter">{{"general.ApplyFilter"|translate}}</button>
      </div>
    </div>
  </div>


  <div *ngIf='dataLoading' class="dataLoading">
    <mat-spinner class="m-auto" diameter="50"></mat-spinner>
  </div>

  <ng-container *ngIf='!dataLoading'>
    <div *ngIf="recordsData.pagedData; else dataEmpty">
      <div class="table-responsive" *ngIf="recordsData.pagedData.length; else dataEmpty">

        <table class="table table-primary">
          <thead>
            <tr>
              <th width="1%"></th>
              <th>{{"labs.learningCenterName"|translate}}</th>
              <th *ngIf="hasPermission('SuperAdmin')">{{"labs.type"|translate}}</th>
              <th>{{"general.manager"|translate}}</th>
              <th>{{"general.mobileNo"|translate}}</th>
              <!-- <th>SLA Status</th> -->
              <th>{{"general.createdOn"|translate}}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let record of recordsData.pagedData">
              <td>
                <div class="img-circle">
                  <div class="image m-0">
                    <img *ngIf="record.logo" [src]="record.logo" (error)='record.logo = ""'>
                    <span *ngIf="!record.logo">{{ record.labName | shortName }}</span>
                  </div>
                </div>
              </td>

              <td>
                <div class="name-with-image">
                  <div class="name">
                    <h5 [matTooltip]="record.labName" matTooltipClass="wrap-tooltip">{{record.labName}}</h5>
                  </div>
                </div>
              </td>

              <td *ngIf="hasPermission('SuperAdmin')">
                <span [ngSwitch]="record.type">
                  <span *ngSwitchCase="'MAIN_LAB'">
                    {{"labs.main"|translate}}
                  </span>
                  <span *ngSwitchCase="'BRANCH'">
                    {{"labs.branch"|translate}}
                  </span>
                  <span *ngSwitchCase="'HOSPITAL_LAB'">
                    {{"labs.hospitalLab"|translate}}
                  </span>
                  <span *ngSwitchCase="'CLINIC_LAB'">
                    {{"labs.clinicLab"|translate}}
                  </span>
                </span>
              </td>
              <td>{{record.manager}}</td>
              <td>{{record.contactNumber}}</td>
              <!-- <td>
                <app-sla-status [status]="record.slaStatus"></app-sla-status>
              </td> -->
              <td>{{record.createdOn | date:'d MMM, y, h:mm a'}}</td>

              <td>
                <div class="btn-actions">
                  <a mat-icon-button [matTooltip]="'general.view'|translate" [routerLink]="['/admin/labs', record.id]">
                    <svg width="20" height="13" viewBox="0 0 20 13">
                      <path id="view-icon"
                        d="M4419.2,17820.545a.72.72,0,0,1,0-.809c.166-.242,3.664-6.094,9.905-6.094s9.74,5.852,9.91,6.094a1.383,1.383,0,0,1,0,.809c-.17.246-3.664,6.1-9.91,6.1S4419.362,17820.791,4419.2,17820.545Zm5.745-.4a4.164,4.164,0,1,0,4.161-4.066A4.073,4.073,0,0,0,4424.941,17820.143Zm1.664,0a2.5,2.5,0,1,1,2.5,2.434A2.423,2.423,0,0,1,4426.6,17820.143Zm2.5-.814a.835.835,0,1,0,.837-.814A.781.781,0,0,0,4429.1,17819.328Z"
                        transform="translate(-4419.072 -17813.643)" fill="#616e7c" />
                    </svg>
                  </a>
                  <button mat-icon-button [matTooltip]="'general.delete'|translate" (click)="deleteRecord(record.id)"
                    *ngIf="record.canDeleteLab && hasPermission('SuperAdmin')">
                    <svg width="16" height="20.002" viewBox="0 0 16 20.002">
                      <path id="delete-icon"
                        d="M4422.4,17833.639a1.853,1.853,0,0,1-1.844-1.785l-.51-10.822a.2.2,0,0,1,.192-.207H4433.9a.194.194,0,0,1,.192.207l-.505,10.822a1.859,1.859,0,0,1-1.844,1.785Zm7.134-11.252v8.129a.616.616,0,1,0,1.231,0v-8.129a.616.616,0,1,0-1.231,0Zm-3.079,0v8.129a.615.615,0,1,0,1.23,0v-8.129a.615.615,0,1,0-1.23,0Zm-3.075,0v8.129a.615.615,0,1,0,1.23,0v-8.129a.615.615,0,1,0-1.23,0Zm-3.692-2.811a.621.621,0,0,1-.618-.627v-1.248a1.551,1.551,0,0,1,1.54-1.562h3.383v-.627a1.864,1.864,0,0,1,1.849-1.875h2.461a1.86,1.86,0,0,1,1.844,1.875v.627h3.383a1.551,1.551,0,0,1,1.54,1.563v1.248a.617.617,0,0,1-.613.627Zm5.536-4.064v.627h3.692v-.627a.616.616,0,0,0-.613-.621h-2.461A.619.619,0,0,0,4425.223,17815.512Z"
                        transform="translate(-4419.069 -17813.637)" fill="#616e7c" />
                    </svg>
                  </button>
                </div>
              </td>

            </tr>
          </tbody>

        </table>
      </div>

      <div class="pagination-container">
        <mat-paginator [showFirstLastButtons]="true" *ngIf="pageCount > 1" [length]="recordsData.labsCount"
          [pageSize]="filterModel.pageSize" [pageSizeOptions]="[5, 10, 20]" (page)="getAll($event)"
          [pageIndex]="pageIndex">
        </mat-paginator>
      </div>

    </div>
  </ng-container>

  <ng-template #dataEmpty>
    <app-empty-state [title]='"general.noDataFound"|translate'></app-empty-state>
  </ng-template>
</div>