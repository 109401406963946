<div class="card_info_profile_content">
  <div class="card_info_profile_title">
    <h4>
      {{"labProfile.staffDirectory"|translate}}
      <span *ngIf='!dataLoading && recordsData.usersCount'>[{{recordsData.usersCount}}]</span>
    </h4>
    <ng-container *ngIf='!dataLoading'>
      <div class="buttons_actions" *ngIf="recordsData.pagedData && recordsData.pagedData.length">
        <a class="add" mat-icon-button [matTooltip]="'userManagment.addUser'|translate" (click)="checkEntityTypeToAddUser()">
          <svg width="15.999" height="15.999" viewBox="0 0 15.999 15.999">
            <path id="plus-btn"
              d="M7.934,16a1,1,0,0,1-1-1V9.065H1a1,1,0,0,1-1-1V7.931a1,1,0,0,1,1-1H6.935V1a1,1,0,0,1,1-1h.133a1,1,0,0,1,1,1V6.931H15a1,1,0,0,1,1,1v.133a1,1,0,0,1-1,1H9.068V15a1,1,0,0,1-1,1Z"
              transform="translate(-0.001 0.002)" fill="#fff" />
          </svg>
        </a>
      </div>
    </ng-container>

  </div>

  <div class="card_info_profile_body">

    <div class="page">
      <div *ngIf='dataLoading' class="dataLoading">
        <mat-spinner class="m-auto" diameter="50"></mat-spinner>
      </div>

      <ng-container *ngIf='!dataLoading'>
        <div *ngIf="recordsData.pagedData; else dataEmpty">
          <div class="" *ngIf="recordsData.pagedData && recordsData.pagedData.length; else dataEmpty">

            <table class="table table_list_users">
              <thead>
                <tr>
                  <th width="50%">{{"labProfile.nameRole"|translate}}</th>
                  <th>{{"labProfile.status"|translate}}</th>
                  <th class="text-right">{{"labProfile.status"|translate}}</th>
                </tr>
              </thead>
              <tbody>
                <tr style="cursor: pointer;" *ngFor="let record of recordsData.pagedData" (click)="userDetails(record)">
                  <td>
                    <div class="blog_user_item">
                      <div class="img-circle">
                        <div class="image m-0">
                          <img *ngIf="record.userLogo" [src]="record.userLogo" (error)='record.userLogo = ""'>
                          <span *ngIf="!record.userLogo">{{ record.fullName | shortName }}</span>
                        </div>
                      </div>
                      <div class="name">
                        <h5>{{record.fullName}}</h5>
                        <div *ngIf="record.roles.length">
                          <ng-container *ngFor="let item of record.roles; index as i">
                            <span>{{(i !== 0 ? ', ' : '') + item}}</span>
                          </ng-container>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td>
                    <span [ngSwitch]="record.userStatus">
                      <span class="badge-status warning" *ngSwitchCase="'Not Verified Yet'">
                        {{"general.notVerifiedYet"|translate}}
                      </span>
                      <span class="badge-status success" *ngSwitchCase="'ACTIVE'">
                        {{"general.active"|translate}}
                      </span>
                      <span class="badge-status" *ngSwitchCase="'INACTIVE'">
                        {{"general.inactive"|translate}}
                      </span>
                      <span class="badge-status warning" *ngSwitchDefault>
                        {{"general.notVerifiedYet"|translate}}
                      </span>
                    </span>
                  </td>
                  <td class="text-right">{{record.memberSince | date:'d MMM, y, h:mm a'}}</td>
                </tr>
              </tbody>

            </table>
          </div>

          <div class="pagination_custom_bootstrap" *ngIf="pageCount > 1">
            <nav>
              <ul class="pagination">
                <li class="page-item" [class.disabled]='pageIndex == 0'>
                  <a class="page-link" (click)="getAll(0)">
                    <span class="material-icons">
                      navigate_before
                    </span>
                  </a>
                </li>
                <li class="page-item other_item" *ngIf="(pageIndex + 4) > visibleNUmberPages">
                  <a class="page-link bg-transparent">...</a>
                </li>
                <ng-container *ngFor="let in of counter(pageCount); let i = index">
                  <li class="page-item" *ngIf="i <= maxNUmberPages && minNUmberPages <= i">
                    <a class="page-link" (click)="getAll(i)" [class.active]='pageIndex == i'>{{i + 1}}</a>
                  </li>
                </ng-container>
                <li class="page-item other_item" *ngIf="(pageCount -1) > maxNUmberPages">
                  <a class="page-link bg-transparent">...</a>
                </li>

                <li class="page-item" [class.disabled]='pageIndex == (pageCount - 1)'>
                  <a class="page-link" (click)="getAll(pageCount - 1)">
                    <span class="material-icons">
                      navigate_next
                    </span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>

        </div>
      </ng-container>

      <ng-template #dataEmpty>
        <app-empty-state [img]='"./assets/images/illustration-reading-list.svg"' [desc]='"labProfile.noStaffUsersAddedYet"|translate'
          (eventEmit)="checkEntityTypeToAddUser()" [link]="'labProfile.addNewUser'|translate" [linkBoolean]="true">
        </app-empty-state>
        <!-- <app-empty-state *ngIf="!sla" [img]='"./assets/images/illustration-reading-list.svg"'
          [desc]='"To add staff (users), you must fill out the Service Level Agreement between this " + entityType + " and Histo."'
          [linkBoolean]="false">
        </app-empty-state> -->
      </ng-template>
    </div>

  </div>
</div>

<app-sidenav-over *ngIf="openSideNav && !checkRole('Pathologist')" [open]="openSideNav"
  (closeDetails)="closeSidenav($event)">
  <app-user-details *ngIf="selectedRecord.labUserId" [title]="'labProfile.staffDirectory'|translate" [module]="module"
    [userInfo]="selectedRecord" [isModuleAdmin]="true" [entityId]="entityId"
    (closeDetails)="closeSidenav($event)"></app-user-details>
</app-sidenav-over>

<app-sidenav-over *ngIf="openSideNav && checkRole('Pathologist')" [open]="openSideNav"
  (closeDetails)="closeSidenav($event)">
  <app-user-details *ngIf="selectedRecord.labUserId" [title]="'labProfile.staffDirectory'|translate" [module]="'PATHOLOGIST_USER'"
    [userInfo]="selectedRecord" [isModuleAdmin]="true" [pathologistLabType]="'LAB'"
    [hideActionsFromUsersNotSuerAdmin]="true" [entityId]="entityId"
    (closeDetails)="closeSidenav($event)"></app-user-details>
</app-sidenav-over>