import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class LookupsService {

  constructor(private http: HttpClient) {
  }

  getCountries() {
    return this.http.get(`${environment.apiUrl}/Country/ListCountries`);
  }
  getRegions(countryId) {
    return this.http.get(`${environment.apiUrl}/Rgion/ListRegions?CountryId=${countryId}`);
  }
  getCities(countryId, regionId) {
    return this.http.get(`${environment.apiUrl}/City/ListCities?CountryId=${countryId}&RegionId=${regionId}`);
  }
  getIdTypes() {
    return this.http.get(`${environment.apiUrl}/ManageUsers/GetIdentificationType`);
  }
  getIdTypesForRegestration() {
    return this.http.get(`${environment.apiUrl}/Pathologist/GetIdentificationType`);
  }

  GetMenu() {
    return this.http.get(`${environment.apiUrl}/lookups/GetMenu`);
  }
  GetLoggedUserCountires() {
    return this.http.get(`${environment.apiUrl}/Country/GetLoggedUserCountires`);
  }
  getAllCountries() {
    return this.http.get(`${environment.apiUrl}/Country/ListAll`);
  }
  GetLoggedRegions() {
    return this.http.get(`${environment.apiUrl}/Region/GetLoggedRegions`);
  }
  AllExtentionNumbetCountries() {
    return this.http.get(`${environment.apiUrl}/Country/AllExtentionNumbetCountries`);
  }
  AllCountryWithChilds() {
    return this.http.get(`${environment.apiUrl}/Country/AllCountryWithChilds`);
  }
  AllRegionsWithChilds() {
    return this.http.get(`${environment.apiUrl}/Region/AllRegionsWithChilds`);
  }
  getOrgansList() {
    return this.http.get(`${environment.apiUrl}/OrgansList/List`);
  }
  getOrgansListForLivingDonor() {
    return this.http.get(`${environment.apiUrl}/OrgansList/ListForLivingDonor`);
  }
  ListOrgansForWaitingList(id, waitingListOrganId) {
    const query = waitingListOrganId ? '&WaitingListOrganId=' + waitingListOrganId : '';
    return this.http.get(`${environment.apiUrl}/OrgansList/ListOrgansForWaitingList?waitingListId=${id}${query}`);
  }

  getListOrgansForBlackList(donorId) {
    const query = donorId ? '?donorId=' + donorId : '';
    return this.http.get(`${environment.apiUrl}/OrgansList/ListOrgansForBlackList${query}`);
  }

  getAllEntites(entityType) {
    const query = entityType ? '?EntityType=' + entityType : '';
    return this.http.get(`${environment.apiUrl}/ManageUsers/GetEntities${query}`);
  }

  getListOrgansForIssueingCard(donorType = null, donorId = null, hospitalId = null, recipientAndDonorBD = false) {
    let str = '';
    if (donorType != null) {
      str += 'donorType=' + donorType;
    }
    if (donorId) {
      str += '&donorId=' + donorId;
    }
    if (hospitalId) {
      str += '&hospitalId=' + hospitalId;
    }
    if (recipientAndDonorBD) {
      str += '&recipientAndDonorBD=' + recipientAndDonorBD;
    }
    return this.http.get(`${environment.apiUrl}/OrgansList/ListOrgansForIssueingCard?${str}`);
  }

  ListOrgansForTransplantationDetails(donorType = null, donorId = null, hospitalId = null, waitingListId = false) {
    let str = '';
    if (donorType != null) {
      str += 'donorType=' + donorType;
    }
    if (donorId) {
      str += '&donorId=' + donorId;
    }
    if (hospitalId) {
      str += '&hospitalId=' + hospitalId;
    }
    if (waitingListId) {
      str += '&waitingListId=' + waitingListId;
    }
    return this.http.get(`${environment.apiUrl}/OrgansList/ListOrgansForTransplantationDetails?${str}`);
  }

  getListHospitalOrgans() {
    return this.http.get(`${environment.apiUrl}/OrgansList/ListHospitalOrgans`);
  }

  getAffiliationList(type) {
    return this.http.get(`${environment.apiUrl}/HospitalAffiliation/List?type=${type}`);
  }
  getNationalityList() {
    return this.http.get(`${environment.apiUrl}/Nationality/List`);
  }
  getSpecialityList(addFromSurgen = false) {
    if (addFromSurgen) {
      return this.http.get(`${environment.apiUrl}/Speciality/ListForSurgeons`);
    } else {
      return this.http.get(`${environment.apiUrl}/Speciality/List`);
    }
  }
  getSpecialityListForExams() {
    return this.http.get(`${environment.apiUrl}/Speciality/ListForExams`);
  }
  getTitlesList() {
    return this.http.get(`${environment.apiUrl}/Titles/List`);
  }
  getClassification() {
    return this.http.get(`${environment.apiUrl}/Classification/List`);
  }
  getCauseOfBrainDeath() {
    return this.http.get(`${environment.apiUrl}/CauseOfBrainDeath/List`);
  }
  getBloodType() {
    return this.http.get(`${environment.apiUrl}/BloodType/List`);
  }
  getHospitalStaffForExams(HosId, positions = []) {
    const filter = {
      hospitalId: HosId,
      positions
    };
    // return this.http.get(`${environment.apiUrl}/HospitalStaff/HospitalStaffForExams?hospitalId=` + HosId);
    return this.http.post(`${environment.apiUrl}/HospitalStaff/HospitalStaffForExams?hospitalId`, filter);
  }
  getAttachementType() {
    return this.http.get(`${environment.apiUrl}/AttachementType/List`);
  }
  getSpeciality() {
    return this.http.get(`${environment.apiUrl}/Speciality/List`);
  }
  InsuranceStatus(): any {
    return this.http.get(`${environment.apiUrl}/InsuranceStatus/List`);
  }
  CompanyType(): any {
    return this.http.get(`${environment.apiUrl}/CompanyType/List`);
  }
  MaritalStatus(): any {
    return this.http.get(`${environment.apiUrl}/MaritalStatus/List`);
  }
  EducationLevel(): any {
    return this.http.get(`${environment.apiUrl}/EducationLevel/List`);
  }
  CauseOfBrainDeath(): any {
    return this.http.get(`${environment.apiUrl}/CauseOfBrainDeath/List`);
  }
  MechanismOfDeath(): any {
    return this.http.get(`${environment.apiUrl}/MechanismOfDeath/List`);
  }
  Circumstance(): any {
    return this.http.get(`${environment.apiUrl}/Circumstance/List`);
  }

  uniqueIdNumber(identityNumber): any {
    return this.http.get(`${environment.apiUrl}/BlackListDetails/IsBlackList?identityNumber=` + identityNumber);
  }

  uniqueDonorIdNumber(identityNumber, donorId = null): any {
    return this.http.get(`${environment.apiUrl}/donor/IsIdentityNumberExist?identityNumber=` + identityNumber);
  }

  uniqueCodeNumber(code, hospital = null): any {
    let str = '';
    if (code != null) {
      str += 'code=' + code;
    }
    if (hospital != null) {
      str += '&hospital=' + hospital;
    }
    return this.http.get(`${environment.apiUrl}/hospital/IsCodeExist?${str}`);
  }


  IsHosNameExist(name, hospital = null): any {
    let str = '';
    if (name != null) {
      str += 'name=' + name;
    }
    if (hospital != null) {
      str += '&hospital=' + hospital;
    }
    return this.http.get(`${environment.apiUrl}/HospitalDepartments/IsNameExist?${str}`);
  }


  RecognitionCriteria(): any {
    return this.http.get(`${environment.apiUrl}/RecognitionCriteria/List`);
  }
  Disease(): any {
    return this.http.get(`${environment.apiUrl}/Disease/List`);
  }
  // getCommunicationMethod():any {
  //     return this.http.get(`${environment.apiUrl}/CommunicationMethod/List`);
  // }
  getFamilyRelationship(): any {
    return this.http.get(`${environment.apiUrl}/FamilyRelationship/List`);
  }
  BloodSampleType(): any {
    return this.http.get(`${environment.apiUrl}/BloodSampleType/List`);
  }
  getListDonorOrgans(donorId) {
    return this.http.get(`${environment.apiUrl}/DonorOrgan/ListDonorOrgans?donorId=` + donorId);
  }
  getListRadiologys() {
    return this.http.get(`${environment.apiUrl}/Radiology/ListRadiologys`);
  }
  getListLaboratoryTests() {
    return this.http.get(`${environment.apiUrl}/LaboratoryTest/ListLaboratoryTests`);
  }
  getListOrganSamples(donorOrganId) {
    return this.http.get(`${environment.apiUrl}/OrganSamples/ListOrganSampless?donorOrganId=` + donorOrganId);
  }
  getListProcedureTypes() {
    return this.http.get(`${environment.apiUrl}/ProcedureType/ListProcedureTypes`);
  }
  TransplantationType(organId) {
    return this.http.get(`${environment.apiUrl}/TransplantationType/List/${organId}`);
  }
  getfluidsList() {
    return this.http.get(`${environment.apiUrl}/IvFluidFeeding/List`);
  }
  getCommitteePositionsList() {
    return this.http.get(`${environment.apiUrl}/CommitteeMemberRole/List`);
  }
  getReasonList(): any {
    return this.http.get(`${environment.apiUrl}/ReasonOfNotReady/List`);
  }

  getCoverageAreas(filter) {
    return this.http.get(`${environment.apiUrl}/Lookups/GetCoverageAreas`, {
      params: filter
    });
  }

  getAncillaryTestsNames() {
    return this.http.get(`${environment.apiUrl}/Lookups/ListAncillaryTestsNames`);
  }

  getListStainTypes() {
    return this.http.get(`${environment.apiUrl}/Lookups/ListStainTypes`);
  }

  getListSpecimenSources() {
    return this.http.get(`${environment.apiUrl}/Lookups/ListSpecimenSources`);
  }

  getMalignancyList(): any {
    return this.http.get(`${environment.apiUrl}/Lookups/GetMalignancyList`);
  }

  getListTissuesBySpecialityId(params) {
    return this.http.get(`${environment.apiUrl}/Lookups/ListTissuesBySpecialityId`, { params });
  }

  getListDiagnosesNameLookup(LabId, LabType) {
    return this.http.get(`${environment.apiUrl}/Lookups/ListDiagnosesNameLookup?LabId=${LabId}&LabType=${LabType}`);
  }

  getPathologistsLookup(LabId, LabType) {
    return this.http.get(`${environment.apiUrl}/Lookups/getPathologistsLookup?LabId=${LabId}&LabType=${LabType}`);
  }

  getCaseStatusLookup() {
    return this.http.get(`${environment.apiUrl}/Lookups/GetCaseStatusLookup`);
  }
  getListHospitalsClinicsLookup(params) {
    return this.http.get(`${environment.apiUrl}/Lookups/ListHospitalsClinicsLookup`, { params });
  }
  getListSLASpecialities(params) {
    return this.http.get(`${environment.apiUrl}/Lookups/ListSLASpecialities`, { params });
  }
  getSubspecialtiesForRegistration() {
    return this.http.get(`${environment.apiUrl}/Pathologist/GetSubSpecilities`);
  }
  getListEntitiesHasSLA() {
    return this.http.get(`${environment.apiUrl}/EntitySLA/ListEntitiesHasSLA`);
  }
  getListEntitiesHasSLAContract(params) {
    return this.http.get(`${environment.apiUrl}/SLAContract/ListEntitiesHasSLA`, { params });
  }
  getListHospitalsClinicsLabs(params = null) {
    return this.http.get(`${environment.apiUrl}/Lookups/ListHospitalsClinicsLabs`, { params });
  }
  getAllLabsAndVirualLABslookups(params = null) {
    return this.http.get(`${environment.apiUrl}/Lookups/GetAllLabsAndVirualLABslookups`, { params });
  }
  getListLabs(params = null) {
    return this.http.get(`${environment.apiUrl}/Lab/GetAllLabsLookup`, { params });
  }

  getListLabsScanningProcess(params = null) {
    return this.http.get(`${environment.apiUrl}/Lookups/ListLabsScanningProcess?${this.getParams(params).toString()}`);
  }

  getListSentBackDetails() {
    return this.http.get(`${environment.apiUrl}/Lookups/ListSentBackDetails`);
  }

  getLabPathologists(params = null) {
    return this.http.get(`${environment.apiUrl}/Lookups/GetLabPathologists`, { params });
  }

  getLabTechnicians(params = null) {
    return this.http.get(`${environment.apiUrl}/Lookups/GetLabTechnicians`, { params });
  }

  getListAllTags() {
    return this.http.get(`${environment.apiUrl}/Lookups/ListAllTags`);
  }
  addCaseTag(data) {
    return this.http.post(`${environment.apiUrl}/Case/AddCaseTag`, data);
  }
  deleteCaseTag(params) {
    return this.http.delete(`${environment.apiUrl}/Case/DeleteCaseTag`, { params });
  }

  getAllContentTypes() {
    return this.http.get(`${environment.apiUrl}/Lookups/GetAllContentTypes`);
  }

  getAllQuizQuestionType() {
    return this.http.get(`${environment.apiUrl}/Lookups/GetAllQuizQuestionType`);
  }

  getSlides(params) {
    return this.http.get(`${environment.apiUrl}/Lookups/FilterSlides`, { params });
  }

  getAllCourses() {
    return this.http.get(`${environment.apiUrl}/Lookups/GetAllCourses`);
  }

  getQuestionBankCategories() {
    return this.http.get(`${environment.apiUrl}/Lookups/GetQuestionBankCategories`);
  }

  getQuestionBankDifficulties() {
    return this.http.get(`${environment.apiUrl}/Lookups/GetQuestionBankDifficulties`);
  }

  getListMyTags() {
    return this.http.get(`${environment.apiUrl}/Lookups/ListMyTags`);
  }

  getQuestionBanks() {
    return this.http.get(`${environment.apiUrl}/Lookups/ListMyQuestionBanks`);
  }

  getAllCourseOutComes(params) {
    return this.http.get(`${environment.apiUrl}/Lookups/GetAllCourseOutComes`, {params});
  }
  getAllCourseContentCategories() {
    return this.http.get(`${environment.apiUrl}/Lookups/GetAllCourseContentCategories`);
  }
  getAllCourseContentTopics(params) {
    return this.http.get(`${environment.apiUrl}/Lookups/GetAllCourseContentTopics`, {params});
  }

  getParams(query) {
    let params: HttpParams = new HttpParams();
    for (const key of Object.keys(query)) {
      if (query[key]) {
        if (query[key] instanceof Array) {
          query[key].forEach((item) => {
            params = params.append(`${key.toString()}`, item);
          });
        } else {
          params = params.append(key.toString(), query[key]);
        }
      }
    }
    return params;
  }
}
